// Campaign Details

.cd-header-back{
    max-height: 400px;
    overflow: hidden;
    position: relative;
    .react-loading-skeleton{
        line-height: unset;
    }
    img{
        filter: blur(4px);
        -webkit-filter: blur(4px);
    }
    &:after{
        background: #00000033 0% 0% no-repeat padding-box;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.backbtn{
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    background-color: rgba(0, 0, 0, 0.4);
    color: $white;
    cursor: pointer;
    font-size: 14px;
    padding: 3px 7px;
    max-width: 69px;
    @include transition(0.28s);
    &:hover{
        background: $hoverColor;
        color: $white;
        text-decoration: none;
    }
    i{
        font-size: 12px;
        margin-right: 4px;
    }
}

.titlebackbtn{
    @include flexbox();
    @include align-items(center);
    @include justify-content(center);
    background-color: rgba(0, 0, 0, 0.4);
    color: $white;
    cursor: pointer;
    font-size: 14px;
    padding: 3px 7px;
    max-width: 69px;
    float: right;
    margin-top: 20px;
    @include transition(0.28s);
    &:hover{
        background: $hoverColor;
        color: $white;
        text-decoration: none;
    }
    i{
        font-size: 12px;
        margin-right: 4px;
    }
}

.cd-header-details{
    .container{
        max-width: 1024px;
        .cd-details{
            position: relative;
            margin-top: -230px;
            
            h2{
                color: $white;
                font-size: 22px;
                font-weight: 600;
                padding: 20px 0;
            }
            .cd-header-image{
                .cd-header-img-wpr{
                    img{
                        // height: 420px;
                        width: 100%;
                        // -o-object-fit: cover;
                        // object-fit: cover;
                    }
                }
                margin-bottom: 20px;
                position: relative;
                img{
                    width: 100%;
                }
                .cd-report{
                    background: #262626;
                    border-radius: 2px;
                    color: #ffffff;
                    cursor: pointer;
                    font-size: 11px;
                    font-weight: 400;
                    padding: 4px 10px;
                    position: absolute;
                    top: 17px;
                    right: 15px;
                    @include transition(0.28s);
                    &:hover{
                        background:$hoverColor;
                    }
                }
                .cd-cat{
                    background: rgba(0, 0, 0, 0.49);
                    border-radius: 13px;
                    color: #ffffff;
                    font-size: 11px;
                    font-weight: 400;
                    padding: 4px 10px;
                    position: absolute;
                    bottom: 17px;
                    left: 15px;
                    text-transform: uppercase;
                }
                .cd-btn-group{
                    position: absolute;
                    bottom: -22px;
                    right: 30px;
                    height: 50px;
                    width: 110px;
                    @include flexbox();
                    @include justify-content(space-between);
                    .upload-btn{
                        background: rgba(255,255,255,1);
                        box-shadow: 0px 3px 6px #0000001F;
                        border-radius: 50%;
                        cursor: pointer;
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        height: 50px;
                        width: 50px;
                        img{
                            width: 18px;
                        }
                    }
                    .heart-btn{
                        background: rgba(255,255,255,1);
                        box-shadow: 0px 3px 6px #0000001F;
                        border-radius: 50%;
                        @include flexbox();
                        @include align-items(center);
                        @include justify-content(center);
                        height: 50px;
                        width: 50px;
                        i{
                            color: $hoverColor;
                            font-size:20px;
                        }
                    }
                }
            }
            .cd-description{
                .posted{
                    color: #858A8F;
                    font-style: italic;
                    font-size: 11px;
                    opacity: 0.7;
                }
                .cd-para{
                    color: #4E5B6D;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 26px;
                }
                .cd-desc-list{
                    padding-left: 20px;
                    li{
                        color: #4E5B6D;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                    }
                }
                h4{
                    color: #858A8F;
                    font-size: 13px;
                    font-weight: 500;
                    letter-spacing: 0.65px;
                }
                p{
                    color: $primaryColor;
                    font-size: 14px;
                    font-weight: 500;
                    letter-spacing: 0.7px;
                    line-height: 21px;
                    span{
                        margin-right: 4px;
                    }
                }
                .cd-des{
                    padding-bottom: 20px;
                }
                .cd-tc, .cd-ref-policy{
                    border-top: 1px solid #e5e5e5;
                    padding: 30px 0 20px 0;
                }
                .cd-ref-policy{
                    padding-bottom: 50px;
                }
                   
                .campaign-type-mask{
                    margin-top: 1em;
                }
                .campaign-info-mask{
                    margin-top: 2em;
                }
                .campaign-description-mask{
                    margin-top: 2em;
                    p{
                        font-size: 14px;
                        font-weight: 600;
                        color: #172940;
                    }
                }
            }
        }
        .interest-wpr{
            background: $white;
            box-shadow: 0px 3px 30px #0000000F;
            padding: 30px 26px 15px 26px;
           
            p.price{
                color: #FF007A;
                font-size: 26px;
                font-weight: 600;
                letter-spacing: 0.78px;
            }
            .location-wpr, .remain-wpr, .price-wpr{
                padding-bottom: 10px;

            }
            .price-wpr{
                border-bottom: 1px solid #E5E5E5;
            }
            .shipped-wpr{
                padding-top: 20px;
            }
            
        }
        .disablecamp-text{
            font-size: 12px;
            font-style: italic;
            color: #858A8F;
            margin: 1em 1em 1em 0;
        }
        .share-friends-wpr {
            margin-top: 28px;
            h3{
                color: #858A8F;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0.7px;
                margin-bottom: 15px;
            }
           
        } 
    }
}

.hashtag-wpr{
    h4{
        padding-bottom: 5px;
    }
}
.hashtag-list{
    padding-bottom: 0px;
    span{
        background: #F8F8F8;
        border-radius: 14px;
        color: $primaryColor;
        display: inline-block;
        font-size: 12px;
        font-weight: 500;
        margin-right: 5px;
        margin-bottom:5px;
        padding: 4px 15px;
        text-align: center;
    }
}

/* Campaigns Details Social Wrapper */

.cd-social{
    li{
        display: inline-block;
        list-style: none;
        margin-right: 6px;
        &:nth-child(1){
            a{
                &:hover{
                    background: #3a5898;
                }
            }
        }
        &:nth-child(2){
            a{
                &:hover{
                    background: #0277fe;
                }
            }
        }
        &:nth-child(3){
            a{
                &:hover{
                    background: #0073b0;
                }
            }
        }
        &:nth-child(4){
            a{
                &:hover{
                    background: #2baae1;
                }
            }
        }
        &:nth-child(5){
            margin-right: 0;
            a{
                &:hover{
                    background: #da3025;
                }
            }
        }
        a{
            border-radius:50% ;
            background: #DEDEDE;
            color: $white;
            display: inline-block;
            font-size: 20px;
            line-height: 2.4;
            height: 46px;
            width: 46px;
        }
    }
}

.cd-header-img-wpr{
    max-height: 420px;
    overflow: hidden;
    position: relative;

    .slick-slider{
        position: unset;
        // .slick-list{
        //     background: #ccc;
        //     .slick-track{
        //         .slick-slide{
        //             img{
        //                 width: 100%;
        //                 min-height: 420px;
        //                 max-height: 420px;
        //                 object-fit: contain;
        //             }
        //         }
        //     }
        // }
    }

    .slick-prev:before, .slick-next:before{
        background: rgba(255,255,255,0.7);
        border-radius: 18px;
        color: #262626;
        display: inline-block;
        font-size: 17px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        height: 36px;
        line-height: 2.1;
        padding-right: 2px;
        text-align: center;
        width: 36px;
    }

    .slick-prev:before{
        content: "\f053";
    }

    .slick-next:before{
        content: "\f054";
        padding-left: 2px;
        padding-right: 0;
    }

    .slick-prev, .slick-next{
        height: 36px;
        width: 36px;
        
    }

    .slick-prev{
        left:32px;
        z-index: 1;
        top:50%;
        transform: translate(-50%, 0);
    }

    .slick-next{
        right: 32px;
        top:50%;
        transform: translate(50%, 0);
    }

    .slick-dots{
        bottom:10px;
    }
    .slick-dots li button:before{
        font-size: 14px;
        opacity: 0.75;
    }
    .slick-dots li.slick-active button:before {
        color: #ff007a
    }
}

p#minimumFollowers div {
    text-align: initial !important;
}

.custom-container{
  max-width:1230px !important;
}

.custom-container{
    .price-wpr{
        h4{
            font-size: 13px;
            font-weight: 600;
            color: #858A8F;
        }
        .price{
            margin-bottom: 0;
        }
		.sponsored_label{
			color: #ffffff;
			border-bottom: 18px solid #FFC107;
			border-top: 18px solid #FFC107;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			line-height: 0px;
			padding: 0px 3.5px 0 0;
			top: -22px;
			position: relative;
			left: 0px;
			width: 100%;
			float: left;
			font-size: 17px;
			font-weight: 400;
			text-align: center;
		}
		.exchange_label{
			color: #ffffff;
			border-bottom: 18px solid #58DC72;
			border-top: 18px solid #58DC72;
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			line-height: 0px;
			padding: 0px 3.5px 0 0;
			top: -15px;
			position: relative;
			left: 0px;
			width: 100%;
			float: left;
			font-size: 17px;
			font-weight: 400;
            text-align: center;
            .labelcenter-fill{
                background-color: #000;
            }
		}
    }
}
.edit-applied{
    width: 15px !important;
    margin-right: 5px;
}
.cd-social li a{
    color: #858A8F;
}
.cd-social li a:hover,
.cd-social li a:focus{
    color: #ffffff;
}

.edit-campaign-check {
	height: 0;
	width: 0;
    visibility: hidden;
    float: left;
}

.edit-campaign-label {
	cursor: pointer;
	text-indent: -9999px;
	width: 35px;
	height: 20px;
	background: #858A8F;
	display: block;
	border-radius: 100px;
	position: relative;
}

.edit-campaign-label:after {
	content: "";
	position: absolute;
	top: 2.5px;
	left: 2px;
	width: 15px;
	height: 15px;
	background: #fff;
	border-radius: 90px;
	transition: 0.3s;
}

.edit-campaign-check:checked + .edit-campaign-label {
	background: #1658D3;
}

.edit-campaign-check:checked + .edit-campaign-label:after {
	left: calc(100% - 2px);
    transform: translateX(-100%);
}

.edit-campaign-label:active:after {
	width: 30px;
}

.toggleswitch{
    position: relative;
    top: -27px;
    left: 45px;
    font-size: 13px;
    font-weight: 600;
    color: #858A8F;
}

.min-followers-camp div{
	text-align:inherit !important;
}

.applicant-img{
	border-radius: 50%;
    width: 128px;           
    height: 128px;
}

#contact{
    padding-top: 0em;
    .hired-wrapper {
        float: left;
        width: 100%;
        position: relative;
        background: #fff;
        border: 1px solid #E8ECF3;
        padding: 1em;
        // height:315px;
        margin: 1.3em 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        transition: transform 0.1s;
        transition: all 0.4s ease;
    
    .applicant-infosection{
        display: unset;
        justify-content: unset;
        align-items: unset;
        float: left;
        width: 100%;
        position: absolute;
        top: 0;
        padding: 1em;
        .hiredtab-left{
            float: left;
            width: 50%;
            h2{
                font-size: 14px;
                font-weight: 600;
                color: #222222;
                margin-bottom: 0px;
            }
            p{
                font-size: 11px;
                font-weight: 500;
                color: #9C9AAF;
            }
        }
        .shiredtab-right{
            float: right;
            width: 50%;
            position: relative;
            top: -5px;
            z-index: 1;
            text-align: right;
            .settings{
                img{
                    cursor: pointer;
                }
            }
            .report-menu {
                background: #fff;
                box-shadow: 0px 3px 10px #00000029;
                display: none;
                list-style: none;
                margin-bottom: 0;
                position: absolute;
                z-index: 1;
                text-align: center;
                right: 21px;
                width: 185px;
                top: 7px;
                border-radius: 5px;
                padding: 1em 1.5em;
                li{
                    a{
                        font-size: 14px;
                        font-weight: 600;
                        color: #172940;
                        padding: 10px 0px;
                        border: medium none;
                    }
                }
                &:after{
                    display: none;
                }
            }
        }
    }

    .applicant-shift {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        .engaged-wrapper{
            .engaged-text{
                font-size: 11px;
                font-weight: 400;
                color: #A8A8A8;
                margin: 2px 0px 5px 0px;
            }
        }
        .followers-wrapper{
            float: left;
            width: 100%;
            text-align: center;
            display: inline-block;
             img{
                float: none;
                display: inline-block;
                margin-right: 5px;
            }
            .follower-text{
                float: none;
                display: inline-block;
                font-size: 11px;
                font-weight: 400;
                color: #58DC72;
                top: 2px;
                position: relative;
            }
        }
        .applicant-img-wrapper{
            float: left;
            width: 100%;
            height: 140px;
            text-align: center;
            margin: 0 auto;
            position: relative;
            
            .completed-labe{
                float: left;
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 5px;
                span{
                    background: #58DC72;
                    text-transform: uppercase;
                    padding: 4px 15px;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 600;
                    color: #ffffff;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                }
            }
            .ongoing-labe{
                float: left;
                width: 100%;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                bottom: 5px;
                span{
                    background: #FFC107;
                    text-transform: uppercase;
                    padding: 4px 15px;
                    color: #ffffff;
                    font-size: 12px;
                    font-weight: 600;
                    color: #ffffff;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                }
            }
    
        }
    }

    .hiredbtn-wrapper{
        display: flex;
        .hired-message-btn{
            float: left;
            min-width: 118px;
            height: 35px;
            border:1px solid #DCE0E9;
            background: #F8F8F8;
            text-align: center;
            font-size: 13px;
            font-weight: 600;
            color: #172940;
            margin: 1em 0.5em 0 0.5em;
            transition: all 0.3s ease;
        &:hover{
            background: #1658D3; 
            color: #ffffff;
            border:1px solid #1658D3;
        }
    }
    
        .hired-canclejob-btn{
            float: left;
            min-width: 118px;
            height: 35px;
            border:1px solid #DCE0E9;
            background: #F8F8F8;
            text-align: center;
            font-size: 13px;
            font-weight: 600;
            color: #172940;
            margin: 1em 0.5em 0 0.5em;
            transition: all 0.3s ease;
            &:hover{
            background: #1658D3; 
            color: #ffffff;
            border:1px solid #1658D3;
            }
        }
    }

    &:hover{
        transition: all 0.4s ease;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
    }
    
    }
}

.no-hiredapplicant{
    float: left;
    width: 100%;
    height: 375px;
    background: #fff;
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .no-hired-center{
        margin:0 auto;
        width: 300px;
        text-align: center;
        h2{
            font-size: 15px;
            font-weight: 400;
            color: #C0C4CC;
            margin: 1em 0;
        }
    }
}

.capmback-wrapper{
    float: left;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    display: flex;
}
.disabled.applied-disable{
    background: #1658d3;
    color: #8bace6;
    opacity: 1;
    border: medium none;
}

button.responsive-filter {
    display: none;
    float: right;
    height: 38px;
    width: 38px;
    margin-left: 10px;
    background: #ffffff;
    border: 1px solid #1658D3;
    color: #1658D3;
    transition: all 0.3s ease;
    i{
        padding-top: 5px;
    }
}
.responsive-sorting {
display:none;
}
button.responsive-filter:hover,
button.responsive-filter:focus{
    background: #1658D3;
    border: 1px solid #1658D3;
    color: #ffffff;  
    transition: all 0.3s ease;
}
.applicantspaymentModal{
    .modal-content{
        border-radius: 0px;
        .modal-body{
            padding:2em;
            .modal-title{
                font-size: 22px;
                font-weight: 600;
                color:#222222;
                margin-bottom: 1em;
            }
            .makepayment-avater{
                img{
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    height: 65px;
                    width: 65px;
                    object-fit: cover;
                }
            }
            .makepayment-infowrapper{
                float: left;
                width: 100%;
                h2{
                    font-size: 18px;
                    font-weight: 600;
                    color: #172940;
                    margin-bottom: 0px;
                }
                p{
                    font-size: 11px;
                    font-weight: 600;
                    color: #1658D3;
                    margin: 5px 0px;
                }
                .makepayment-instafollower{
                    background: url('/images/instagram.svg') no-repeat;
                    background-position: left center;
                    padding:0px 15px 0px 22px;
                    font-size:12px;
                    font-weight: 400;
                    color: #172940;

                }
            }
            .makeoffer-modal-form{
                float: left;
                width: 100%;
                margin-top: 2em;
                label{
                    font-size: 13px;
                    font-weight: 600;
                    color: #172940;
                }
                input[type='text']{
                    border:1px solid #E3E3E3;
                    height: 48px;
                    font-size: 13px;
                    font-weight: 400;
                    border-radius: 0px;
                    -webkit-border-radius: 0px;
                    -moz-border-radius: 0px;
                    &:hover{
                        outline: unset;
                        box-shadow: unset;
                    }
                    &:focus{
                        outline: unset;
                        box-shadow: unset;
                    }
                }
                .paybutton{
                    width: 100%;
                    height: 44px;
                    background: #1658D3;
                    text-align: center;
                    color: #ffffff;
                    border:medium none;
                    font-size: 16px;
                    font-weight: 500;
                }
                .whatisescrow-wrapper{
                    float: left;
                    width: 100%;
                    text-align: right;
                    .whatisescrow{
                        font-size: 11px;
                        font-weight: 400;
                        color: #172940;
                    }
                    .report-menu{
                        width: 390px;
                        top: -155px;
                        left: -150px;
                        position: absolute;
                        padding: 1.1em;
                        border-radius: 7px;
                        border: 1px solid #F8F4C4;
                        background: #FFFBD8;
                        text-align: left;
                        h2{
                            font-size: 12px;
                            font-weight: 600;
                            color: #172940;
                        }
                        p{
                            font-size: 12px;
                            font-weight: 400;
                            color: #172940;
                            margin-bottom: 0px;
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            border-bottom: 16px solid #FFFBD8;
                            bottom: -70px;
                            right: -94px;
                            transform: rotate(65deg);
                        }
                    }
                }
            }
            .acceptoffer-label{
                width: 170px;
                position: relative;
                margin-top: 10px;
                display: inline-block;
                height: 24px;
                float: none;
                padding: 5px 5px 5px 25px;
                margin: 0 auto;
                background: #58DC72;
                color: #ffffff;
                font-size: 11px;
                font-style: italic;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                img{
                    position: absolute;
                    left: 7px;
                    top: 6px;
                }
            }
        }
    }
}
.pendingoffer-label{
	background: #FFC107 !important;
}
.modal {
    background: #00000091;
	overflow: hidden;
}
.modal-backdrop{
	display:none;
}
.modal-open {
    overflow: unset;
	padding-right: 0px;
}

    .paymentaccept-infowrapper{
        float: left;
        width:100%;
        background: #FFF6E2;
        padding:1.5em;
        margin: 0px 15px 15px 15px;
        h2{
            float: left;
            width:81%;
            position: relative;
            top: 3px;
            font-size: 12px !important;
            font-weight: 500  !important;
            color: #172940  !important;
            padding:0px !important;
            margin:0px !important;
            span{
                font-weight: 700;
            }
        }
        .payment-action{
            float: right;
            .paymentaccept{
                min-width: 90px;
                height: 28px;
                padding:5px 10px;
                margin: 0px 5px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;   
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                background: #58DC72;
                &:hover{
                    text-decoration: none;
                }
            }
            .paymentreject{
                min-width: 90px;
                height: 28px;
                padding:5px 10px;
                margin: 0px 5px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;   
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                background: #FF4444;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
#cenclejobModal{
    .modal-content{
        border-radius: 0px;
        .modal-body{
            text-align: center;
            h2{
               font-size: 20px;
               font-weight: 600;
               color: #172940; 
               margin-top: 0.5em;
            }
            p{
                font-size: 13px;
                font-weight: 600;
                color: #172940;
                margin: 1.5em 0;
            }
            .cancle-job-btns{
                min-width: 88px;
                height: 44px;
                text-align: center;
                background: #1658D3;
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                border:medium none;
                margin: 0.5em 8px;
            }
        }
    }
}

    
#releasepaymentsModal{
    .modal-content{
        border-radius: 0px;
        .modal-body{
            text-align: center;
            h2{
               font-size: 20px;
               font-weight: 600;
               color: #172940; 
               margin-top: 0.5em;
            }
            p{
                font-size: 13px;
                font-weight: 600;
                color: #172940;
                margin: 1.5em 0;
            }
            .cancle-job-btns{
                min-width: 88px;
                height: 44px;
                text-align: center;
                background: #1658D3;
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                border:medium none;
                margin: 0.5em 8px;
            }
        }
    }
}

#raiseisdputeModal{
    .modal-content{
        border-radius: 0px;
        padding: 1em;
        .modal-body{
            h2{
               font-size: 20px;
               font-weight: 600;
               color: #172940; 
               margin-bottom: 1em;
            }
            p{
                font-size: 13px;
                font-weight: 600;
                color: #172940;
                margin: 1.5em 0;
            }
            .cancle-job-btns{
                min-width: 88px;
                height: 44px;
                text-align: center;
                background: #1658D3;
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                border:medium none;
                margin: 0.5em 8px;
            }
            .makepayment-avater{
                img{
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    height: 65px;
                    width: 65px;
                    object-fit: cover;
                }
            }
            .makepayment-infowrapper{
                float: left;
                width: 100%;
                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #172940;
                    margin-bottom: 0px;
                }
                p {
                    font-size: 11px;
                    font-weight: 600;
                    color: #1658D3;
                    margin: 5px 0px;
                }
            }
            .raisedispute-form{
                .paybutton{
                    width: 100%;
                    height: 44px;
                    background: #1658D3;
                    text-align: center;
                    color: #ffffff;
                    border: medium none;
                    font-size: 16px;
                    font-weight: 500;
                }
                textarea{
                    border-radius: 0px;
                    -webkit-border-radius: 0px;
                    -moz-border-radius: 0px;
                    resize: none;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
        }
    }
}


#writeareviewModal{
    .modal-content{
        border-radius: 0px;
        padding: 1em;
        .modal-body{
            h2{
               font-size: 20px;
               font-weight: 600;
               color: #172940; 
               margin-bottom: 1em;
            }
            p{
                font-size: 13px;
                font-weight: 600;
                color: #172940;
                margin: 0.5em 0 1em 0;
            }
            .cancle-job-btns{
                min-width: 88px;
                height: 44px;
                text-align: center;
                background: #1658D3;
                font-size: 16px;
                font-weight: 600;
                color: #ffffff;
                border:medium none;
                margin: 0.5em 8px;
            }
            .makepayment-avater{
                img{
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    height: 65px;
                    width: 65px;
                    object-fit: cover;
                }
            }
            .makepayment-infowrapper{
                float: left;
                width: 100%;
                h2 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #172940;
                    margin-bottom: 0px;
                }
                p {
                    font-size: 11px;
                    font-weight: 600;
                    color: #1658D3;
                    margin: 5px 0px;
                }
            }
            .raisedispute-form{
                .paybutton{
                    width: 100%;
                    height: 44px;
                    background: #1658D3;
                    text-align: center;
                    color: #ffffff;
                    border: medium none;
                    font-size: 16px;
                    font-weight: 500;
                }
                textarea{
                    border-radius: 0px;
                    -webkit-border-radius: 0px;
                    -moz-border-radius: 0px;
                    resize: none;
                    font-size: 13px;
                    font-weight: 400;
                }
            }
            .writereview-rating{
                float: left;
                width: 100%;
                margin: 1em 0;
                img{
                    float: left;
                }
                span{
                    float: left;
                    font-size: 13px;
                    font-weight: 400;
                    color: #172940;
                    margin: 5px 0px 0px 10px;
                }
            }
        }
    }
}

.paymentaccept-infowrapper1 {
    float: left;
    width: 100%;
    background: #e4e2ff80;
    padding: 1.5em;
    margin: 0px 15px 10px 15px;
}
.paymentaccept-infowrapper1 .payment-action1 {
    float: left;
}

.paymentaccept-infowrapper1 .payment-action1 .paymentaccept1 {
    min-width: 90px;
    height: 28px;
    padding: 6px 10px;
    margin: 0px 5px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    background: #58DC72;
}

.paymentaccept-infowrapper1 h2 {
    float: right;
    width: 81%;
    position: relative;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #172940 !important;
    padding: 0px !important;
    margin: -5px !important;
}
.minfollowersshould div{
	display:contents;
}
.suggested-wrapper{
	border-right:unset !important
}
.applicant-awaiting{
	pointer-events: none !important;
}
.bankinfonote{
	color: #858a8fed;
    font-style: italic;
    font-size: 11px;
    opacity: 0.7;
    margin-top: 8px;
}

.hired-menu li a{
	padding: 5px 0px !important;
}

.payoutSuccess .popup-content {
	padding:0px;
	width:30% !important;
}

.showmycolor{
    background: #FFC107;
    height: 8px;
    line-height: 15px;
    position: absolute;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.shoutoutcolor{
    background: #58DC72;
    height: 8px;
    line-height: 15px;
    position: absolute;
    top: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}