.transactionhistory_page{
    .accordion-wrapper{
        float: left;
        width: 100%;
        #accordion{
            float: left;
            width: 100%;
            
            .card{
                border:medium none;
                .card-header{
                    padding:0px;
                    border-top: 1px solid #E8E8E8;
                    border-bottom: 1px solid transparent;
                    background: #ffffff;
                    &:hover{
                        background: #F9F9F9; 
                    }
                }
                .card-body{
                    float: left;
                    width: 100%;
                    background: #F9F9F9;
                    padding: 0px 0px 20px 75px;
                    margin-top: -1px;
                    .accordion-inner-wrapper{
                        float: left;
                        width: 100%;
                        .accordion-info-right{
                            h2{
                                border-top: 1px solid #E8E8E8;
                                padding: 20px 0px;
                                margin-bottom: 0px;
                                font-size: 12px;
                                color: #A8A8A8;
                            }
                            .productlist-img-wrapper{
                                float: left;
                                width: 100%;
                                display: flex;
                                img{
                                    margin-right: 15px;
                                }
                                h2{
                                    border: medium none;
                                    font-size: 15px;
                                    font-weight: 600;
                                    color: #172940;
                                    padding: 0px;
                                }
                                h3{
                                    font-size: 15px;
                                    font-weight: 500;
                                    color: #1658D3;
                                    margin: 12px 0px 5px 0;
                                }
                                p{
                                    font-size: 13px;
                                    font-weight: 400;
                                    color: #A8A8A8;
                                    margin: 0px 0px 6px 0px;
                                }
                                a{
                                    font-size: 13px;
                                    font-weight: 400;
                                    color: #1658D3;
                                    padding: 0px;
                                    &:hover{
                                        text-decoration: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .card:first-child{
                .card-header{
                    border-top: 1px solid #E8E8E8;
                }
            }
            .card:last-child{
                .card-header{
                    border-bottom: 1px solid #E8E8E8;
                }
            }
            .card:last-child{
                // .card-header{
                //     border-top: 1px solid rgba(0,0,0,.125);
                // }
                .collapse.show{
                    border-bottom: 1px solid #E8E8E8;
                }
            }
            .collapse.show{

            }
        }
        
        .accordionlist-info{
            float: left;
            width: 100%;
            padding: 10px;
            background:#ffffff;
            .accordionimg{
                float: left;
                margin-right: 10px;
            }
            .listwrapper{
                float: right;
                width: 92%;
                .listwrapper_left{
                    float: left;
                    width: 50%;
                    font-size: 14px;
                    font-weight: 600;
                    color: #172940;
                    line-height: 21px;
                    cursor: pointer;
                }
                .listwrapper_right{
                    float: left;
                    width: 50%;
                    span.addamount{
                        font-size: 14px;
                        font-weight: 600;
                        color: #58DC72; 
                        line-height: 21px;
                        float: right;
                        &::after{
                            float: right;
                            content:'';
                            transition:0.4s ease;
                            background:url('/images/accordion-arrow.svg') no-repeat;
                            height: 25px;
                            width: 25px;
                            position: relative;
                            top: -2px;
                        }
                    }
                    span.lessamount{
                        font-size: 14px;
                        font-weight: 600;
                        color: #FF4444; 
                        line-height: 21px;
                        float: right;
                        &::after{
                            float: right;
                            content:'';
                            transition:0.4s ease;
                            background:url('/images/accordion-arrow.svg') no-repeat;
                            height: 25px;
                            width: 25px;
                            position: relative;
                            top: -2px;
                        }
                    }
                }
            }
            .listwrapper_date{
                float: right;
                width: 92%;
                font-size: 12px;
                font-weight: 400;
                color: #172940;
                margin-top: 0px;
            }
        }
        .accordionlist-info.collapsed{
            .listwrapper{
                .listwrapper_right{
                    span.lessamount{
                        font-size: 14px;
                        font-weight: 600;
                        color: #FF4444; 
                        line-height: 21px;
                        float: right;
                        &::after{
                            float: right;
                            content:'';
                            transition:0.4s ease;
                            background:url('/images/accordion-arrow.svg') no-repeat;
                            transform: rotate(360deg);
                            height: 25px;
                            width: 25px;
                        }
                    }
                    span.addamount{
                        font-size: 14px;
                        font-weight: 600;
                        color: #58DC72; 
                        line-height: 21px;
                        float: right;
                        &::after{
                            float: right;
                            content:'';
                            transition:0.4s ease;
                            background:url('/images/accordion-arrow.svg') no-repeat;
                            transform: rotate(360deg);
                            height: 25px;
                            width: 25px;
                        }
                    }
                }
            }
        }

        .accordionlist-info[aria-expanded="false"]{
            background: #ffffff;
        }

        .accordionlist-info[aria-expanded="true"]{
            background: #F9F9F9;
            .addamount{
                &::after{
                    transform: rotate(90deg);  
                }
            }
            .lessamount{
                &::after{
                    transform: rotate(90deg);  
                }
            }
        }
    }
    .product-list{
        display: unset;
        .transactionhistory-tabs-wrapper{
            .tab-top-wpr{
                border-bottom: 1px solid #dee2e6;
            }
            #myTabContent{
                .product-header{
                    padding: 12px 0 12px 0;
                }
            }
            .accordion-wrapper{
                #accordion{
                    margin-bottom: 2rem;
                }
            }
        }
    }
}