.create-campaigns{
    background: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left:0;
    height: 100%;
    z-index: 20;
    .popup-content {
        max-width: 490px;  
    }
    .create-right{
        background: $white;
        color: $primaryColor;
        min-height: 100vh;
        padding: 35px 0 0 0 ;
        position: absolute;
        right: 0;
        .inner-create-right{
            // .create-form-label{
            //     display: block;
            // }
            padding-left: 0px;
            #createCampaigns{
                .create-form-label{
                    display: block;
                    
                }
                input[type='file']{
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .campaignimage{
                    float: left;
                    text-align: left;
                    width: 100%;
                    margin-bottom: 2em;
                    .campaignimage-wrapper{
                        float: left;
                        position: relative;
                        img{
                            width: 200px !important;
                            height: 200px;
                            object-fit: cover;
                            margin: 1em 1em 1em 0em;
                        }
                        .delete-img-icon{
                            img{
                                width: 45px !important;
                                height: auto;
                                &:hover{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .delete-img-icon{
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        z-index: 1;
                    }
                    
                }
                #campaignsInfo{
                    .close-campaign{
                        position: absolute;
                        top: 10px;
                        left: -45px;
                        width: 45px;
                        background: #fff;
                        border-radius: 8px 0px 0px 8px;
                    }
                }
                
            }
            .form-group{
                margin-bottom: 25px;
            }
            .form-control{
                border:1px solid #E3E3E3;
            }
            .custom-checkbox{
                label.radio-label{
                    @include font-style(14px , 500, 21px)
                }
            }
        }
        .custom-nav {
            .nav-link{
                &::before{
                    background-color: #1658D3;
                }
            }
        }
        .create-camp-content{
            min-height: calc(100vh - 172px);
            max-height: calc(100vh - 172px);
            padding-right: 15px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-top:0px ;
            padding-bottom: 0px;
            margin-right: 10px;
        }
        .drag-drop{
            margin-bottom: 25px;
            .dzu-dropzone{
                border: 1px dotted #E3E3E3;
                min-height: 221px;
                overflow: hidden;
                .drag-wpr{
                    color: $primaryColor;
                    p{
                        margin: 0;
                    }
                    .drag-title{
                        @include font-style(14px , 600, 21px);
                        margin-bottom: 10px;
                    }
                    .drag-text{
                        @include font-style(12px , 400, 18px);
                        opacity: 0.5;
                        max-width: 72%;
                        margin: 0 auto 17px auto;
                    }
                    .drag-size{
                        @include font-style(12px , 400, 18px);
                        opacity: 0.2;
                    }
                }
            }
        }
    }
   
}
.campaigns-drafts{
    background: rgba(0,0,0,0.6);
    position: fixed;
    top: 0;
    left:0;
    height: 100%;
    z-index: 20;
    .popup-content {
        max-width: 490px;  
    }
    .create-right{
        background: $white;
        color: $primaryColor;
        min-height: 100vh;
        padding: 35px 0 0 0 ;
        position: absolute;
        right: 0;
        .inner-create-right{
            // .create-form-label{
            //     display: block;
            // }
            padding-left: 0px;
            #createCampaigns{
                .create-form-label{
                    display: block;
                    
                }
                input[type='file']{
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .campaignimage{
                    float: left;
                    text-align: left;
                    width: 100%;
                    .campaignimage-wrapper{
                        float: left;
                        position: relative;
                        img{
                            width: 200px !important;
                            height: 200px;
                            object-fit: cover;
                            margin: 1em 1em 1em 0em;
                        }
                        .delete-img-icon{
                            img{
                                width: 45px !important;
                                height: auto;
                                &:hover{
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                    .delete-img-icon{
                        position: absolute;
                        top: 0px;
                        right: 0px;
                        z-index: 1;
                    }
                    
                }
                #campaignsInfo{
                    .close-campaign{
                        position: absolute;
                        top: 10px;
                        left: -45px;
                        width: 45px;
                        background: #fff;
                        border-radius: 8px 0px 0px 8px;
                    }
                }
                
            }
            .form-group{
                margin-bottom: 25px;
            }
            .form-control{
                border:1px solid #E3E3E3;
            }
            .custom-checkbox{
                label.radio-label{
                    @include font-style(14px , 500, 21px)
                }
            }
        }
        .custom-nav {
            .nav-link{
                &::before{
                    background-color: #1658D3;
                }
            }
        }
        .create-camp-content{
            min-height: calc(100vh - 172px);
            max-height: calc(100vh - 172px);
            padding-right: 15px;
            overflow-y: scroll;
            overflow-x: hidden;
            padding-top:0px ;
            padding-bottom: 0px;
            margin-right: 10px;
        }
        .drag-drop{
            margin-bottom: 25px;
            .dzu-dropzone{
                border: 1px dotted #E3E3E3;
                min-height: 221px;
                overflow: hidden;
                .drag-wpr{
                    color: $primaryColor;
                    p{
                        margin: 0;
                    }
                    .drag-title{
                        @include font-style(14px , 600, 21px);
                        margin-bottom: 10px;
                    }
                    .drag-text{
                        @include font-style(12px , 400, 18px);
                        opacity: 0.5;
                        max-width: 72%;
                        margin: 0 auto 17px auto;
                    }
                    .drag-size{
                        @include font-style(12px , 400, 18px);
                        opacity: 0.2;
                    }
                }
            }
        }
    }
   
}

#CampaignsPreview{
    .campaignimage{
        text-align: left;
        img{
            width: 200px !important;
            height: 200px;
            object-fit: cover;
            margin: 1em 1em 1em 0em;
        }
    }
}

.footer-button-group{
    background: $white;
    position: absolute;
    left: 15px;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #E3E3E3;
    padding:18px 36px;
    @include flexbox();
    @include justify-content(space-between);
    z-index: 1;
    .btn-left-wpr{
        padding-left: 0;
        padding-right: 0;
    }
    .btn-right-wpr{
        text-align: right;
        padding-left: 0;
        padding-right: 0;
    }
}

.custom-popup{
    padding: 40px 20px;
    .popup-heading{
        color: $primaryColor;
        @include font-style(18px , 600, 27px);
    }
    .popup-details{
        color: $primaryColor;
        @include font-style(13px , 500, 20px);
    }
}

.camp-preview{
    min-height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
    padding-right: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-top:30px ;
    padding-bottom: 40px;
    margin-right: 10px;
    .prev-heading{
        color: #6B6A77;
        @include font-style(18px, 400, 27px);
    }
    .prev-sub-heading{
        color: #6B6A77;
        @include font-style(12px, 400, 18px);
    }
    .image-preview{
        margin-top: 0px;
        float: left;
        width: 100%;
        margin-bottom: 2em !important;
        img{
            height: 315px;
            object-fit: cover;
            width: 100%;
        }
    }
    .prev-title{
        color: #6B6A77;
        @include font-style(13px, 500, 13px);
    }
    .prev-text{
        color: #3D4046;
        @include font-style(14px, 500, 21px);
    }
    
}

.edit-camp-btn{
    svg{
        position: relative;
        top: 2px;
    }
}

.one-line-ellipsis{
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.five-line-ellipsis{
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.camp-added-success{
    .inner-camp-added-success {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .camp-success{
        max-width: 400px;
        img{
            margin: 0 auto 40px auto;
            width: 125px;
        }
        h2{
            color: #3D4046;
            @include font-style(20px , 500, 30px);
            margin-bottom: 20px;
        }
        p{
            color: $primaryColor;
            @include font-style(13px , 500, 20px);
            &.allow-text{
                color: #1658D3;
                margin: 33px 0;
            }
        }

    }
}

.draft-camp{
    padding-right: 15px;
    .no-image{
        background: #1658D3;
        min-height: 220px;
        margin-right: 10px;
        width: 100%;
    }
    h3{
        color: $primaryColor;
        @include font-style(16px, 600, 25px);
        margin: 20px 0 70px 0;
    }
    p{
        color: $primaryColor;
        @include font-style(14px, 500, 21px);
        opacity: 0.5;
    }
}

.hashtag-input{
    .cNrjqJ{
        box-shadow: unset;
        background: unset;
        border: 1px solid #E3E3E3;
        border-radius: 0;
        color: $primaryColor;
        font-family: 'Poppins', sans-serif;
        @include font-style(14px, 500, 21px);
        position: relative;
        transform: unset;
        left: unset;
        top: unset;
        width: 100%;
    }
    span.cMWoBu{
        background: #DEDEDE;
        color: #4B5460;
        @include font-style(14px, 500, 21px);
        padding: 5px 10px;
    }
    span.sc-bxivhb {
        color: #4B5460;
    }
    input{
        background: unset;
        padding:5px 10px;
        font-family: 'Poppins', sans-serif;
        font-style: unset;
        @include font-style(14px, 500, 21px);
        &::-webkit-input-placeholder { font-style: normal;}
        &::-moz-placeholder {font-style: normal;} /* firefox 19+ */
        &:-ms-input-placeholder { font-style: normal; } /* ie */
        &:-moz-placeholder {font-style: normal;}
    }
}

.pulse-notification {
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background:rgba(22, 88, 211, 1);
    cursor: pointer;
    animation: pulse-danger 2s infinite;
    animation-duration: .9s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
}

@-webkit-keyframes pulse-danger {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(22, 88, 211, 0.9);
    }
  
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(220, 53, 69, 0);
    }
  
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
    }
  }

  @keyframes pulse-danger {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(22, 88, 211, 0.9);
    }
  
    70% {
      -webkit-box-shadow: 0 0 0 10px rgba(220, 53, 69, 0);
    }
  
    100% {
      -webkit-box-shadow: 0 0 0 0 rgba(220, 53, 69, 0);
    }
  }

  .date-error{
      @include font-style(12px, 400, 18px);
  }
.image-preview-list{
    margin-top: 10px;
    .img-preview{
        height: 300px;
        overflow: hidden;
        img{
            display: block;
            height: 300px;
            object-fit: cover;
        }    
    }
}
.campaign-price-wpr{
    position: relative;
    .form-control.campaign-price{
        padding-right: 55px;
    }
    .usd-text{
        background: transparent;
        bottom: 1px;
        color: #6c757d;
        height: 41px;
        font-size: 14px;
        font-weight: 500;
        position: absolute;
        padding-top: 10px;
        right: 0;
        text-align: center;
        text-transform: uppercase;
        width: 49px;
    }
}


.campaign-date-wpr{
    position: relative;
    .cal-icon{
        bottom:15px;
        top:unset;
    }
}

.camp-date-error{
    font-size: 12px;
    font-weight: 400;
}

.camp-icon-container {
  position: absolute;
  right: -24px;
  top: calc(50% - 10px);
}
.camp-loader {
  position: relative;
  height: 20px;
  width: 20px;
  display: inline-block;
  animation: around 5.4s infinite;
}

@keyframes around {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.camp-loader::after, .camp-loader::before {
  content: "";
  background: white;
  position: absolute;
  display: inline-block;
  width: 100%;
  height: 100%;
  border-width: 2px;
  border-color: #FF007A #FF007A transparent transparent;
  border-style: solid;
  border-radius: 20px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: around 0.7s ease-in-out infinite;
}

.camp-loader::after {
  animation: around 0.7s ease-in-out 0.1s infinite;
  background: transparent;
}
.minimumpreview div{
	text-align:left !important
}
.campaign-amount{
    padding-right: 15px;
    padding-left: 0px;
}
.createcampaign-moreless a:hover {
    text-decoration: none;
}

.createcampaign-moreless a {
    color: #ff5baa;
    font-weight: 500;
}
.createcampaign-moreless br{
	display: contents;
}
.createcampaign-moreless div{
	text-align: justify;
}





.box {
    display: block;
  }
#fade-in {
    height: 150px;
    width: 1px;
    opacity: 0;
    // transition: all 0.75s ease;
  }
  
  #fade-in.show {
    opacity: 1;
    width: 100%;
  }
  
  #spin-in {
    opacity: 0.2;
    height: 50px;
    width: 50px;
    transform: translateX(0) rotate(0deg);
    // transition: all 0.75s ease;
  }
  
  #spin-in.show2 {
    transform: translateX(450px) rotate(720deg);
    opacity: 1;
  }
  
  h1.spacing {
    letter-spacing: 0;
    opacity: 0.5;
    // transition: all 0.75s ease-out;
  }
  
  h1.spacing.spaced {
    opacity: 1;
    letter-spacing: 10px;
  }
//   .addwidth{
//       width: 50%;
//       padding-left: 35px !important;
//   }
 
  .create-right{
      .slide{
          position: absolute;
          left: -35px;
          top: 0px;
          background: #fff;
            border-radius: 8px;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
          img{
              width: 35px;
              height: 35px;
              
        }
      }
	  .slidenew{
          position: absolute;
          left: -35px;
          top: 0px;
          background: #fff;
          opacity: 1;
          transition: all 0.3s ease;
            border-radius: 8px 0px 0px 8px;
            -webkit-border-radius: 8px 0px 0px 8px;
            -moz-border-radius: 8px 0px 0px 8px;
          img{
              width: 35px;
              height: 35px;
              
            }
            &:hover{
                opacity: 0.8;
                transition: all 0.3s ease;
                cursor: pointer;
            }
      }
  }

#createDraftPopup{
    #fade-in{
        opacity: 1;
        // background: #f00;
        width: 100%;
        #draftCampaigns{
            .page-heading{
                float: left;
                width: 100%;
                margin-bottom: 1em;
                // color: lawngreen;
            }
            h3{
                font-size: 16px;
                font-weight: 600;
                line-height: 25px;
                color: #172940;
                margin-top: 1em;
            }
            .readcareful-wrapper{
                height: 350px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                h4{
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    color:#8B949F;
                }
                h5{
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    text-align: center;
                    margin: 1em 0;
                    color:#8B949F;
                }
                .continue-drafted{
                    width: 315px;
                    height:44px;
                    opacity: 0.8;
                    padding: 0px 15px;
                    background: #1658D3;
                    border:medium none;
                    font-size: 13px;
                    font-weight: 600;
                    color: #ffffff;
                    margin: 1em 0;
                    transition: all .5s ease;
                    img{
                        margin-left: 5px;
                    }
                    &:hover{
                      opacity: 1;
                      transition: all .5s ease;
                    }
                }
            }
        }
        
    }
}

#createCampaignsPopup{
    #fade-in{
        opacity: 1;
        width: 100%;
        #draftCampaigns{
            .page-heading{
                float: left;
                width: 100%;
                margin-bottom: 1em;
                // color: lawngreen;
            }
        }
        
    } 
}

.createrightDraft{
    width: 40%;
    padding: 3em !important;
    position: absolute;
    right: 0;
    color: #172940;
    min-height: 100vh;
    .draftsave{
        position: absolute;
        left: -35px;
        top: 0px;
        background: #fff;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        img{
            width: 35px;
            height: 35px;
            
        }
    }
}

.createrightCreate{
    width: 50%;
    padding: 3em !important;
    position: absolute;
    right: 0;
    color: #172940;
    min-height: 100vh;
    .draftsave{
        position: absolute;
        left: -35px;
        top: 0px;
        background: #fff;
        border-radius: 8px;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        img{
            width: 35px;
            height: 35px;
            
        }
    }
}



  .image-upload{
    border: medium none !important;
    height: 115px;
    width: 115px;
    padding: 1em 0px 0px 0px !important;
      .uploadimg-label{
          float: left;
          img{
              cursor: pointer;
          }
      }
	  .image-size{
		img{
			float: left;
			width: 80%;
		}
	  }
      input {
        display: none;
      }
  }

#camp-image-input+button{
	display:none;
}

.campaignFollowers-options{
    float: left;
    width: 100%;
    margin-bottom: 2em;
}