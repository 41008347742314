.search-inf-wpr{
    .search-inf{
        @include flexbox();
        margin: 20px 0;
        position: relative;
        .search-icon{
            color: #6E7884;
            position: absolute;
            top: 17px;
            left: 12px;
        }
        input{
            background: #F9F9F9;
            border: 1px solid #F9F9F9;
            border-radius:0 ;
            border-right:0 ;
            color: #6E7884;
            font-size: 14px;
            padding: 10px 20px 10px 30px;
            &:focus{
                box-shadow: none;
            }
        }
        button{
            border: 1px solid #F9F9F9;
            font-size: 14px;
            font-weight: 500;
            width: 124px;
        }
    }
}



.find-result-sort-wpr{
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
            margin-bottom: 27px;
    .custom-sort{
        label{
            padding-right: 10px;
        }
        select{
            min-width: 225px;
        }
    }
    .found-inf{
        color: #6E7884;
        font-size: 14px;
        margin: 0;
        .found-type{
            color: #1658D3;
        }
    }
}

.applicants_bx {
    background: #F9F9F9;
    border: 1px solid #F9F9F9; 
    text-align:center; 
    margin-bottom:24px;
    position: relative;
    -webkit-transition: 0.5s;
     -o-transition: 0.5s;
     transition: 0.5s;
    &:hover{
        background: transparent;
        -webkit-box-shadow: 0px 3px 30px #0000000F;
        box-shadow: 0px 3px 30px #0000000F;
        -webkit-transform: translateY(-10px);
            -ms-transform: translateY(-10px);
            transform: translateY(-10px);
    }

    .overlay-text{
        background: rgba(0,0,0,0.4);
        position: absolute;
        padding: 15px 30px;
        overflow: hidden;
        visibility: hidden;
        top: -10%;
        left: 0%;
        height: 100%;
        width: 100%;

        .inner-overlay-wpr{
            @include flexbox();
            @include justify-content(center);
            @include align-items(center);
            @include flex-wrap(wrap);
            height: 100%;
        }
        .overlay-close{
            color: #fff;
            cursor: pointer;
            font-size: 14px;
            text-align: left;
        }
        .cuss{
            span{
                display: inline-block;
                margin-right: 5px;
                margin-bottom: 5px;
                &:last-child{
                    background: #DBE0EB 0% 0% no-repeat padding-box;
                    border-radius: 3px;
                    color: #2E3442;
                    font-size: 10px;
                    height: 20px;
                    margin-right: 5px;
                    padding: 3px 7px;
                    width: 56px;
                    
                }
            }
            
        }
    }
    .editp {
        width:140px; 
        height:140px; 
        background:#ffffff; 
        color:#fff; 
        font-weight:500; 
        border-radius:50%; 
        margin: 20px auto 0  auto; 
        overflow: hidden;
        padding:0;
        img{
            width: 100%;
        }
    }
    h2{
        color:#172940; 
        font-size:18px; 
        line-height:25px; 
        font-weight:600; 
        margin-top:20px; 
        min-height: 25px;
        margin-bottom:4px;
        a{
            color: #172940;
            &:hover{
                color: #ff5baa;
                text-decoration: none;
            }
        }
    }
    span.username{
        font-size: 11px; 
        color:#1658D3; 
        font-weight: 600;
        a{
            &:hover{
                color:#1658D3;
                text-decoration: none;
            }
        }
    }
    p{
        font-size: 12px; 
        color:#6B6A77; 
        margin:7px 0 11px;  
        font-weight:400; 
        line-height:17px;
        min-height: 20px;
    }
    .cuss{
        margin-bottom:15px;
        span{
            width:56px; height:20px; 
            background:#DBE0EB 0% 0% no-repeat padding-box; 
            border-radius:3px; 
            color:#2E3442; 
            font-size:10px;
            padding:3px 7px; 
            margin-right: 2px;
            &:last-child{
                width:20px; 
                height:20px; 
                background:#1658D3 0% 0% no-repeat padding-box; 
                cursor: pointer;
                border-radius:3px; 
                color:#fff; 
                padding:3px 7px;
            }
        }
    }
}

.ap_social{
    border-top: 1px solid #DCE0E9; 
    padding:10px 0 ;
    span{
        font-size:12px; color:#172940; font-weight:400;
        i{
            font-size:18px;
        }
        img{
            width:16px;
        }
    }
    .social-count {
        margin: 0;
        color: #172940; 
        @include flexbox();
        @include justify-content(space-evenly);
        width: 100%;
        .social{
            @include justify-content(center);
            @include align-items(center);
            list-style: none;
            width: 50%;
            .fa-facebook-square{
                color: #2E4E91;
                font-size: 18px;
                margin-right: 8px;
            }
            .fa-instagram{
                color: #FF007A;
                font-size: 18px;
                margin-right: 8px;
            }
            .heist {color:#DCE0E9;}
            .s-count{
                @include font-style(18px, 400, 21px);
            }
            .s-text{
                color: #6B6A77;
                @include font-style(12px, 400, 18px);
            }
        }
        .insta-wpr{
           border-left: 1px solid #DCE0E9;
        }
    }
}

.fabk svg path {
  fill: #2E4E91;
}
.ap_social span img:hover svg path {
  fill: #ff007a;
}

.dotsbul{
    color: #767F95;
    cursor: pointer;
    font-size: 16px;
    position: absolute;
    right: 27px;
    top: 27px;
}
.report-menu{
    background: #fff;
    box-shadow: 0px 3px 10px #00000029;
    display: none;
    list-style: none;
    margin-bottom: 0;
    position: absolute;
    text-align: center;
    right: -20px;
    width: 170px;
    top: 32px;
    &::after{
        content: '';
        position: absolute;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 16px solid #fff;
        top: -16px;
        right: 19px;
    }
    li{
        text-align: left;
        a{  
            color: #172940;
            font-size: 14px;
            font-weight: 500;
            display: block;
            padding:10px 15px;
            border-bottom: 1px solid #e5e5e5;
            text-decoration: none;
            img{
                margin-right: 8px;
            }
        }
        &:last-child a{
            border-bottom: 0;
        }
        &:first-child img{
            margin-right: 11px;
        }
    }
}
.dotsbul:hover .report-menu{
    display: block;
}

.separator{
    border-top: 1px solid #E5E5E5;
    margin: 15px 0;
}


.influen-search-wpr{
    max-width: 320px;
    background: #F9F9F9;
    padding: 20px;
    
   .search-clear-wpr{
       @include flexbox();
       font-size: 14px;
       @include justify-content(space-between);
       margin-bottom: 5px;
       .seacrh-by{
           color: #8C949E;
       }
       .clear-all{
           color: #1658D3;
           cursor: pointer;
           font-weight: 500;
       }
   }
   .inf-text{
       border-top:1px solid #E5E5E5;
       color: #8C949E;
       font-size: 14px;
       font-weight: 500;
       margin-top: 10px;
       padding:15px 0;
       line-height: 21px;
   }
   .searched-cat{
        margin-bottom: 25px;
        span{
            background: #E8E8E8;
            padding: 2px 5px;
            display: inline-block;
            color: #6B6A77;
            font-size: 11px;
            margin-right: 5px;
            margin-bottom: 10px;
            i{
                margin-left: 1px;
            }
        }
    }

    .cat-list{
        max-height: 200px;
        overflow-y: scroll;
        label{
            font-weight: 400;
            font-size: 13px;
            .checkbox-text{
                @include flexbox();
                @include justify-content(space-between);
            }
        }
        .form-check{
            padding: 5px 10px 5px 22px ;
            .form-check-label{
                display: block;
                width: 100%;
            }
            .form-check-input{
                margin-top: 3px;
            }
        }
    }
    .form-heading{
        color: #172940;
        font-size: 13px;
        font-weight: 600;
    }

    .gender-list,.age-list{
        margin-bottom: 20px;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(space-between);
        span{
            background: rgba(23, 41, 64, 0.04);
            border-radius:18px ;
            color: #172940;
            cursor: pointer;
            font-size: 13px;
            font-weight: 400;
            display: inline-block;
            margin-right: 2px;
            margin-bottom: 10px;
            padding: 8px 15px;
            min-width: 80px;
            text-align: center;
            &:hover, &.active{
                background: #1658D3;
                color: #ffffff;
            }
        }
    }
    .age-list{
        span{
            min-width: 66px;
        }
    }
}

.showOverlay{
    visibility: visible  !important;
    top:0 !important;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.no-result-wpr{
    @include flexbox();
    @include justify-content(center);
    @include align-items(center);
    min-height: 50vh;
    min-width: 100%;
    .no-result{
        color: #aebac6;
        @include font-style(14px, 400, 1.4);
    }
    .no-result-img{
        height: 160px;
        width: 160px;
        margin: 0 auto 20px auto;
        img{
            width: 100%;
        }
    }
}

.search-influencer-wpr{
    .my-campaign-search{
        margin-bottom: 30px;
    }
    .custom-sort{
        select{
            min-width: 250px;
        }
    }
}

.influencer-lists{
    .col-12, .col-xl-3, .col-lg-3, .col-md-4, .col-sm-12{
        padding-left: 12px;
        padding-right: 12px;
    }
}