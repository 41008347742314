.username-main{
    margin-bottom: 50px;
    .username-wpr{
        position: relative;
        padding-left: 15px;
        form{
            @include flexbox();
            @include justify-content(space-between);
            @include align-items(center);
            min-width: 432px;
            label{
                @include font-style(13px, 500, 20px);
            }
            input{
                border: 1px solid #E3E3E3;
                border-radius: 0;
                font-size: 13px;
                min-height:44px;
                width: 312px;
                &:focus{
                    box-shadow: none;
                }
            }
            button{
                margin-left: 23px;
                margin-top: 9px;
                height: 44px;
                width: 97px;
            }
        }
        &:before{
            border: 1px solid #cccccc;
            content: '';
            right: -160px;
            height: 28px;
            position: absolute;
            top: -185px;
            transform: rotate(45deg);
            width: 28px;
        }
        &:after{
            content: '' !important;
            background: url('../../images/circleafter.png') no-repeat !important;
            height: 28px;
            top: 75px;
            left: -200px;
            width: 28px;
            position: absolute;
        }
    }
    
    .lets-text{
        color: #172940;
        @include font-style(16px, 400, 28px);
        margin-top: 60px;
    }
    .exist-username-text, .go-ahead{
        color: #FF4444;
        @include font-style(12px, 500, 18px);
        margin: 0 0 5px 0;
        padding: 0;
        span{
            margin-right: 5px;
        }
    }
    .go-ahead{
        color: #5DB75D;
    }
}


