.products-page{
    .page-heading{
        margin-bottom:0px;
    }
    .product-header{
        padding: 0px 0 35px 0;
        float: left;
        width: 100%;
        max-height: 75px;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
    
        .campaign-header-left{
            .found-inf{
                color: #6E7884;
                font-size: 14px;
                margin:0;
                .found-type{
                    color: #1658D3
                }
            }
            .searchkeylabel-text{
                font-size: 14px;
                font-weight: 500;
                color: #6E7884;
                margin-top: 1em;
                span{
                    font-size: 14px;
                    font-style: italic;
                    color: #1658D3;
                }
            }
        }
        .campaign-header-right{
            .form-group{
                display: table;
                margin-bottom: 0;
                position: relative;
                width: 240px;
                .down-arrow {
                    position: absolute;
                    top:15px;
                    right:8px;
                }
                label{
                    color: #6F7B8A;
                    display: table-cell;
                    font-size: 14px;
                }
                select{
                    border: 1px solid $borderColor ;
                    border-radius: 0;
                    font-size: 13px;
                    font-weight: 400;
                    color: $primaryColor;
                    min-height: 40px;
                    -webkit-appearance: none;
                    -moz-appearance: none;
                        appearance: none;
                    &:focus{
                        border: 1px solid $borderColor ; 
                        box-shadow: none;
                    }
                }
            }
            .shortingselect-custom{
                position: relative;
                top: -1px;
            }
        }
        .search-inf{
            margin:0px;
        }
    }
    .campaign-search-wpr{
        max-width: 320px;
        background: $grey1 ;
        padding: 20px;
        
        .search-clear-wpr{
            @include flexbox();
            font-size: 14px;
            @include justify-content(space-between);
            margin-bottom: 5px;
            .seacrh-by{
                color: #8C949E;
            }
            .clear-all{
                color: #1658D3;
                cursor: pointer;
                font-weight: 500;
            }
        }
        .inf-text{
            border-top:1px solid #E5E5E5;
            color: #8C949E;
            font-size: 14px;
            font-weight: 500;
            padding:15px 0;
            line-height: 21px;
        }
        .searched-cat{
            margin-bottom: 25px;
            span{
                background: #E8E8E8;
                padding: 2px 5px;
                display: inline-block;
                color: #6B6A77;
                cursor: pointer;
                font-size: 11px;
                margin-right: 10px;
                i{
                    margin-left: 1px;
                }
            }
        }
        .form-heading{
            color: $primaryColor;
            font-size: 13px;
            font-weight: 600;
        }

        .gender-list,.age-list{
            margin-bottom: 20px;
            @include flexbox();
            @include justify-content(space-between);
            span{
                background: rgba(23, 41, 64, 0.04);
                border-radius:18px ;
                color: #172940;
                cursor: pointer;
                font-size: 13px;
                font-weight: 400;
                display: inline-block;
                margin-right: 2px;
                margin-bottom: 10px;
                padding: 8px 15px;
                min-width: 80px;
                text-align: center;
                &:hover, &.active{
                    background: #1658D3;
                    color: $white;
                }
            }
        }
        .age-list{
            span{
                min-width: 66px;
            }
        }
    }
    .camp-left {
        padding-left: 0;
    }
    .product-list{
        padding-right: 0;
        @include flexbox();
        @include flex-wrap(wrap);
        @include justify-content(flex-start);
        .product-item{
            float: left;
            position: relative;
            width: 32%;
            margin: 0px;
            background:#F9F9F9;
            @include transition(0.5s);
            //margin: 0 0 30px 36px;
            //min-height: 500px;
            //max-height: 500px;
			// min-height: 460px;
            // max-height: 460px;
            margin: 0px 6px 15px 6px !important;
            &:nth-child(3n+1){
                margin-left: 0
            }
            &:hover{
                -webkit-box-shadow: 5.657px 5.657px 30px 0px rgba(0, 0, 0, 0.08);
                box-shadow: 5.657px 5.657px 30px 0px rgba(0, 0, 0, 0.08);
                -webkit-transform: translateY(-10px);
                -ms-transform: translateY(-10px);
                transform: translateY(-10px);
            }
            // &:before{
            //     content: '';
            //     position: absolute;
            //     left: 0;
            //     bottom: 0;
            //     width: 100%;
            //     background: -webkit-gradient(linear, left top, right top, from(#00264a), color-stop(#3d336b), color-stop(#7b377c), color-stop(#ba367a), to(#ed4266));
            //     background: -o-linear-gradient(left, #00264a, #3d336b, #7b377c, #ba367a, #ed4266);
            //     background: linear-gradient(to right, #00264a, #3d336b, #7b377c, #ba367a, #ed4266);
            //     @include transition(0.5s);
            //     height: 4px;
            //     opacity: 0;
            //     visibility: hidden;
            // }

            &:hover::before {
                opacity: 1;
                visibility: visible;
            }

            .product-image{
                display: flex;
               img{
                width: 100%;
                max-height: 305px;
                // height: 200px;
                object-fit: cover;
               }
            }
            .product-details{
                float: left;
                width: 100%;
                padding: 1em;
                h2{
                    float: left;
                    width: 100%;
                    font-size: 16px;  
                    font-weight: 600;   
                    color: #172940;
                    margin-top: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
				a{
					color: #172940;
					transition: 0.3s;
					-moz-transition: 0.3s;
					-webkit-transition: 0.3s;
				}
				a:hover {
					color: #ff007a;
					text-decoration: none;
				}
                .campaign-details-mask{
                    float: left;
                    width: 100%;
                    p{
                        font-size: 12px;
                        font-weight: normal;
                        color: #464550;
                        opacity: 1;
                        font-style: normal;
                        height: 40px;
                        overflow: hidden;
                        margin-bottom: 0px;
                    }
                    .productpriceoffer{
                        float: left;
                        width: 100%;
                        margin: 1em 0 0.5em 0;
                        .productactprice{
                            display: inline-block;
                            float: none;
                            width: auto;
                            font-size: 14px;
                            font-weight: 500;
                            text-transform: uppercase;
                            color: #1658D3;
                            margin-right: 10px;
                        }
                        .productprevprice{
                            display: inline-block;
                            float: none;
                            width: auto;
                            font-size: 12px;
                            font-weight: normal;
                            color: #707070;
                            margin-right: 10px;
                            text-decoration: line-through;
                        }
                        .productdiscount{
                            display: inline-block;
                            float: none;
                            width: auto;
                            min-width: 55px;
                            height: 20px;
                            background:#40E8A4;
                            font-size: 11px;
                            font-weight: 500;
                            color: #ffffff;
                            padding: 2px 5px;
                            text-align: center;
                            border-radius: 2px;
                            -webkit-border-radius: 2px;
                            -moz-border-radius: 2px;
                        }
                    }
                    .campaign-detail-btn{
                        float: left;
                        width: 100%;
                        padding: 0.5em;
                        background: #F9F9F9;
                        border: 1px solid #FF007A;
                        border-radius: 4px;
                        -webkit-border-radius: 4px;
                        -moz-border-radius: 4px;
                        opacity: 1;
                        text-align: center;
                        color: #FF007A;
                        font-size: 12px;
                        font-weight: 500;
                        margin-top: 1em;
                        transition: all 0.4s ease;
                        &:hover{
                            cursor: pointer;
                            transition: all 0.5s ease;
                            background: #ff5baa;
                            color: #F9F9F9;
                            order: 1px solid #ff5baa;   
                            text-decoration: unset !important;
                        }
                    }
					.outofstock{
						transition: all 0.5s ease;
						background: #ff5b5b;
						color: #F9F9F9;
						border: 1px solid #ff5b5b;   
						text-decoration: unset !important;
					}
                }
            }
        }
    }
    .breadcrumb{
        background:transparent;
        padding:10px 0px;
        .breadcrumb-item{
            .breadcrumb-item::before {
                display: inline-block;
                padding-right: .5rem;
                padding-left: .5rem;
                color: #6c757d;
                content: ">";
            }
            a{
                color: #0062cc;
                &:hover{
                    color: #0062cc;
                    text-decoration: none;
                }
            }
        }
    }
   
}

.pg-wpr{
    @include flexbox();
    @include justify-content(space-between);
    @include align-items(center);
    .custom-pagination{
        @include flexbox();
        float: right;
        list-style: none;
        li{
            border: 1px solid #efefef;
            border-right: 0;
            &:last-child{
                border-right: 1px solid #efefef;
            }
            &:hover, &.active{
                background-color: #e9ecef;
            }
    
            a{  
                display: inline-block;
                color: $primaryColor;
                cursor: pointer;
                padding:8px 14px;
                text-decoration: none;
            }
        }
    }
}

.price-slider{
    margin: 40px 15px 20px 15px;
    .input-range__slider{
        background: #fff;
        box-shadow: 0px 3px 6px #00000029;
        border:1px solid #fff;
        margin-top: -13px;
        height: 20px;
        width: 20px;
        &:active{
            transform:initial;
        }
    }
    .input-range__label--value {
        position: absolute;
        top: -38px;
        font-size: 14px;
        font-weight: 600;
        color: #172940;
        opacity: 0.68;
    }
    .input-range__label-container{
        &:before{
            content:'$';
            font-size: 14px;
            font-weight: 600;
            color: #172940;
            position: absolute;
            left: -10px;
            top: -2px;
        }
    }
    .input-range__track--active{
        background: #1658D3;
    }
    .input-range__label--max,.input-range__label--min{
        display: none;
    }
    
}
.menu li.belicon {
    padding: 0 4px 0 23px;
}
.menu li.belicon img {
    width: 16px;
    cursor: pointer;
}
.menu li.belicon span {
    background: #ff5baa;
    color: #fff;
    text-align: center;
    min-width: 18px;
    // line-height: 19px;
    min-height: 18px;
    font-size: 10px;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 0px;
    margin-left: 7px;
    padding: 2px;
}
div#notifi {
    position: fixed;
    right: 193px;
    z-index: 1;
    top: 70px;
}

.posted-campaignlist{
	color: #858A8F;
    font-style: italic;
    font-size: 11px;
    opacity: 0.7;
}

.show-area{
    float: left;
    width: 100%;
    //min-height: 135px;
    min-height: 95px !important;
}

.received-campaignslist {
	position: absolute;
	top: 14px;
	right: -5px;
	color: #ffffff;
	width: auto;
	padding: 0 0px;
	height: 26px;
	border-bottom: 13px solid #689DFF;
	border-top: 13px solid #689DFF;
	border-left: 6px solid transparent;
	font-size: 12px;
	font-weight: 400;
	line-height: 17px;
}
.received-campaignslist:after{
	position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #1658D3;
    z-index: -1;
    top: 3px;
    right: 0;
}
.received-campaignslist{
    width: 185px;
    .bookmark-text {
        //padding: 0px 3.5px;
        padding: 0px 3.5px 0 0;
        top: -9px;
        position: relative;
        left: 0px;
        width: 100%;
        float: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
} 

.homepage-header-only{
    .menu li.belicon span {
        background: #ff5baa;
        color: #fff;
        text-align: center;
        min-width: 18px;
        // line-height: 19px;
        min-height: 18px;
        font-size: 10px;
        border-radius: 10px;
        display: block;
        position: absolute;
        top: 15px;
        z-index: 1;
        margin-left: 7px;
        padding: 2px;
    }
}

.homepage-header-only.header-sticky{
    .menu li.belicon span {
        background: #ff5baa;
    color: #fff;
    text-align: center;
    min-width: 18px;
    // line-height: 19px;
    min-height: 18px;
    font-size: 10px;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 9px;
    z-index: 1;
    margin-left: 7px;
    padding: 2px;
    }
}
.addedby-product{
	border-radius: 50%;
    width: 37px;
    height: 37px;
}

.similar-product-redirect{ 
	a{
		color: #172940;
		transition: 0.3s;
		-moz-transition: 0.3s;
		-webkit-transition: 0.3s;
	}
	a:hover {
		color: #ff007a;
		text-decoration: none;
	}
}