//2.  Color Variables 
$primaryColor : #172940;
$hoverColor :#ff007a;
$buttonBack : #ff5baa;
$white : #ffffff;
$borderColor : #E3E3E3;
$catHeadingColor : #624a9d;
$blue : #1658D3;
$grey1 : #F9F9F9;
$grey2 : #6b6a77;
$voilet : #3B1D82;
$yellow : #FFCE1A;