/* Share Modal*/ 

.form-modal-heading{
    color: #172940;
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
}

.share-modal{
    .modal-dialog{
        margin-top: 10%;
    }
    .modal-content{
        border-radius: 0;
    }
    h4{
        font-size: 18px;
        display: block;
        margin-bottom: 0;
        width: 100%;
    }
    .cd-social{
        padding: 10px 0;
        li{
            &:nth-child(1){
                a{
                    background: #3a5898;
                } 
            }
            &:nth-child(2){
                a{
                    background: #0073b0;
                } 
            }
            &:nth-child(3){
                a{
                    background: #0073b0;
                } 
            }
            &:nth-child(4){
                a{
                    background: #2baae1;
                } 
            }
            &:nth-child(5){
                a{
                    background: #da3025;
                } 
            }
        }
    }
    input{
        border: 1px solid $borderColor;
        color: $primaryColor;
        font-size: 14px;
        padding: 10px;
        width: 100%;
        &:focus{
            border: 1px solid $hoverColor;
        }
    }
    .close-modal{
        font-size: 18px;
    }
}

.login-modal{
    .email-input{
        &:after{
            position: absolute;
            content: '';
            top: 10px;
            right: 0;
            background: url(../../images/at.png);
            width: 20px;
            height: 20px;
            background-size:cover ;
        }
    }
    .password-input{
        margin-bottom: 22px;
        &:after{
            position: absolute;
            content: '';
            top: 10px;
            right: 0;
            background: url(../../images/lock.png);
            width: 15px;
            height: 20px;
            background-size:cover ;
            }
    }
   
    .forgot-password{
        color: #384D8F;
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        display: block;
        text-align: right;
        &:hover{
            text-decoration: none;
        }
    }
    .login-btn{
        display: block;
        margin: 25px 0;
    }
    .login-with{
        color: #1C2A3A;
        font-size: 12px;
        line-height: 18px;
    }
    .social-btn-wpr{
        @include flexbox();
        @include justify-content(space-between);
    .facebook-btn{
        background: #4267B2;
        color: $white;
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 10px;
        text-align: center;
        text-decoration: none;
        width: 170px;
        span{
            margin-right: 10px;
        }
    }
    .insta-btn{
        background: #DC2878;
        color: $white;
        display: inline-block;
        font-size: 16px;
        font-weight: 400;
        padding: 10px 10px;
        text-align: center;
        text-decoration: none;
        width: 170px;
        span{
            margin-right: 10px;
        }
        }
    }
   
}

.signUp-modal{
    .name-wpr {
        .name-left {
            float: left;
            margin-right: 5%;
            width: 45%;
        }
        .name-right {
            float: left;
            width: 50%;
        }
    }
      
    .password{
        &:after{
            position: absolute;
            content: '';
            top: 6px;
            right: 2px;
            background: url(../../images/lock.png);
            width: 18px;
            height: 25px;
            background-size:cover ;
        }
    }
    .email-address{
        &:after{
            position: absolute;
            content: '';
            top: 10px;
            right: 0;
            background: url(../../images/at.png);
            width: 20px;
            height: 20px;
            background-size:cover ;
            }
    }
    .mobile-wpr{
        .mobile-left{
            float: left;
            margin-right: 5%;
            width: 15%;
        }
        .mobile-right{
            float: left;
            width: 80%;
            &:after{
                color: $voilet;
                font-size: 14px;
                font-weight: 400;
                position: absolute;
                content: '';
                cursor: pointer;
                top: 10px;
                right: 0;
            }
        }
    }
    .code{
        margin-bottom: 10px;
    }
    .not-valid{
        color: #FF4040;
        font-size: 11px;
        font-weight: 400;
    }
    .tc-checkbox{
        input{
            margin-top: 2px;
        }
        margin-bottom: 10px;
    }
    .btn{
        margin-top: 20px;
    }
}

.brand-modal{
    h2{
        font-size: 24px;
    }
    .location{
        span{
            margin-right: 6px;
        }
        color: $primaryColor;
        font-size: 14px;
        font-weight: 500;
        margin-top: -5px;
    }
    label{
        color: $primaryColor;
        font-size: 13px;
        font-weight: 600;
        margin-top: 15px;
    }
    textarea{
        border-radius:0 ;
        border: 1px solid $borderColor ;
        font-size: 13px;
        padding: 10px;
    }
}

.successfull-modal{
    .modal-dialog{
        color: $primaryColor;
        img{
            display: block;
            margin: 0 auto;
            width: 127px;
        }
        h4{
            font-size: 18px;
            font-weight: 600;
            margin: 20px 0;
        }
        p{
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
        }
        a{
            color: $blue;
            font-size: 13px;
            font-weight: 600;
            &:hover{
                text-decoration: none;
            }
        }
    }
}

.report-modal{
    .modal-dialog{
        color: #172940;
        .get-det{
            font-size: 14px;
            opacity: .7;
        }
        .report-label{
            position: relative;
            width: 100%;
        }
        select{
            border: 1px solid #e3e3e3;
            border-radius: initial;
            font-size: 13px;
            font-weight: 400;
            min-height: 44px;
            padding: 10px;
            -webkit-appearance: none;
            -moz-appearance: none;
                 appearance: none;
            &:focus{
                box-shadow: none;
            }
        }
        textarea{
            border: 1px solid #e3e3e3;
            border-radius: initial;
            font-size: 13px;
            font-weight: 400;
            min-height: 135px;
            padding: 10px;
            &:focus{
                box-shadow: none;
            }
        }
    } 
}

.signup-success-content{
	margin-top: 16rem;
}