.app-list-wpr{
    .app-list-header{
        background:#f8f8f8;
        color: #172940;
        margin-top: 30px;
        padding: 20px 30px;
        h2{
            @include font-style(18px, 600, 27px);
        }
        .list-price-wpr{
            margin-top: 10px;
            span{
                color: #858A8F;
                @include font-style(13px, 500, 20px); 
            }
            p{
                @include font-style(14px, 500, 21px);  
                margin-top: 6px;
                margin-bottom: 0;
                letter-spacing: 0.7px;
            }
        }
    }
    .application-received{
        .left{
            padding-left: 0;
        }
        .right{
            padding-right: 0;
        }
        select{
            min-width: auto;
        }
        margin: 40px 0;
        h2{
            @include font-style(24px, 600, 35px);
        }
    }

    .application-list-container{
        margin-bottom: 40px;
        .application-list{
            border-bottom: 1px solid #DEDEDE;   
            margin: 20px 0;
            .applicant-details{
                @include flexbox();
                @include align-items(center);
                .applicant-image{
                    margin-right: 20px;
                    border-radius: 50%;
                    height: 73px;
                    width: 73px;
                    overflow: hidden;
                    img{
                        height: 73px;
                        object-fit: cover;
                    }
                }
                h3{
                    @include font-style(18px, 600, 27px); 
                }
                span{
                    color: #1658D3;
                    display: inline-block;
                    @include font-style(11px, 600, 17px); 
                }
            
            }
            p{
                color: #4E5B6D;
                margin-top: 14px;
                @include font-style(14px, 400, 26px); 
            }
            .btn{
                font-size: 12px;
                border-radius: 0;
            }
        }
    } 
}
.followers-count{
    float: left;
    width: 100%;
    color: #4d5056;
    font-size: 15px;
    img{
        margin-right: 5px;
    }
    span{
        color: #c7c7c7 !important;
        font-size: 14px !important;
        font-weight: normal !important;
    }
}

.applicationuser-wrapper p.posted{
    color: #858A8F;
    font-style: italic;
    font-size: 11px;
    opacity: 0.7;
}
.campaign-info-mask{
    margin-top: 2em;
}
.campaign-type-mask h4{
    color: #858A8F;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.65px;
}
.campaign-info-mask h4{
    color: #858A8F;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.65px;
}
.campaign-info-mask  p{
    color: #172940;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.7px;
    line-height: 21px;
}
.campaign-description-mask{
    margin-top: 2em;
    h4{
        color: #858A8F;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0.65px;  
    }
    p{
        font-size: 14px;
        font-weight: 600;
        color: #172940;
    }
} 
.cd-header-image{
    margin-bottom: 20px;
}
.custom-container {
    max-width: 1230px !important;
}
.cd-social li button{
    border:medium none;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
}
.applicant-wrapper{
    float: left;
    width: 100%;
    position: relative;
    background: #fff;
    border:1px solid #E8ECF3;
    // padding: 2em;
    margin:1.3em 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    // height:270px;
    flex-direction: column;
    transition: transform .1s;
    transition: all 0.4s ease;
    // &:hover {
    //     -ms-transform: scale(1.1); /* IE 9 */
    //     -webkit-transform: scale(1.1); /* Safari 3-8 */
    //     transform: scale(1.1); 
    //     transition: all 0.4s ease;
    //     background: rgba(255,255,255,0.8);
    // }
    // &:hover .make-offer-hover-btn{
    //     opacity: 1;
    //     transition: all 0.4s ease;
    //     cursor: pointer;
    // }
    // &:hover .applicant-shift{
    //     transition: all 0.9s ease;
    //     position: relative;
    //     top: -2em;
    // }
    // &:hover .applicant-img{
    //     height: 65px;
    //     width: 65px;
    // }
    // &:hover .applicabt-name{
    //     color: #ff5baa;
    // }
    // &:hover .applicant-img-wrapper{
    //     height: auto !important;
    // }
    // &:hover .acceptoffer-label{
    //     top: 0px;
    // }
    .applicant-shift{
        transition: all 0.9s ease;
        text-align: center;
        float: left;
        width: 100%;
        padding: 2em 1em 0 1em;
        .applicant-img-wrapper{
            float: left;
            width: 100%;
            height: 140px;
            text-align: center;
            margin: 0 auto;
            position: relative;
        }
    }
    .acceptoffer-label{
        min-width: 130px;
        position: relative;
        top: -15px;
        display: inline-block;
        height: 24px;
        float:none;
        padding: 5px 10px 5px 25px;
        margin: 0 auto;
        background: #58DC72;
        color: #ffffff;
        font-size: 11px;
        font-style: italic;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        img{
            position: absolute;
            left: 7px;
            top: 6px;
        }
    }
    .followers-mask{
        float: left;
        width: 100%;
        padding: 1em;
        border-top: 1px solid #E8ECF3;
    }
    .topdots-infosection{
        width: 100%;
        float: left;
        display: unset;
        justify-content: unset;
        align-items: unset;
        float: left;
        width: 100%;
        position: absolute;
        top: 0;
        padding: 1em;
        .hiredtab-left{
            float: right;
            width: 50%;
        }
        .shiredtab-right {
            float: right;
            width: 50%;
            position: relative;
            top: -5px;
            z-index: 1;
            text-align: right;
            .report-menu {
                background: #fff;
                box-shadow: 0px 3px 10px #00000029;
                display: none;
                list-style: none;
                margin-bottom: 0;
                position: absolute;
                z-index: 1;
                text-align: center;
                right: 21px;
                width: 185px;
                top: 7px;
                border-radius: 5px;
                padding: 1em 1.5em;
                li{
                    a {
                        font-size: 14px;
                        font-weight: 600;
                        color: #172940;
                        padding: 10px 0px;
                        border: medium none;
                    }
                } 
                &:after{
                    display: none;
                }
            }
        }
    }
    &:hover{
        transition: all 0.4s ease;
        box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.07);
    }
}

.toogletab-section{
    .tab-top-wpr {
        border-bottom: 1px solid #dee2e6;
        margin-bottom: 1em;
    }
    #application-user-list-tab{
        h3{
            float: left;
            width: 100%;
            color: #172940;
            font-size: 14px;
            line-height: 18px;
            font-weight: 600;
            margin-top: 15px;
        }
        .showlistgrid{
            float: right;
            margin-top: 10px;
            width: 100%;
            .btn{
                float: right;
                background: #ffffff;
                border:1px solid #1658D3;
                border-radius: 0px;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                margin: 0px 4px;
                i{
                    color: #1658D3;
                }
                &:focus{
                    box-shadow: unset;
                }
            }
            .btn.active{
                background: #1658D3;
                border:1px solid #1658D3;
                border-radius: 0px;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                i{
                    color: #ffffff;
                }
                &:focus{
                    box-shadow: unset;
                }
            }
            .application-filter-form{
                float: right;
                margin-left: 10px;
                .form-control{
                    font-size: 13px;
                    font-weight: 400;
                    color: #172940;
                }
                select{
                    border-radius: 0px;
                    -webkit-border-radius: 0px;
                    -moz-border-radius: 0px;
                }
                #shortbyCategory{
                    height: 38px;
                    border:1px solid #E3E3E3;
                    border-radius: 0px;
                    option{
                        font-size: 13px;
                        font-weight: 400;
                        color: #172940;
                    }
                    &:hover{
                        outline: unset;
                        box-shadow: unset;
                    }
                    &:focus{
                        outline: unset;
                        box-shadow: unset;
                    }
                } 
            }
        }
        .applicant-list-wrapper{
            float: left;
            width: 100%;
            padding: 1em;
            margin-bottom: 1em;
			margin-top: 1rem;
            background: #ffffff;
            border:1px solid #E8ECF3;
            .applicantlist-infosection{
                float: left;
                width:100%;
                .followers-wrapper{
                    text-align: left;
                    display: inherit;
                    margin-bottom: 8px;
                }
                .acceptoffer-label{
                    width: 170px;
                    position: relative;
                    margin-top: 10px;
                    display: inline-block;
                    height: 24px;
                    float: none;
                    padding: 5px 5px 5px 25px;
                    margin: 0 auto;
                    background: #58DC72;
                    color: #ffffff;
                    font-size: 11px;
                    font-style: italic;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    img{
                        position: absolute;
                        left: 7px;
                        top: 6px;
                    }
                }
            }
            .applicant-list-text{
                font-size: 12px;
                font-weight: 600;
                line-height: 24px;
                color: #656E78;
                word-break: break-all;
            }
            .applicantlist-inforight{
                float: left;
                width: 100%;
                padding: 0px 1em 0px 1.5em;
                border-left: 1px solid #E8ECF3;
                .list-message-btn{
                    float: left;
                    min-width: 92px;
                    height: 35px;
                    margin:10px 10px 10px 0px;
                    padding:0px 15px;
                    background: #F8F8F8;
                    border:1px solid #DCE0E9;
                    text-align: center;
                    font-size: 13px;
                    font-weight: 600;
                    color: #172940;
                    transition: all 0.4s ease;
                    &:hover{
                        background: #1658D3;
                        color: #ffffff;
                        transition: all 0.4s ease;
                    }
                }
            }
        }   
    }
}

.applicabt-name{
    float: left;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #172940;
    line-height: 22px;
    margin: 10px 0px 0px 0px;
    .application-name{
        float: left;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }
}
.applicant-username{
    float: left;
    width: 100%;
    font-size: 11px;
    font-weight: 600;
    color: #1658D3;
    line-height: 16px;
    margin: 5px 0px;
}
.followers-wrapper{
    float: left;
    width: 100%;
    text-align: center;
    display: inline-block;
}
.followers-wrapper img{
    float: none;
    display: inline-block;
    margin-right: 10px;
 }
 .follower-text{
    float: none;
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #172940;
    top: 2px;
    position: relative;
}
.applicant-wrapper{
    margin: 1em 0em;
}
.make-offer-hover-btn{
    min-width: 120px;
    // opacity: 0;
    height: 40px;
    // position: absolute;
    border: 1px solid #DCE0E9;
    background: #F8F8F8;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    margin: 1em 0 0 0;
    transition: all 0.4s ease;
    color: #172940;
    margin: 0 auto;
    margin: 0.6em 0 1em;
    padding: 0px 15px;
    bottom: 24px;
    &:hover{
        background: #1658D3;
        color: #ffffff;
        transition: all 0.4s ease;
        border: 1px solid #1658D3;
    }
    
}
.topspace-remove{
    .blank-wpr{
        min-height: auto;
    }
}
.application-name:hover{
	color:#ff5baa;
}

.applicantuserpayment{
    .paymentaccept-infowrapper{
        float: left;
        width:100%;
        background: #FFF6E2;
        padding:1.5em;
        margin: 0px 15px 10px 15px;
        h2{
            float: left;
            width:81%;
            position: relative;
            top: 3px;
            font-size: 12px !important;
            font-weight: 500  !important;
            color: #172940  !important;
            padding:0px !important;
            margin:0px !important;
            span{
                font-weight: 700;
            }
        }
        .payment-action{
            float: right;
            .paymentaccept{
                min-width: 90px;
                height: 28px;
                padding:5px 10px;
                margin: 0px 5px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;   
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                background: #58DC72;
                &:hover{
                    text-decoration: none;
                }
            }
            .paymentreject{
                min-width: 90px;
                height: 28px;
                padding:5px 10px;
                margin: 0px 5px;
                border-radius: 15px;
                -webkit-border-radius: 15px;
                -moz-border-radius: 15px;   
                font-size: 12px;
                font-weight: 500;
                color: #ffffff;
                background: #FF4444;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

.reportbtn {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    background-color: rgba(0, 0, 0, 0.4);
    color: #ffffff;
    cursor: pointer;
    font-size: 14px;
    padding: 3px 7px;
    min-width: 75px;
    transition: 0.28s;
    margin: 20px 0;
    float: right;
    &:hover{
        background: #ff007a;
        color: #ffffff;
        text-decoration: none;
    }
}
#profile{
    .followers-wrapper{
        border-right: 1px solid #c7c7c7;
    }
}