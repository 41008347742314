
 .hedfx_h1 {
	margin-bottom: 40px;
}

.conp{
	text-align:left; 
	margin:0 0 20px 0; 
	color:#172940; 
	line-height:24px; 
	font-size:14px;
	
}
.contact-mail{
	font-weight: 500;
	line-height:24px; 
	font-size:14px;
	span{
		margin-right: 8px;
	}
	a{
		color: #FF007A;
	}
}

@media (max-width:767px){ 
#hedfx_h1{font-size:30px;}
}
