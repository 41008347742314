
  .carousel-wrapper{
    position: relative;
    .carousel-item{
     
      .carousel-image-one{
        width: 100%;
        background: url('/images/carousel-image/carousel-four.jpg') no-repeat;
        background-size: cover;
        background-position: center center;
        height: 100vh;
        object-fit: cover;
        
      }
      .carousel-image-two{
        width: 100%;
        background: url('/images/carousel-image/carousel-three.jpg') no-repeat;
        background-size: cover;
        background-position: center center;
        height: 100vh;
        object-fit: cover;
      }
      .carousel-image-three{
        width: 100%;
        background: url('/images/carousel-image/carousel-newone.jpg') no-repeat;
        background-size: cover;
        background-position: center center;
        height: 100vh;
        object-fit: cover;
      }
      .carousel-caption{
        background: rgba(23,41,64,0.3);
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .carousel-caption-inner{
          width: 84%;
          float: none;
          margin: 0 auto;
          h2{
           text-align: center;
            font-size: 36px;
            font-weight: 500;
            color: #ffffff;
            margin: 0.7em 0;
          }
          p{
            width: 850px;
            margin: 0 auto;
            text-align: center;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
          }
        }
        
      }
    }
    .carousel-control-prev-icon {
      background: url('/images/carousel-image/carousel-left-arrow.svg') no-repeat;
      height: 40px;
      width: 25px;
      opacity: 1;
    }
    .carousel-control-next-icon {
      background: url('/images/carousel-image/carousel-right-arrow.svg') no-repeat;
      height: 40px;
      width: 25px;
      opacity: 1;
    }
    .carousel-search-wrapper{
      // position: absolute;
      // width: 100%; 
      // bottom: 40px;
      position: absolute;
      width: 100%;
      bottom: 33%;
      .search-wrapper{
        float: none;
        width: 80%;
        // position: relative;
        // top: -280px;
        margin: 0 auto;
        padding: 0em;
        display: block;
        //background: #FF5BAA;
        input[type='text']{
          height: 48px;
          border:medium none;
          padding: 0px 15px 0px 42px;
          border-radius: 0px;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
        }
        select{
          height: 48px;
          border:medium none;
          border-radius: 0px;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
        }
        .carousel-search-btn{
          width: 100%;
          height: 48px;
          margin-left: -10px;
          background: #1658D3;
          border:medium none;
          font-size: 15px;
          font-weight: 500;
          color: #ffff;
          border-radius: 0px;
          -webkit-border-radius: 0px;
          -moz-border-radius: 0px;
        }
        .searchinput-wrapper{
          position: relative;
          float: left;
          width: 100%;
          img{
            position: absolute;
            top: 15px;
            left: 15px;
          }
        }
      }
    }
  }

  .homepage-header-only{
    background: transparent;
    position: fixed;
    float: left;
    width: 100%;
    z-index: 1;
    .logo{
      display: block;
    }
    .logo-scroll{
      display: none;
    }
    .menu{
      margin-top: 15px;
      li{
        a{
          color: #ffffff;
          transition: all 0.4s ease;
          padding: 10px;
          svg{
            fill: #ffffff;
          }
        }
        .navigationcreate-camp-btn{
          min-width: 170px;
          height: 39px;
          padding: 0.7em 1em;
          text-align: center;
          font-size: 16px;
          font-weight: 500;
          color: #ffffff;
          border-radius: 4px;
          -webkit-border-radius: 4px;
          -moz-border-radius: 4px;
          transition: all 0.4s ease;
          &:hover{
            background: #FF5BAA;
            color: #ffffff;
            transition: all 0.4s ease;
          }
        }
        &:hover a{
          color: #ff007a;
          transition: all 0.4s ease;
        }
      }
    }
    .user-menu{
      .dropdown-toggle{
        color: #ffffff;
        svg{
          fill: #ffffff;
        }
      }
      .dropdown-menu{
        li{
          a{
            color: #000;
            transition: all 0.3s ease;
            svg{
              fill: #8d8c8c;
            }
            span{
              &:before{
                color: #8d8c8c;
              }
            }
            &:hover{
              color: #ff007a;
              transition: all 0.3s ease;
            }
            &:hover svg{
              fill: #ff007a;
            }
            &:hover span{
              &:before{
                color: #ff007a;
                transition: all 0.3s ease;
              }
            }
          }
        }
      }
    }
  }

  .homepage-header-only.header-sticky{
    margin-top: 0px;
    background: #ffffff;
    z-index: 9;
    .logo{
      display: none;
    }
    .logo-scroll{
      display: block;
    }
    .menu{
      margin-top: 7px;
      li{
        a{
          color: #000;
          transition: all 0.4s ease;
          svg{
            fill: #000;
          }
        }
        .navigationcreate-camp-btn{
          &:hover{
            background: #FF5BAA;
            color: #ffffff;
            transition: all 0.4s ease;
          }
        }
        &:hover a{
          color: #ff007a;
          transition: all 0.4s ease;
        }
      }
    }
    .user-menu{
      .dropdown-toggle{
        color: #000;
        svg{
          fill: #000;
        }
      }
      .dropdown-menu{
        li{
          a{
            color: #000;
            transition: all 0.3s ease;
            svg{
              fill: #8d8c8c;
            }
            span{
              &:before{
                color: #8d8c8c;
              }
            }
            &:hover{
              color: #ff007a;
              transition: all 0.3s ease;
            }
            &:hover svg{
              fill: #ff007a;
            }
            &:hover span{
              &:before{
                color: #ff007a;
                fill: #ff007a;
                transition: all 0.3s ease;
              }
            }
          }
        }
      }
    }
  }

  .feature-campaign{
    .see-all-featured{
      display: flex;
      justify-content: center;
      position: relative;
      top: 30px;
      a.viewall-btn{
        background: #fff;
        border-radius: 0;
        padding: 7px;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #E0E0E5;
        color: #333;
        min-height: 40px;
        min-width: 90px;
        text-align: center;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        transition: all 0.3s ease;
        &:hover{
          transition: all 0.3s ease;
          background: #1658D3;
          color: #fff;
          border: 1px solid #1658D3;
          text-decoration: none;
        }
      }
    }
  }
  .homesearch-required{
	font-weight: 600;
    margin: 15.2em;
  }