.help-left{
    background: #FBFBFB;
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    .img-one{
        position: absolute;
        top: 60px ;
        left: 40px;
    }
    .img-two{
        position: absolute;
        top:0;
        right: 40px;
    }

    .img-three{
        position: absolute;
        bottom:0;
        left: 40px;
    }

    .img-four{
        position: absolute;
        right:-40px;
        top: 45%;
    }

    .img-five{
        position: absolute;
        left: -59px;
        top: 30%;
        transform: rotate(98deg);
    }

    .img-six{
        position: absolute;
        left: 45%;
        top: 30%;
    }

    .img-seven{
        position: absolute;
        right: 40px;
        bottom:-34px;
    }

    .img-eight{
        position: absolute;
        right: -50px;
        bottom:15%;
    }
}

.help-right {
    min-width: 550px;
    margin: 0 auto;
    .page-heading{
        margin-bottom: 10px;
    }
    .help-para{
        @include font-style(14px , 400, 21px);
        opacity: 0.5;
        margin-bottom: 40px;
    }
    .form-control{
        color: #909294;
        font-weight: 400;
        border: 1px solid #E3E3E3;
        &:focus{
            border: 1px solid #E3E3E3; 
        }
    }
}

.help-container{
	margin-top:8em!important;
	margin-bottom:60px!important;
}

@media screen and (max-width: 1440px) {
    .img-two, .img-three{
        width: 30%;
    }
}

@media screen and (max-width:1024px){
    .img-two, .img-three{
        width: 40%;
    }
}

