.inf-profile-wpr{
    background: #fafafa; 
    .container{
        max-width: 935px;
    }
    .inf-profile-header{
        background: linear-gradient(0deg, #4C26A7 0%, #FF5BAAA1 100%) 0% 0% no-repeat padding-box, url(../../images/profile-back.png) no-repeat;
        background-position: bottom;
        color: $white;
        // padding:36px 0;
        padding:115px 0 0 0;
        margin-bottom: 30px;
        min-height: 362px;
        overflow: hidden;
        background-size: cover;
    }
    .profile-img-wpr{
        .profile-img{
            position: relative;
            border:2px solid  $white;
            border-radius: 50%;
            height: 190px;
            overflow: hidden;
            width: 190px;
            img{
                height: 190px;
                width: 100%;
                object-fit: cover;
            }

        .react-loading-skeleton{
            position: relative;
            top: -5px;
        }
           
        }
        .heart-wpr{
            background: $white;
            border-radius: 50%;
            position: absolute;
            right: 26px;
            top: 9px;
            height: 42px;
            text-align: center;
            width: 42px;
            i{
                color: #FF007A;
                font-size: 17px;
                line-height: 2.55;
            }
        }
    }
   
    .right-top{
        @include flexbox();
        @include justify-content(space-between);
        padding: 30px 30px 10px  0;
        .report-menu-wpr{
            position: relative;
            .settings{
                background: rgba(0, 0, 0, 0.14);
                border-radius: 50%;
                cursor: pointer;
                display: inline-block;
                text-align: center;
                height: 42px;
                width:42px;
                i{
                    line-height: 2.16;
                    font-size: 19px;
                }
            }
            .report-menu{
                right: -7px;
                top: 43px;
                &:after{
                    content: "";
                    position: absolute;
                    border-left: 8px solid transparent;
                    border-right: 8px solid transparent;
                    border-bottom: 10px solid #fff;
                    top: -10px;
                    right: 19px;
                }
            }
        }
        .profile-name{
            h2{
                font-size: 18px;
                font-weight: 600;
                line-height: 27px;
            }
            h3, h4{
                font-size: 12px;
                font-weight: 500;
                line-height:18px;
            }
            h4{
                span{
                    margin-right: 5px;
                }
            }
            .statusnotification-wrapper{
               float: left;
               width: 100%;
              
               span.statuelastseen-text{
                margin-left: 5px;
                font-style: italic;
                font-size: 12px;
                line-height: 18px;
                color: rgba(255,255,255,0.7);
                   &::before {
                    float: left;
                    content: "";
                    width: 6px;
                    height: 6px;
                    background:rgba(255,255,255,0.7);
                    border-radius: 50%;
                    position: relative;
                    top:6px;
                   }
               }
               span.statusonline-text{
                margin-left: 5px;
                font-style: italic;
                font-size: 12px;
                line-height: 18px;
                color: #92E5A8;
                   &::before {
                    float: left;
                    content: "";
                    width: 6px;
                    height: 6px;
                    background:#92E5A8;
                    border-radius: 50%;
                    position: relative;
                    top:6px;
                   }
               }
            }
        }
    }
    .right-bottom{
        @include flexbox();
        padding: 0 30px 0 0;
        .right-bottom-left{
            @include flexbox();
            .followers{
                margin-right: 36px;
                .f-num{
                    display: block;
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 48px;
                }
                .f-text{
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.6px;
                    opacity: 0.6;
                }
            }

            .post{
                position: relative;
                &:after{
                    background:  #FFFFFF ;
                    content: '';
                    height: 54px;
                    width: 1px;
                    position: absolute;
                    top: 6px;
                    right: 0;
                    opacity: 0.5;
                }
                padding-right: 30px;

                .f-num{
                    display: block;
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 48px;
                }
                .f-text{
                    display: block;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    letter-spacing: 0.6px;
                    opacity: 0.6;
                }
            }
            .right-social{
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);
                padding-left: 30px;
                a{
                    border-radius: 21px;
                    background: #FFFFFF4F;
                    color: $white;
                    display: inline-block;
                    font-size: 14px;
                    padding: 10px 25px;
                    margin-right: 10px;
                    &:hover{
                        text-decoration: none;
                    }
                    i{
                        font-size: 18px;
                        margin-right: 5px;
                    }
                }
            }
        }
        
    }
    .bg_al {
        padding-bottom: 100px;
        .img_ful img {
            width: 100%;
            span{
                img{
                    width:15px; 
                    position:absolute; 
                    right: 25px; 
                    top:10px; 
                    filter: brightness(0) invert(1);
                }
            }
        }
        .post_i{
            display: inline-block; 
            margin:10px 0 20px;
            ul{
                list-style: none;
                li{
                    float: left;  margin-right: 20px;
                    span{
                        font-size:20px; 
                        font-weight:600; 
                        color:#172940; 
                        line-height: 24px;
                    }
                }
            }
        }
        .inf_content {
            cursor: cell;
            height: 280px;
            overflow: hidden;
            position: relative;
            width:280px;
            .inf-image{
                height: 280px;
                object-fit: cover;
            }
            .content-overlay{
                background: rgba(0, 0, 0, 0.43);
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                z-index: 1;
                top: 0;
                bottom: 0;
                right: 0;
                opacity: 0;
                -webkit-transition: all 0.4s ease-in-out 0s;
                -moz-transition: all 0.4s ease-in-out 0s;
                transition: all 0.4s ease-in-out 0s;
                }
                .modal#profileimg{
                    
                    .modal-dialog{
                        right:9%;
                        .modal-content{
                            width: 800px;
                            height: 400px;
                            .modal-body{
                                position: relative;
                                padding: 0px;
                                img.inf-image{
                                    height: 400px;
                                    object-fit: contain;
                                    padding: 1em;
                                    width: 100%;
                                }
                                .modal-right-info{
                                    padding: 2em 1em 1em 1em;
                                    .modal-right-text{
                                        height: 335px;
                                        overflow: auto;
                                        // overflow: scroll;
                                        overflow-x: hidden;
                                        .insta-right-text{
                                            font-size: 13px;
                                            line-height: 23px;
                                            padding: 1em;
                                        }
                                    }
                                    .footer-insta-bottom{
                                        width: 89%;
                                        position: absolute;
                                        left: 0;
                                        padding: 0 1em;
                                        bottom: 0em;
                                        height: 40px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        .insta-text{
                                            float: left;
                                            // width: 100%;
                                            position: relative;
                                            color: #333;
                                            font-size: 12px;
                                            span {
                                                position: absolute;
                                                left: 22px;
                                            }
                                            .fa{
                                                color: #ccc;
                                                font-size: 12px;
                                                margin-right: 5px;
                                            }
                                        }
                                        .modal-insta-img{
                                            img{
                                                width: 60px;
                                                float: right;
                                            }
                                        }
                                    }
                                }
                            }
                        }    
                    }   
                }
        }
        
    }
    .inf_content:hover .content-overlay{
        opacity: 1;
      }
      
      .content-image{
        width: 100%;
      }
      
      .content-details {
        position: absolute;
        text-align: center;
        padding-left: 1em;
        padding-right: 1em;
        width: 100%;
        top: 50%;
        left: 50%;
        z-index: 2;
        opacity: 0;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        -webkit-transition: all 0.3s ease-in-out 0s;
        -moz-transition: all 0.3s ease-in-out 0s;
        transition: all 0.3s ease-in-out 0s;
      }
      
      .inf_content:hover .content-details{
        top: 50%;
        left: 50%;
        opacity: 1;
      }
      .inf_content img{width:100%;}
      .inf_content span img {width:15px; position:absolute; right:11px; top:10px; filter: brightness(0) invert(1);
      }
      .content-details{
          p{
              text-align:center;
              img{
                  width:15px; margin-right:3px; filter: brightness(0) invert(1);
              }
              a{
                  font-size: 15px; color: #fff; text-decoration: none;
                  &:first-child{
                      margin-right:20px; 
                  }
              }
          }
      }
      
    .fadeIn-bottom{top: 80%;}
    .bio{
        margin-top: 21px;
        h4{
            font-size: 14px;
            font-weight: 400;
            font-style: italic;
        }
        p{
            font-size: 13px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0.26px;
            a{
                color: $white;
                font-weight: 500;
                &:hover{
                    text-decoration: none;
                }
            }
        }
        .morecontent{
            span{
                display: none;
            }
        }
    }
    .nocontend-minheight{
        min-height: 10px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
   
}

.account-not-linked{
    img{
        margin: 0 auto 15px auto;
        width:110px;
    }
    h3{
        opacity: 0.7;
        margin-bottom: 10px;
    }
    p{
        color: #c0c4cc;
        @include font-style(14px, 400, 1.2);
    }
}

// .tabbable-panel {

// }
a.active.show {
    border-bottom: #333 solid 4px !important;
    position: relative;
}

.tabbable-line > .nav-tabs {
  border: none;
  margin: 0px;
}
.tabbable-line > .nav-tabs > li {
  margin-right: 2px;
    border-bottom: 4px solid #fff;
}
.tabbable-line > .nav-tabs > li > a {
      border-bottom: #fff solid 4px;
    margin-right: 0;
    color: #737373;
}
.tabbable-line > .nav-tabs > li > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open, .tabbable-line > .nav-tabs > li:hover {
  border-bottom: 4px solid #ff007a;
}
.tabbable-line > .nav-tabs > li.open > a, .tabbable-line > .nav-tabs > li:hover > a {
  border: 0;
  background: none !important;
  color: #333333;
}
.tabbable-line > .nav-tabs > li.open > a > i, .tabbable-line > .nav-tabs > li:hover > a > i {
  color: #a6a6a6;
}
.tabbable-line > .nav-tabs > li.open .dropdown-menu, .tabbable-line > .nav-tabs > li:hover .dropdown-menu {
  margin-top: 0px;
}
.tabbable-line > .nav-tabs > li.active {
  border-bottom: 4px solid #32465B;
  position: relative;
}
.tabbable-line > .nav-tabs > li.active > a {
}
.tabbable-line > .nav-tabs > li.active > a > i {
  color: #404040;
}
.tabbable-line > .tab-content {
  margin-top: -3px;
  background-color: #fff;
  border: 0;
  border-top: 1px solid #eee;
  padding: 15px 0;
}
.portlet .tabbable-line > .tab-content {
  padding-bottom: 0;
}

