.edit-profile-main{
    .custom-popup-content, .popup-content{
        width: 490px !important;
        .modal-dialog{
            margin: 0;
        }
    }
    .max-upload{
        ul{
            margin-top: 15px;
            list-style: none;
            li{
                @include font-style(14px , 500, 20px);
                margin-bottom: 5px;
                opacity: 0.7;
            }
        }
    }
    
    .update-successfully{
        img{
            max-width: 120px;
        }
        p{
            @include font-style(14px , 500, 20px);
            opacity: 0.7;
        }
    }

    .tab-top-wpr{
        border-bottom: 1px solid #dee2e6;
        @include flexbox();
        @include justify-content(space-between);
        @include align-items(center);
        .change-password{
            cursor: pointer;
            i{
                margin-right: 6px;
            }
            color: #1658D3;
            @include font-style(16px, 600, 25px);
        }
    }
   
    .account-tab-content{
        padding: 40px 0;
        .tab-pane{
            padding: 0;
        }
        .form-divider{
            p{
                border-top: 1px solid #EBEBEB;
            }
        }
        .form-main-heading{
            color: $primaryColor;
            @include font-style(14px, 600, 21px);
            opacity: .32;
            padding-left: 0;
            padding-left: 0;
            text-transform: uppercase;
            margin: 0 15px 16px 15px;
        }
        .mobile-number{
            .btn-group{
                width:100%;
            }
            .not-public{
                position: relative;
                top: -10px;
            }
            label{
                color: $primaryColor;
                @include font-style(14px, 600, 21px);
                display: block;
            }
            .dropdown-menu{
                border-radius: 0;
                padding: 0;
                margin-top: 0px;
                border-top: 0;
                min-width: 71px;
                max-height: 230px;
                overflow: hidden;
                overflow-y: scroll;
                @include font-style(14px, 600, 21px);
                li{
                    cursor: pointer;
                    text-align: left;
                    padding: 6px 10px;
                }
            }
            .m-select{
                min-width: 90px;
            }
            .m-left{
                position: relative;
                .dropdown-toggle{
                    border-right: 0;
                    cursor: pointer;
                    min-width: 70px;
                    &:after{
                        display: none;
                     }
                }
                
                .down-arrow {
                    position: absolute;
                    right: 9px;
                    top: 15px;
                    font-size: 13px;
                }
                .form-control{
                    min-height: 43px;
                }
            }
            .m-right{
                position: relative;
                width: 100%; 
                .verified{
                    @include font-style(12px, 400, 18px);
                    color: #1DBB22;
                    position: absolute;
                    right: 12px;
                    bottom: 12px;
                    text-transform: uppercase;
                }
            }
            
        }
        .insterest-label, .insta-label{
            color: $primaryColor;
            display: block;
            margin-bottom: 10px;
            @include font-style(14px, 600, 21px);
        }
        .interest-wpr{
            span{
                background: transparent;
                border: 1px solid #E3E3E3;
                color: $primaryColor ;
                display: inline-block;
                margin-right: 10px;
                margin-bottom: 10px;
                cursor: pointer;
                padding:  10px 20px;
                @include font-style(14px, 500, 21px);
                &.active{
                    background:#1658D3 ;
                    border: 1px solid #1658D3;
                    color: $white;
                }
                input{
                    opacity: 0;
                }
            }
        }

        .insta-wpr{
            span{
                background: transparent;
                border: 1px solid #E3E3E3;
                display: inline-block;
                margin-right: 10px;
                color: $primaryColor;
                cursor: pointer;
                padding:  10px 20px;
                @include font-style(14px, 500, 21px);
                img{
                    margin-right: 5px;
                    width: 18px;
                }
                &.add-btn{
                    background: transparent;
                }
                &.remove-insta-btn{
                    border: 1px solid #E3E3E3;
                    background: #F1F1F1;
                    
                }
                
            }
        }

        .img-container{
            position: relative;
            margin-right: 100px;
            .img-wpr{
                border-radius: 50%;
                position: relative;
                height: 152px;
                overflow: hidden;
                width: 152px;
                img{
                    height: 152px;
                    object-fit: cover;
                    width: 100%;
                }
                .photo-loader{
                    position: absolute;
                    left: 37%;
                    top: 37%;
                }
            }
            .plus-icon{
                background: $hoverColor;
                border: 3px solid #fff;
                border-radius: 50%;
                color: $white;
                cursor: pointer;
                position: absolute;
                text-align:center;
                height: 36px;
                width: 36px;
                right: 0;
                top: 112px;
                input{
                    position: absolute;
                    font-size: 16px;
                    opacity: 0;
                    left: 0;
                    top: 0;
                    width:40px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                input::-webkit-file-upload-button { cursor:pointer; }
                i{
                    line-height: 2.6;
                }
            }
        }
       
    }
    .customtelno{
        position: relative;
        .verfiednoornot{
            position: absolute;
            top: 12px;
            right: 8px;
            color: #58DC72;
            text-transform: uppercase;
            font-size: 14px;
        }
    }
    .formtopborder{
        float: left;
        width: 100%;
        border-top: 1px solid #DEDEDE;
        margin-top: 8px;
    }
    .formaddresswrapper{
        width: 100%;
        textarea{
            height: 45px;
            resize: none;
        }
    }
}

.blocked-user-list{
    .blocked-user{
        background: transparent;
        border: 1px solid #DCE0E9;
        padding:12px 0 32px 0;
        .ellipsis-menu-wpr{
            color: #767F95;
            cursor: pointer;
            font-size: 16px;
            position: relative;
            right: 12px;
            .unblock{
                @include font-style(12px , 600, 16px);
                background: #DBDBDB;
                border-radius: 2px;
                color: #1658D3;
                cursor: pointer;
                right: 14px;
                padding: 6px 10px;
                position: absolute;
                top: -4px;
                &::after{
                    border-top: 14px solid transparent;
                    border-left: 15px solid #dbdbdb;
                    border-bottom: 13px solid transparent;
                    content: "";
                    position: absolute;
                    width: 0;
                    height: 0;
                    right: -6px;
                    top: 1px;
                    z-index: -1;
                }
            }
            .ellipsis-menu.fa-ellipsis-v{
                position: absolute;
                right: 0    ;
            }
           
        }
        .editp{
            height: 72px;
            width: 72px;
            img{
                height: 72px;
                object-fit: cover;
            }
        }
        h2{
            margin-top: 15px;
            a{
                color: $primaryColor;
                &:hover{
                    text-decoration: none;
                }
            }
        }
    }
}

.blocked-search{
    .custom-form-group {
        position: relative;
        &::before{
            color: #6E7884;
            content: "\f002";
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            top: 13px;
            left: 12px;
        }
    }
    .form-control{
        color: #6E7884;
        padding-left: 30px;
    }
}


.successfully-unblock{
    background: rgba(78,197,74,0.1);
    border-radius: 4px;
    color: #4EC54A;
    padding:11px 10px;
    margin: 0 auto;
    max-width: 280px;
    text-align: center;
}

.setting-bankinfo{

   h2{
       font-size: 14px;
       font-weight: 600;
       color: #b4bac2;
   }
   p{
       font-size: 14px;
       font-weight: 500;
       color: #172940;
   }
   .add-bank-btn{
    min-width: 115px;
    height: 44px;
    border:medium none;
    background: #FF007A;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
   }
   .notification-text{
       font-size: 14px;
       font-weight: 600;
       color: #b4bac2;
       margin-top: 2em;
   }
   .form-check{
       margin-top: 1em;
       label{
           font-size: 14px;
           font-weight: 500;
           color: #172940;
       }
   }
   .updateaccount-btn{
    min-width: 180px;
    height: 44px;
    border:medium none;
    background: #1658d3;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin: 50px 0px 20px 0px;
   }
   .formtitle{
       font-size: 13px;
       font-weight: 500;
       color: #858A8F;
       margin: 0.5em 0;
   }
   .formdescription{
        font-size: 13px;
        font-weight: 500;
        color: #172940; 
        margin: 0.5em 0;
   }
   .editaccount-btn{
       float: left;
       min-width: 95px;
       height: 44px;
       border:medium none;
       background: #FF007A;
       text-align: center;
       font-size: 16px;
       font-weight: 600;
       color: #fff;
       margin: 1em 15px 0 0;
       img{
           margin-right: 10px;
       }
   }
   .deleteaccount-btn{
    float: left;
    min-width: 115px;
    height: 44px;
    border:medium none;
    background: #FF007A;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin: 1em 15px 0 0;
    img{
        margin-right: 10px;
    }
   }

   
}

#addbankaccountmodal{
    .modal-body{
        .modal-title{
            font-size: 22px;
            font-weight: 600;
            color: #222222;
            margin-bottom: 0.5em;
        }
        padding: 1em 2em !important;
        .custom-views{
            position: relative;
            display: block;
            padding-left: 0px !important;
            margin: 0 0 1em 0;
        }
        label{
            font-size: 14px;
            font-weight: 600;
            color: #172940;
            .subtraction-icon{
                float: right;
                position: absolute;
                right: 25px;
            }
        }
        .bankinfowrapper{
            margin-bottom: 0.5em;
            .nav-tabs{
                border: medium none;
                .nav-link{
                    font-size: 14px;
                    font-weight: 500;
                    padding: 10px 20px 10px 0px;
                    color: rgba(23,41,64,0.30);
                    border: medium none;
                }
                .nav-link.active{
                    font-size: 14px;
                    font-weight: 500;
                    padding: 10px 20px 10px 0px;
                    color: #1658D3;
                    border: medium none;
                }
                .nav-link.active.show{
                    font-size: 14px;
                    font-weight: 500;
                    color: #1658D3;
                    border: medium none !important;
                }
            }
        }
    }
}
.addbankmodal-btn{
          
    width: 100%;
    height: 44px;
    background: #1658D3;
    border:medium none;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    margin-top: 1em;
}