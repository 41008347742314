.my-campaign-search{
     background: #F6F6F6;
     margin-top: 10px;
     margin-bottom: 50px;
     padding:17px 20px 15px 20px;  
    .form-control{
        color: #6E7884;
        font-weight: 400;
        border-color: $white;
        &:focus{
            border-color: $white;
        }
     }
    label, .custom-group.form-group label{
        color: #A4A8AE;
        font-weight: 500;
    } 
    button{
        margin-top: 26px;
    }
    .title-search{
        &::before{
            content: "\f002";
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            bottom: 26px;
            left: 30px;
        }
    }
	.required-title-search{
        &::before{
            content: "\f002";
            position: absolute;
            font-family: "Font Awesome 5 Free";
            font-weight: 900;
            bottom: 50px;
            left: 30px;
        }
    }
    .camp-search{
        padding-left: 33px;
    }
	.required-camp-search{
        margin-bottom: 6px;
    }
}

.my-campaigns{
    padding-bottom: 50px;
    .received{
        position: absolute;
        margin-top: 15px;
        // top: 14px;
        right: -5px;
        color: $white;
        width:auto; 
        //padding : 0 0px;
        padding: 0 5px;
        height:26px; 
        border-bottom:13px solid #689DFF;
        border-top:13px solid #689DFF;
        border-left:6px solid transparent;
        @include font-style(12px, 400, 17px);
        &:after{
            position: absolute;
            content: "";
            width: 0;
            height: 0;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid #1658D3;
            z-index: -1;
            top: 3px;
            right: 0;
        }
        .bookmark-text{
            // position: relative;
            // top: -9px;
            //background: #f00;
            //padding: 0px 3.5px;
            padding: 0px 3.5px 0 0;
            top: -9px;
            position: relative;
            left: 0px;
            width: 100%;
            float: left;
        }
        .algny{
            font-size: 14px;
            position: relative;
            top: 1px;
            margin-right: 2px;
            left: -1px;
        }
        &.awaiting{
            border-bottom:13px solid #FFC107;
            border-top:13px solid #FFC107;
            &:after{
                border-left: 10px solid #FF9C07;
            }
        }
        &.awarded{
            border-bottom:13px solid #58DC72;
            border-top:13px solid #58DC72;
            &:after{
                border-left: 10px solid #36B450;
            }
        }
		&.declined{
            border-bottom:13px solid #fb2626;
            border-top:13px solid #fb2626;
            &:after{
                border-left: 10px solid #fb2626;
            }
        }
    }
    // .feature-item{
    //     &:hover{
    //         .received{
    //             right: 0;
    //             &:after{
    //                 z-index: -9;
    //                 display: none;
    //             }
    //         }
    //     }
    // }
}
.category-tag{
    background: rgba(0, 0, 0, 0.49);
    bottom: 13px;
    border-radius: 11px;
    color: $white;
    display: inline-block;
    left: 16px;
    padding: 2px 10px;
    position: absolute;
    text-transform: uppercase;
    width: auto;
}
.show-area{
    float: left;
    width: 100%;
    min-height: 135px;
}