

//  Font Import 
@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Poppins:wght@400;500;600;700&display=swap');

/* font-family: 'Dosis', sans-serif;
font-family: 'Poppins', sans-serif; */

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  .clearfix { display: inline-block; }
  * html .clearfix { height: 1%; }
  .clearfix { display: block; }


*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    outline: none;
    word-break: break-all;
}


// Scrollbar color 

/* Scrollbar Styling */
::-webkit-scrollbar {
    width: 7px;
}
 
::-webkit-scrollbar-track {
    background-color: #ebebeb;
    -webkit-border-radius: 7px;
    border-radius: 7px;
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 7px;
    border-radius: 7px;
    background: #6d6d6d; 
}

// 3. Body

body{
    background: $white;
    font-family: 'Poppins', sans-serif !important; 
    font-size:12px;
    padding: 0px !important;
}

.container.w1280{
    max-width: 1280px;
}

.container.w1200{
    max-width: 1200px;
}

.blank-wpr{
    background: $white;
    min-height: 35px;
}

.stickyElm{
    position: fixed;
    top: 72px;
    width: 312px;
    height: auto;
    z-index: 1;
}

.mb_30{margin-bottom:30px;}

.errorcolor{
    color: #ff0000;
}
.errorinput{
    border-color: red !important;
}

.border-separator{
    border-top: 1px solid #e5e5e5;
}

/*react tab */
.react-tabs__tab--selected{
    background: unset !important;
    border-color: unset !important;
    border: medium none !important;
    border-bottom: 2px solid #ff5baa !important;
}
.react-tabs__tab-list {
    background: unset !important;
    border-color: unset !important;
    border: medium none !important;
}
.react-tabs__tab:focus{
    box-shadow: unset !important;
    border-radius: unset !important;
}
.react-tabs__tab{
    padding: 6px 23px !important;
}
.react-tabs__tab h2{
    color:#dedede;
}
.react-tabs__tab.react-tabs__tab--selected h2{
    margin-bottom: 0px !important;
    color:#172940;
}
.userCampaignskel span{
	display:block !important;
    margin-bottom: 6px;
}
/*Notification Css*/
.notification-cover{
    // position: relative;
    // height: 465px;
    // width: 360px;
    // background: #fff;
    // opacity: 1;
    // border:1px solid #F5F5F5;
    // padding: 20px 15px 15px 15px;
    position: relative;
    max-height: 465px;
    overflow: auto;
    overflow-x: hidden;
    width: 360px;
    background: #fff;
    opacity: 1;
    // border: 1px solid #F5F5F5;
    padding: 20px 15px 15px 15px;
    .tooltip-squre{
        height: 10px;
        width: 10px;
        //background: #f00;
        z-index: 999999999;
        position: absolute;
        right: 2em;
        top: -0.5em;
        transform: rotate(45deg);
    }
    .notificationtop-img{
        position: absolute;
        top: -1.7em;
        right: 2em;
    }
    h2{
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 12px;
        padding-right: 10px;
        color: #333;
    }
    h4{
        font-size: 12px;
        color:#6B6A77;
        opacity: 0.4;
        font-weight:600;
        margin-top: 0.7em;
        padding-right: 10px;
    }
    .notification-section{
        margin-bottom: 0.5em;
        margin-right: 10px;
        .notifyRead{
            border-bottom: 1px solid #F5F5F5;
            padding-bottom: 0.8em;
            margin-bottom: 8px;
        }
        img{
            margin-top: 1em;
            width: 45px;
			height:45px;
        }
        .notification-id{
            font-size: 12px;
            color: #1658D3;
        }
        .notification-time{
            font-size: 12px;
            color: #C0C4CC;
            text-align: center;
        }
        h2{
            font-size:14px;
            color: #3E3E46;
            font-weight: 600;
            margin-bottom: 0.2em;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-top: 0.7em;
        }
        p{
            margin-bottom: 0.4em;
        }
    }
    .notification-scroll-bar{
        // height:93%;
        // overflow: scroll;
        // overflow-x: hidden;
        width: 100%;
        .nonotificationmask{
            height: 140px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            p{
                margin-top: 0.5em;
                font-weight: bold;
                color: #000;
            }
        }
    }
    ::-webkit-scrollbar-thumb{
        background: #6c757d;
    }
}

// .notification-cover.home{
//     margin-top: 70px;
// }

.mycampaigns a{
	color: #fff;
    text-decoration: none !important
}

.application-name{
	color: #212529;
    text-decoration: none !important;
}
/*Campaigns styles*/
.filter-tages-mask{
    // float: left;
    // width: 100%;
    .filter-tages{
        background: #E8E8E8 0% 0% no-repeat padding-box;
        opacity: 1;
        text-align: center;
        height: 26px;
        min-width: 76px;
        padding: 5px 10px;
        font-size: 11px;
        font-weight: normal;
        color: #6B6A77;
        display: inline-block;
        margin: 4px 6px 4px 0;
    }
}
.campaigns-checkbox{
    .checkbox-text{
        font-size: 14px;
        color: #172940;
        font-weight: 500;
    }
    .form-check{
        margin-bottom: 1em;
    }
}
.leftpanel-title{
    margin-top: 1em;
}
.left-section-group{
    margin-top: 2.5em;
}
.campaign-details-mask{
    // .canp-details-lable,
    // .camp-details-info{
    //     float: left;
    //     width: 49%;
    // }
}

.feature-details{
    p{
        letter-spacing: 0.55px;
        color: #858A8F;
        opacity: 0.7;   
        font-size: 11px; 
        font-weight: 400;
        font-style: italic;   
    }
    h2{
        color: #172940;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
    }
    .campaign-details-mask{
        float: left;
        width: 100%;
        .campaign-label{
            float: left;
            width: 50%;
            font-size: 12px;
            color: #AEAEB4;
            font-weight: 400;
            margin-bottom: 0.3em;
        }
        .campaign-info{
            float: left;
            width: 50%;
            font-size: 13px;
            color: #464550;
            font-weight: 400;
            overflow: hidden;
            max-height: 60px;
            margin-bottom: 0.3em;
        }
        a.campaign-detail-btn{
            width: 100%;
            padding: 0.5em;
            background: #F9F9F9;
            border: 1px solid #FF007A;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            opacity: 1;
            text-align: center;
            color: #FF007A;
            font-size: 12px;
            font-weight: 500;
            margin-top: 1em;
            transition: all 0.4s ease;
            
                &:hover{
                    cursor: pointer;
                    transition: all 0.5s ease;
                    background: #ff5baa;
                    color:#F9F9F9;
                    order: 1px solid #ff5baa;
                    text-decoration:unset !important;
                }
            
           
        }
        .campaign-social-icons{
            list-style: unset;
            margin: 0;
            padding: 0;
            float: left;
            li{
                display:inline-block;
                margin-right: 8px;
                &:hover{
                    cursor: pointer;
                }
            }
        }
    }
}

.application-followers div{
	text-align: unset !important;
    display: inline-block !important;
}
// .feature-item{
//     .feature-details{
//         a{
//             &::after{
//                 content: "";
//                 display: block;
//                 width: 0;
//                 height: 0px;
//                 background: transparent;
//                 transition: width 0.3s;
//             }
//         }
//     }
// } 

#profile-post-number .number-post{
	display: inline-flex;
}
#profile-post-number .number-post a, #profileimg .insta-text{
	display: -webkit-inline-box;
}
#successpopup .popup-content {
width: 25% !important;
}

#editBank .popup-content, #addBank .popup-content{
    background: unset !important;
    border: none !important;
}
#editBank .popup-overlay, #addBank .popup-overlay{
    display: block;
    outline: 0;
	overflow-x: hidden;
	overflow-y: auto;
}

.bank-account .form-check-input{
    position: absolute;
    margin-top: .25rem !important;
    margin-left: -1.25rem;
}


.payment-wrapper{
    float: left;
    width: 100%;
    padding: 12em 0;
    // box-shadow: 0px 1px 20px 0px rgba(50, 67, 73, 0.2);
    .paymenttop-back{
        float: left;
        width: 100%;
        margin-bottom: 2em;
    }
    .header-banner-wrapper{
        object-fit: cover;
        width: 100%;    
    }
    .headerbottom-info{
        float: left;
        width: 100%;
        padding: 2em 0 1em 0;
        border-bottom: 1px solid #E9E9E9;
        margin-bottom: 2em;
        h2{
            font-size: 22px;
            font-weight: 600;
            color: #222222;
            margin-bottom: 1em;
        }
        .payment-tag-title{
            font-size: 13px;
            font-weight: 500;
            color: #858A8F;
        }
        .payableamout-wrapper{
            h4{
                font-size: 13px;
                font-weight: 500;
                color: #858A8F;
            }
            p{
                font-size: 20px;
                font-weight: 500;
                color: #FF007A;
            }
        }
    }
    .paymentfor-wrapper{
        float: left;
        width: 100%;
        margin-bottom: 1em;
        .payforuser-avatar{
            float: left;
            width: 30%;
        }
        .payforuser-info{
            float: left;
            width: 70%;
            padding: 8px 0px 0px 10px;
            h2{
                font-size: 14px;
                font-weight: 600;
                color: #172940;
                margin-bottom: 0px;
                padding-bottom: 0px;
            }
            p{
                font-size: 9px;
                font-weight: 600;
                color: #1658D3;
            }
        }
    }
    .striptop-lockicon{
        float: right;
        width: 160px;
        position: relative;
        background: url('/images/payment-lock.svg') no-repeat;
        background-position: left center;
        height: 25px;
        font-size: 16px;
        font-weight: 400;
        color: #C9C9C9;
        padding: 2px 0px 0px 22px;
        
    }
    .straipayment-wrapper{
        float: left;
        width: 100%;
       border: 1px solid #DDDDDD;
       padding: 1em !important;
       .cartaccept-type{
           float: left;
           width: 100%;
           margin-bottom: 1em;
           .cartaccept-type-text{
               float: left;
               width: auto;
               font-size: 16px;
               font-weight: 600;
               color: #191919;
           }
           .cartaccept-type-card{
               float: right;
               width: auto;
               .payment-cards{
                   float: right;
                   width: 135px;
               }
           }
       }
       input.InputElement {
        border-bottom: 1px solid #ccc !important;
        }
        .payviastrip-btn{
            background: #f00;
            min-width: 117px;
            height: 42px;
            background: #1658D3;
            border:medium none;
            font-size:14px;
            font-weight: 700;
            color: #ffffff;
            margin-top: 1em;
        }
        .payment-masterimg{
            width: 120px;
            text-align: right;
            float: right;
            margin-top: 2em;
        }
        input[type='text'],
        .InputElement{
            border:medium none;
            border-bottom: 1px solid #ccc !important;
            border-radius: 0px;
            -webkit-border-radius: 0px;
            -moz-border-radius: 0px;
        }
    }
    
}


button{
    cursor: pointer;
}

.payment-container{
    .successful-wrapper{
        float: left;
        width: 100%;
        .payment-result-mask{
            width: 400px;
            margin:0 auto;
            float:none;
            height: 80vh;
            flex-direction: column;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2em 0;
            /* height:calc(100vh - 10vh); */
            img{
                margin:1em 0;
                height: 80px;
                width: 80px;
              }
              h2{
                font-size: 22px;
                font-weight: 600;
                color: #222222;
                margin: 0.5em 0;
              }
              h3{
                font-size: 14px;
                font-weight: 600;
                color: #172940;
              }
              h5{
                font-size: 9px;
                font-weight: 600;
                color: #1658D3;
              }
              p{
                font-size: 16px;
                font-weight: 500;
                color: #6B6A77;
                margin: 5px 0px 12px 0;
              }

              .transaction-btn{
                min-width: 135px;
                height: 42px;
                background: #1658D3;
                text-align: center;
                font-size: 14px;
                font-weight: 500;
                color: #ffffff;
                border:medium none;
                text-transform: capitalize;
                margin: 1em 0 3em 0;
                transition: all 0.3s ease;
                &:hover{
                    background: #DEDEDE;
                    color: #1658D3;
                    transition: all 0.3s ease;
                }
              }
          }
      }
}

.instatconnect-alert{
    float: left;
    width: 100%;
    padding: 0.8em 3em 0.8em 2em;
    margin-bottom: 1.5em;
    position: relative;
    background:#F2F7FF;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    p{
        font-size: 14px;
        font-weight: normal;
        color: #1658D3;
        margin-bottom: 0px;
    }
    button{
        background:#ffffff url('../images/alert-instagram.svg') no-repeat;
        min-width: 105px;
		height: 34px;
		font-size: 14px;
		font-weight: normal;
		border: medium none;
		text-align: left;
		padding: 1px 10px 0px 33px;
		background-position: 10px center;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
    }
	a{
		background:#ffffff url('../images/alert-instagram.svg') no-repeat;
		min-width: 105px;
		height: 34px;
		font-size: 14px;
		color: #000;
		text-decoration: none;
		font-weight: normal;
		border: medium none;
		text-align: left;
		padding: 6px 10px 0px 33px;
		background-position: 10px center;
		border-radius: 5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
	}
	.instatconnect-alert a:active {
		border: 2px solid #000;
		padding: 4px 10px 0px 33px;
		width: 105px;
	}
	/*&::after{
        content: '';
		background: url('../images/alert-cross.svg') no-repeat;
        width: 12px;
        position: absolute;
        right: 10px;
        float: left;
        height: 12px;
        cursor: pointer;
    }*/
	
	span{
		width: 12px;
        position: absolute;
        right: 10px;
        float: left;
        cursor: pointer;
	}
    
}

.footer{
    float: left;
    width: 100%;
}
.breadcrumb {
    background: transparent;
    padding: 10px 0px;
}

/*signle post layout resolve*/
.feature-campaign .feature-list .feature-slider .slick-list {
    max-height: inherit !important;
}
/*signle post layout resolve*/

.slick-slide{
    .feature-details{
        h3{
            font-size: 16px !important;
            font-weight: 600 !important;
            color: #1658D3 !important;
            span{
                font-size: 16px !important;
                font-weight: 600 !important;
                color: #1658D3 !important;
            }
        }
    }
}

.loaderimg-wrapper img{
    width: 80px;
}







@media screen and  (max-width: 768px) {
    .payableamout-wrapper{
        margin-top: 1em;
    }
}

.notifyRead{
	background-color:#fff;
}
.notifyNotRead{
	background-color:#f6f6f680 !important;
	cursor:pointer;
}
.my-profile-new .popup-content {  
	width: 490px !important;
}


.custom-modal.forgot-modal{
    .modal-body{
        padding: 25px !important;
    }   
}

.showMoreText{
	font-size:12px !important;
	color: #007bff !important;
}


.modal p{
    word-break: break-word;
}

//.logoutmodel-popup{}