*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 7px;
}
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
    background: #888;
}
::selection{
  background: rgba(0,123,255,0.3);
}

#influencer_list,
#products_list,
#transactionhistory_list,
#campaigns_list,
#my-campaigns,
#my_application,
#accordion-container,
.aboutus-container,
.edit-profile-main,
.pp-container,
.tc-container{
  margin-top: 8em;
}

.navbar{
  position: fixed;
  z-index: 5;
  width: 100%;
  height: 65px;
  padding: 0px 30px;
  //background: transparent;
  background: rgba(0,0,0,0.8);
  transition: all 0.3s ease;
  .content{
    width: 100%;
    margin: auto;
    padding: 0px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo{
        a{
            color: #fff;
            font-size: 30px;
            font-weight: 600;
            text-decoration: none;
            img{
                height: 80px;
                width: 155px;
            }
          }
    }
  }
  .menu-list{
    display: inline-flex;
    margin-top: 1.5em;
    li{
        list-style: none;
        margin-top: 0px;
        a{
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            margin-left: 25px;
            letter-spacing: 1px;
            text-decoration: none;
            padding-bottom: 19px;
            // padding: 0px 0 27px 0px;
            border-bottom: 3px solid transparent;
            transition: all 0.3s ease;
            &:hover{
                color: #FF007A;
                font-size: 14px;
                font-weight: 500;
                margin-left: 25px;
            }
            &:hover svg{
              fill: #FF007A;
            }
            .hovercolor{
              fill: #ffffff;
              margin-right:5px;
              position: relative;
              top:3px;
            }
          }
          a.create-btn{
            border: 1px solid#FF5BAA;
            background: #FF5BAA;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            margin-left: 25px;
            position: relative;
            padding: 8px 15px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            &:hover{
                color: #ffffff;  
            }
          }
          a.active{
            color: #FF007A;
            border-bottom: 3px solid #FF007A;
          }
          // a.checked{
          //   color: #FF007A;
          //   transition: .3s ease-in-out;
          // }
        .dropdown {
          position: relative;
          display: inline-block;
          .dropbtn {
            color: #fff;
            font-size: 14px;
            font-weight: 500;
            margin-left: 25px;
            border: none;
            padding-right: 20px;
            &:after{ 
              content:"";
              background: url('../../images/dropdown-arrow-white.svg') no-repeat;
              height: 6px;
              width: 9px;
              position: absolute;
              top: 10px;
              right: 0;
            }
            .hovercolor{
              fill: #fff;
            }
            &:hover{
              color: #FFF;
              //color: #5D5D61;
              cursor: pointer;
            }
           
          }
          &:hover .dropdown-content {display: block;}
        }
        .create-camp-wrapper {
          position: relative;
          .savedraft-circle{
            height: 12px;
            width: 12px;
            padding: 0;
            background: #ffc107;
            position: absolute;
            top: -12px;
            right: -3px;
            border: 2px solid #333333;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
          }
        }
      }
  }
  .form-inline{
    display: none;
  }
  .logo{
    a{
      .nonscroll-logo{
        display: block;
        width: 130px;
      }
      .scroll-logo{
        display:none;
        width: 130px;
      }
    }
  }
}
.navbar.sticky{
  transition: all .5s ease;
  background: #ffffff;
  box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
  .menu-list{
    display: inline-flex;
    li{
        list-style: none;
        margin-top: 11px;
        a{
            color: #000;
            font-size: 14px;
            font-weight: 500;
            margin-left: 25px;
            letter-spacing: 1px;
            text-decoration: none;
            // padding: 0px 0 22px 0px;
            padding-bottom: 20px;
            border-bottom: 3px solid transparent;
            transition: all 0.3s ease;
            &:hover{
                color:#FF007A;
                font-size: 14px;
                font-weight: 500;
                margin-left: 25px;
                border-bottom: 3px solid #FF007A;
            }
            &:hover svg{
              fill: #FF007A;
            }
            .hovercolor{
              fill: #333;
            }
          }
          a.create-btn{
            border: 1px solid #FF5BAA;
            background: #FF5BAA;
            color: #ffffff;
            font-size: 14px;
            font-weight: 500;
            margin-left: 25px;
            position: relative;
            padding: 8px 15px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            &:hover{
                color: #ffffff;  
            }
          }
          a.active{
            color: #FF007A;
            border-bottom: 3px solid #FF007A;
          }
          // a.checked{
          //   color: #FF5BAA;
          //   transition: .3s ease-in-out;
          //     border-bottom: 3px solid #FF5BAA;
          // }
        //   .dropdown-content li a {
        //     font-size: 14px;
        //     font-weight: 500;
        //     margin-left: 25px;
        //     color: #222;
        //     padding: 8px 0px;
        //     svg{
        //       .subhovercolor {
        //         fill: #222222;
        //         position: relative;
        //         right: 8px;
        //         top: 2px;
        //       }
        //     }
        // }
        .dropdown {
          position: relative;
          display: inline-block;
          .dropbtn {
            color: #333;
            font-size: 14px;
            font-weight: 500;
            margin-left: 25px;
            border: none;
            padding-right: 20px;
            margin-top: 0px;
            &:after{ 
              content:"";
              background: url('../../images/dropdown-arrow.svg') no-repeat;
              height: 6px;
              width: 9px;
              position: absolute;
              top: 10px;
              right: 0;
            }
            // .dropdown-content {
            //   display: none;
            //   position: absolute;
            //   top: 20px;
            //   background-color: transparent !important;
            //   min-width: 257px;
            //   z-index: 1;
            //   right: 0;
            //   padding-top: 2em;
            //   .dropdown-content-inner{
            //     float: left;
            //     width: 100%;
            //     background: #fff;
            //     padding: 1em 0;
            //     box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
            //     li{
            //       margin-top: 0px;
            //       a{
            //         svg{
            //           fill:#333;
            //         }
            //       }
            //     } 
            //   }
            //   li a{
            //     font-size: 14px;
            //     font-weight: 500;
            //     margin-left: 25px;
            //     color: #222;
            //     padding: 8px 0px;
            //   }
            // }
            .hovercolor{
              fill: #333;
            }
            &:hover{
              //color: #FF007A;
              color: #5D5D61;
              cursor: pointer;
            }
          }
          &:hover .dropdown-content {display: block;}
        }
        .create-camp-wrapper {
          position: relative;
          .savedraft-circle{
            height: 12px;
            width: 12px;
            padding: 0;
            background: #ffc107;
            position: absolute;
            top: -12px;
            right: -3px;
            border: 2px solid #fff;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
          }
        }
      }
      form{
        width: 353px;
        .navsearchtop_wrapper{
            float: left;
            width: 100%;
            position: relative;
            .navsearchtop{
                width: 100%;
                font-size: 13px;
                position: relative;
                background:#ffffff url('../../images/header-search-glass.svg') no-repeat;
                border:1px solid #DCDCDC;
                height: 42px;
                border-radius: 25px;
                background-position: 15px;
                padding: 0px 100px 0px 45px;
                -webkit-border-radius: 25px;
                -moz-border-radius: 25px;
            }
            .btn-search{
                position: absolute;
                right: 1px;
                top: 1px;
                border-radius: 25px;
                min-width: 98px;
                font-size: 15px;
                height: 40px;
                font-weight: 500;
                color: #ffffff;
                background-color: #1658D3;
            }
        }
        
    }
  }
  .form-inline{
    display: block;
}
  .logo{
    a{
      .nonscroll-logo{
        width: 130px;
        display: none;
      }
      .scroll-logo{
        width: 130px;
        display:block;
      }
    }
  }
}


.navbar.loginuser{
  .menu-list{
    margin: 0px;
    form{
      width: 353px;
    }
    li.navmenu-text{
      margin-top: 11px;
    }
  }
}

.icon{
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  display: none;
}
.icon.cancel-btn{
  position: absolute;
  right: 5px;
  top: -4px;
  background: transparent;
}
.navbar.sticky .icon.cancel-btn{
  top: 10px;
}

.scroll-logo{
  display: none;
}

.navbar.sticky .nonscroll-logo{
  display: none;
}
.navbar.sticky {
  .scroll-logo{
    display: block;
    // width: 80px !important;
    // height: 80px !important;  
    //margin-left: 30px;
  }
  .menu-list {
    display: inline-flex;
    margin: 0px;
  } 
}

.menu-list{
  .dropdown-content {
    display: none;
    position: absolute;
    top: 15px;
    background-color: transparent !important;
    min-width: 210px;
    z-index: 1;
    right: 0;
    padding-top: 2em;
    .dropdown-content-inner{
      float: left;
      width: 100%;
      background: #fff;
      padding: 1em 0;
      box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
      padding-right: 10px;
      li{
        float: left;
        width: 100%;
        margin: 7px 0px;
        a{
          float: left;
          width: 100%;
          padding: 0px;
          border:medium none;
          &:hover{
            border:medium none;
          }
          svg{
            position: relative;
            top: 3px;
            right: 8px;
          }
          &:hover svg{
            fill:#FF5BAA;
          }
        }
      }
    }
    li a{
      font-size: 14px;
      font-weight: 500;
      margin-left: 25px;
      color: #222;
      padding: 8px 0px;
      svg{
        fill:#D8D8D8;
      }
    }
  } 
}

.notification-dropdown{
  .dropbtn{
    padding-right: 0px !important;
    svg{
      &:hover{
        cursor: pointer;
      }
    }
    &::after{
      content: "";
      background:unset !important;
    }
  }
}

.navbar.loginuser{
  .form-inline{
    display: block;
    .navsearchtop_wrapper{
      float: left;
      width: 100%;
      position: relative;
      .navsearchtop{
          width: 100%;
          font-size: 13px;
          position: relative;
          background:#ffffff url('../../images/header-search-glass.svg') no-repeat;
          border:1px solid #DCDCDC;
          height: 42px;
          border-radius: 25px;
          background-position: 15px;
          padding: 0px 100px 0px 45px;
          -webkit-border-radius: 25px;
          -moz-border-radius: 25px;
      }
      .btn-search{
          position: absolute;
          right: 1px;
          top: 1px;
          border-radius: 25px;
          min-width: 98px;
          font-size: 15px;
          height: 40px;
          font-weight: 500;
          color: #ffffff;
          background-color: #1658D3;
      }
    }
  }
}

li.belicon {
  .count-notification {
    background: #1658D3;
    color: #fff;
    text-align: center;
    min-width: 22px;
    min-height: 18px;
    font-size: 10px;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: -8px;
    margin-left: 7px;
    padding: 2px 0px;
  }
  
}

.navbar{
.menu-list{
  .navmenu-text{
    .notification-dropdown{
      .belicon{
        .dropdown-content{
          .dropdown-content-inner{
            padding-right: 10px;
            .notification-cover{
              height: 405px;
              padding: 20px 0px 15px 0px;
              h2{
                padding: 0px 20px;
                margin: 0px;
              }
              .notification-scroll-bar{
                .notification-section{
                  h4{
                    padding: 0px 20px 5px 20px;
                  }
                  .redirect-check{
                    padding:8px 0px;
                    margin-left: 10px;
                    background: #ffffff;
                    a{
                      margin: 0px !important;
                      padding: 0px !important;
                      p{
                        span{
                          color: #5D5D61;
                          font-size: 12px;
                          line-height: 18px;
                          letter-spacing: 0px;
                        }
                      }
                    }
                    &:hover{
                      background: #FBFCFF;
                      order:medium none !important;
                    }
                  }
                  // .notifyNotRead{
                  //   background: #ffffff;
                  //   &:hover{
                  //     background: #FBFCFF;
                  //     border:medium none;
                  //   }
                  // }
                  // .notifyRead{
                  //   background: #ffffff;
                  //   &:hover{
                  //     background: #FBFCFF;
                  //     border:medium none;
                  //   }
                  // }
                  .notification-time{
                    letter-spacing: 0px;
                  }
                  img{
                    margin-top: 0;
                  }
                }
              }
              .readall-notification{
                display: none;
                font-size: 12px;
                font-weight: 600;
                line-height: 18px;
                color: #1658D3;
                letter-spacing: 0px;
                float: left;
                width: 85%;
                text-align: right;
                margin: 0;
                padding: 0;
              }
            }
          }
        }
      }
    }
  }
}
}

.navbar.sticky{
  .menu-list{
    .navmenu-text{
      .notification-dropdown{
        .belicon{
          .dropdown-content{
            .dropdown-content-inner{
              padding-right: 10px;
              .notification-cover{
                height: 405px;
                padding: 20px 0px 15px 0px;
                h2{
                  padding: 0px 20px;
                  margin: 0px;
                }
                .notification-scroll-bar{
                  .notification-section{
                    h4{
                      padding: 0px 20px 5px 20px;
                    }
                    .redirect-check{
                      padding:8px 0px;
                      margin-left: 10px;
                      background: #ffffff;
                      a{
                        margin: 0px !important;
                        padding: 0px !important;
                        border-bottom: medium none;
                        p{
                          span{
                            color: #5D5D61;
                            font-size: 12px;
                            line-height: 18px;
                            letter-spacing: 0px;
                          }
                        }
                        &:hover{
                          border-bottom: medium none;
                        }
                      }
                      &:hover{
                        background: #FBFCFF;
                      }
                    }
                    // .notifyNotRead{
                    //   background: #ffffff;
                    //   &:hover{
                    //     background: #FBFCFF;
                    //     border:medium none;
                    //   }
                    // }
                    // .notifyRead{
                    //   background: #ffffff;
                    //   &:hover{
                    //     background: #FBFCFF;
                    //     border:medium none;
                    //   }
                    // }
                    .notification-time{
                      letter-spacing: 0px;
                    }
                    img{
                      margin-top: 0;
                    }
                  }
                }
                .readall-notification{
                  font-size: 12px;
                  font-weight: 600;
                  line-height: 18px;
                  color: #1658D3;
                  letter-spacing: 0px;
                  float: left;
                  width: 85%;
                  text-align: right;
                  margin: 0;
                  padding: 0;
                  border-bottom: medium none;
                  &:hover{
                    border-bottom: medium none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  }








@media (min-width: 1024px) and (max-width: 1280px) {
  .navbar{
    .menu-list{
      li{
        a{
          padding-bottom: 0px;
          font-size: 12px;
          margin-left: 12px;
        }
        a.create-btn{
          margin-left: 0px;
          padding: 8px;
          margin-left: 12px;
          font-size: 12px;
        }
        ul.dropdown {
          li.dropbtn{
              margin-left: 12px !important;
              font-size: 12px !important;
              ul.dropdown-content{
                .dropdown-content-inner{
                  padding: 1em;
                  li{
                    margin: 7px 0px 7px 15px;
                    a{
                      margin-left: 0px;
                      font-size: 12px;
                    }
                  }
                }
              }
          }
        }
      }
    }
  }


  .navbar.sticky{
    .menu-list{
      li.navmenu-text{
        a{
          padding-bottom: 0px;
          font-size: 12px;
          margin-left: 12px;
        }
        a.create-btn{
          margin-left: 0px;
          padding: 8px;
          margin-left: 12px;
          font-size: 12px;
        }
        ul.dropdown {
          li.dropbtn{
              margin-left: 12px !important;
              font-size: 12px !important;
              ul.dropdown-content{
                .dropdown-content-inner{
                  padding: 1em;
                  li{
                    margin: 7px 0px 7px 15px;
                    a{
                      margin-left: 0px;
                      font-size: 12px;
                    }
                  }
                }
              }
          }
        }
      }
      .form-inline{
        width: 255px;
        .navsearchtop_wrapper{
          .navsearchtop{
            padding: 0px 80px 0px 45px;
          }
          .btn-search{
            min-width: 75px;
            font-size: 12px;
          }
        }
      }
    }
  }

  .navbar.loginuser{
    .menu-list{
      margin: 0px;
      .form-inline{
        width: 255px;
        .navsearchtop_wrapper{
          .navsearchtop{
            padding: 0px 80px 0px 45px;
          }
          .btn-search{
            min-width: 75px;
            font-size: 12px;
          }
        }
      }
      li.navmenu-text{
        margin-top: 11px;
      }
    }
  }
}

@media (max-width: 868px) {
  body.disabledScroll{
    overflow: hidden;
  }
  .icon{
    display: block;
    height: 50px;
    width: 50px;
    text-align: center;
    line-height: 50px;
    // border-radius: 50%;
    z-index: 9;
  }
  .icon.cancel-btn{
    display: none;
  }
  .icon.cancel-btn.show{
    display: block;
    right: 5px;
    top: 14px;
  }
  .icon.hide{
    display: none;
  }
  .navbar .menu-list{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    // background: #FF5BAA;
    display: block;
    padding: 40px 0;
    overflow-y: auto;
    text-align: center;
    clip-path: circle(25px at calc(100% - 55px) calc(0% + 50px));
    transition: all 0.3s ease;
  }
  .navbar .menu-list.active{
    background: rgba(0,0,0,0.9);
    margin-top: 0;
  }
  .navbar.sticky .menu-list{
    clip-path: circle(25px at calc(100% - 55px) calc(0% + 35px));
  }
  .navbar .menu-list.active{
    clip-path: circle(75%);
    float: left;
    width: 100%;
    padding: 6em 2em 2em 2em;
  }

  .navbar .menu-list li{
    float: left;
    width: 100%;
    text-align: left;
    padding: 8px 15px;
  }
  .navbar .menu-list li a{
    font-size: 16px;
    float: left;
    width: 100%;
    text-align: left;
    font-weight: normal;
    margin: 0px;
  }
  .dropdown {
    width: 100%;
  }
  .navbar .menu-list.active .dropdown .dropbtn{
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    margin: 0px 5px;
    padding: 0px;
  }
  .dropdown-content {
    background-color: #fff !important;
    min-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    margin: 1em 0 3em 0;
    padding: 0.5em 2em;
  }
  .dropdown-content-inner{
    padding: 0px;
  }
  .dropdown-content li{
    padding: 0px;
  }
  .dropbtn {
    font-size: 23px;
  }
  .dropdown li{
    padding: 0px !important;
  }
  .navbar.sticky {
    padding: 0px 30px;
    
  }
  .navbar.sticky .menu-list li a{
    color: #ffffff;
  }
  .navbar.sticky .menu-list li a svg {
    fill: #fff;
  }
  .navbar.sticky .notoification-bell{
    fill: #fff !important;
  }
  .navbar.sticky .menu-list li ul.dropdown li{
    color: #ffffff;
  }
  .navbar.sticky .menu-list li ul.dropdown li svg {
    fill: #ffffff;
  }
  .navbar.sticky .dropdown .dropbtn .dropdown-content li a{
    color: #222;
  }
  .navbar.sticky .dropdown .dropbtn .dropdown-content li svg{
    fill: #222;
  }
  .navbar.sticky .menu-list li ul.dropdown .dropbtn:after{
    content:"";
    background: url('../../images/dropdown-arrow.svg') no-repeat;
    height: 6px;
    width: 9px;
    position: absolute;
    top: 10px;
    right: 0;
  }

  .navbar.loginuser .menu-list li ul.dropdown .dropbtn:after{
    content:"";
    background: url('../../images/dropdown-arrow-white.svg') no-repeat;
    height: 6px;
    width: 9px;
    position: absolute;
    top: 10px;
    right: 0;
  }
  
  .navbar{
    .menu-list{
      li.navmenu-text{
        a{
          padding-bottom: 0px;
          margin-left: 0px;
          font-size: 14px;
          border:medium none;
          // font-size: 12px;
          // margin-left: 12px;
        }
        a.create-btn{
          margin-left: 0px;
          padding-bottom: 8px;
          margin-left: 0px;
          font-size: 14px;
        }
        ul.dropdown {
          li.dropbtn{
              margin-left: 12px;
              font-size: 14px;
              color: #ffffff;
          }
        }
        ul.dropdown-content{
          position: relative;
          top: -30px;
        }
      }
    }
    .icon{
      color: #fff;
    }
  }


  .navbar.sticky{
    .menu-list{
      li.navmenu-text{
        a{
          padding-bottom: 0px;
          border:medium none;
          margin-left: 0px;
          font-size: 14px;
          // font-size: 12px;
          // margin-left: 12px;
          svg{
            fill:#fff;
          }
        }
        a.create-btn{
          margin-left: 0px;
          padding-bottom: 8px;
          margin-left: 0px;
          font-size: 14px;
        }
        ul.dropdown {
          li.dropbtn{
              margin-left: 12px;
              font-size: 14px;
              color: #ffffff;
          }
        }
        
      }
    }
    .menu-btn{
      color: #000;
    }
    .cancel-btn{
      color: #fff;
    }
  }

  .navbar.loginuser{
    .menu-list{
      li.navmenu-text{
        a.create-btn{
          margin-left: 0px;
          font-size: 16px;
        }
        ul.notification-dropdown{
         
            display: none;
         
        }
        ul.dropdown{
          li.dropbtn{
            font-size: 16px;
            margin: 0px 5px;
            color: #ffffff;
          }
        }
      }
    }
  }

//   .navbar{
//     .menu-list{
//         li {
//             a{
//                 font-size: 12px;
//                 margin-left: 12px;
//             }
//             a.create-btn{
//                 margin-left: 12px;
//                 font-size: 12px;
//             }
//             ul.dropdown {
//                 li.dropbtn{
//                     margin-left: 12px;
//                     font-size: 12px;
//                 }
//             }
//         }
//     }
// }



  .navbar.sticky .menu-list{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    // background: #FF5BAA;
    display: block;
    padding: 40px 0;
    overflow-y: auto;
    text-align: center;
    clip-path: circle(25px at calc(100% - 55px) calc(0% + 50px));
    transition: all 0.3s ease;
  }
  .navbar.sticky .menu-list.active{
    background: rgba(0,0,0,0.9);
    margin-top: 0;
  }
  .navbar.sticky .menu-list{
    clip-path: circle(25px at calc(100% - 55px) calc(0% + 35px));
  }
  .navbar.sticky .menu-list.active{
    clip-path: circle(75%);
    float: left;
    width: 100%;
    padding: 6em 2em 2em 2em;
  }

  .navbar.sticky .menu-list li{
    float: left;
    width: 100%;
    text-align: left;
    padding: 8px 15px;
    margin-top: 0px;
  }
  .navbar.sticky .menu-list li a{
    font-size: 16px;
    float: left;
    width: 100%;
    text-align: left;
    font-weight: normal;
    margin: 0px;
  }
  .dropdown {
    width: 100%;
  }
  .navbar.sticky .menu-list.active .dropdown .dropbtn{
    font-size: 16px;
    text-align: left;
    font-weight: normal;
    margin: 0px 5px;
    padding: 0px;
  }
  .dropdown-content{
    min-width: 100% !important;
    padding: 0px;
    .dropdown-content-inner{
      padding: 1em 2em !important;
    }
  }
}


