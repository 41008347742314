
.tc-container{
    color: #172940 ;
    margin-bottom: 70px;
    h2{
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0px;
        margin-bottom: 40px;
    }

    .allcon{
        .listItem{
            margin-bottom: 32px;
        }
        h3{
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 16px; 
        }
        p{  
            font-size:14px;
            font-weight: 400;
            line-height: 24px; 
            margin-bottom: 10px;
            a{
                color: #172940;
                font-size:14px; 
                line-height: 24px; 
                margin: 0; 
            }
        }
    }
}



@media (max-width:767px){ 
    .tc-container{
        h2{
            font-size:24px; 
        }
        .allcon{
            h3{
                font-size:17px; 
            }
            p{
                font-size:14px;
            }
        }
    }
}
