@media (min-width: 1366px) and (max-width: 1440px) {
    .feature-list{
        margin-top: 40px;
        width: 95%;
        margin: 0 auto;
        .feature-slider{
            .feature-item{
                width: 95%;
            }
        }
    }
    #CampaignsPreview{
        .campaignimage img {
            width: 160px !important;
            height: 160px;
            object-fit: cover;
            margin: 1em 1em 0em 0em;
        }
    } 
    
    .create-campaigns{
        .create-right{
            .inner-create-right{
                #createCampaigns{
                    .campaignimage{
                        .campaignimage-wrapper img {
                            width: 160px !important;
                            height: 160px;
                            object-fit: cover;
                            margin: 1em 1em 0em 0em;
                        }
                    }
                } 
            }
        }
    }
    .carousel-search-wrapper{
        bottom: 28% !important;
    }
}
@media (min-width: 1024px) and (max-width: 1280px) {
    .mycollaboration-wrapper{
        .my-campaigns{
            .feature-list{
                .feature-item{
                    min-height: 540px;
                    max-height: 540px;

                }
            }
        }
        .pagination-text{
            text-align: left;
        }
    }
    .followers-wrapper img{
        margin-right: 5px;
    }
    .hired-wrapper{
        .applicant-shift{
            padding-top: 3em;
        }
        .hiredbtn-wrapper{
            float: left;
            width: 100%;
            padding: 1em 0em 0 0em;
            flex-direction: column;
            
            .hired-message-btn,
            .hired-canclejob-btn{
                float: left !important;
                width: 100% !important;
                margin: 5px 0px !important;
            }
        }
    }
    // .feature-item{
    //     width: 95% !important;
    //     .received{
    //         top: 195px;
    //     }
    // }
    .feature-item{
        .received{
            top: 201px !important;
        }
    }
    .paymentaccept-infowrapper1 h2{
        width: 80%;
        float: right;
    }
    .paymentaccept-infowrapper1{
        .payment-action1 {
            float: left;
            width: 20%;
            .paymentaccept1{
                float: left;
            }
        }
    } 
    .paymentaccept-infowrapper h2{
        width: 75%;
    }
    .payment-action{
        margin-top: 7px;
    }

    .campaign-page{
        .feature-list{
            .feature-item{
                width: 45%;
                margin: 0px 10px 15px !important;
            }
        } 
    } 

    .campaign-page{
        .campaign-search-wpr{
            float: left;
            padding: 15px;
            .gender-list{
                display: inherit;
                float: left;
                width: 100%;
            }
        }
    }  
    .campaign-page {
        .container.w1280 {
            max-width: 850px;
        }
    }

    .feature-list{
        margin-top: 40px;
        width: 95%;
        margin: 0 auto;
        .feature-slider{
            .feature-item{
                width: 95%;
            }
        }
    }
    #my-campaigns{
        .feature-list{
            width: 100%;
            .feature-item{
                margin: 0px !important;
                margin-bottom: 2em !important;
                padding-bottom: 2em;
                min-height: 470px;
                max-height: 470px;
                width: 100% !important;
               .canp-details-lable{
                   .campaign-info{
                       height: 40px;
                       overflow: hidden;
                   }
               }
               .feature-details{
                   p{
                       font-size: 12px;
                   }
               }
            }
        }
    }
    #my_application{
        .my-campaigns{
            .feature-list{
                width: 100%;
                .feature-item{
                    margin: 0px !important;
                    margin-bottom: 2em !important;
                    width: 100% !important;
                    .feature-details{
                        .campaign-info{
                            height: 40px;
                            overflow: hidden;
                        }
                    }
                }
            }
        }
    }
    #CampaignsPreview{
        .campaignimage img {
            width: 180px !important;
            height: 180px;
            object-fit: cover;
            margin: 1em 1em 0em 0em;
        }
    } 
    
    .create-campaigns{
        .create-right{
            .inner-create-right{
                #createCampaigns{
                    .campaignimage{
                        .campaignimage-wrapper img {
                            width: 180px !important;
                            height: 180px;
                            object-fit: cover;
                            margin: 1em 1em 0em 0em;
                        }
                    }
                } 
            }
        }
    }
    .product-list{
        margin-top: 40px;
        width: 95%;
        margin: 0 auto;
        .product-slider{
            .feature-item{
                width: 95%;
                min-height: 480px;
                .campaign-details-mask{
                    .productpriceoffer{
                        .productactprice{
                            margin-right: 5px;
                            font-size: 12px;
                        }
                        .productprevprice{
                            font-size:10px;
                            margin-right: 5px;
                        }
                        .productdiscount{
                            min-width: 50px;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }
    #products_list{
        width: 92%;
        float: none;
        margin: 0 auto;
        .product-item{
            width: 31.5%;
        }
    }
    
    .carousel-wrapper{
        .carousel-caption-inner{
            h2{
                font-size: 25px !important;
            }
            p{
                width: 710px !important;
                font-size: 15px !important;
            }
        }
        .carousel-search-wrapper{
            bottom: 30%;
        }
    }

    .editp{
        width: 140px !important;
        height: 140px !important;
        overflow: inherit !important;
        .editpro-wrapper{
            position: relative;
            .search-influencer-offline{
                position: absolute;
                top: 2em;
                right: 5px;
            }
            img {
                width: 140px;
                height: 140px;
                border-radius: 50%;
            }
        }
    }
    .navbar.loginuser .menu-list .form-inline {
        width: 190px;
    }

    .transactionhistory_page{
        margin-top: 8em !important;
        .accordion-wrapper{
            .accordionlist-info{
                .listwrapper{
                    float: right;
                    width: 89%;
                }
                .listwrapper_date{
                    float: right;
                    width: 89%;
                }
            }
        }
    }  
    
    .feature-campaign{
        .feature-slider{
            .feature-item {
                .feature-details{
                    span{
                        font-size: 10px;
                    }
                }
            }
        }
    }

}

@media (min-width: 768px) and (max-width: 1023px) {
    .mycollaboration-wrapper{
        .my-campaigns{
            .feature-list{
                .feature-item{
                    min-height: 520px;
                    max-height: 520px;
                    .feature-details{
                        .campaign-details-mask{

                           .canp-details-lable{
                               .campaign-label{
                                   width: 100%;
                               }
                               .campaign-info{
                                   width: 100%;
                               }
                           } 

                           .canp-details-lable-status{
                            .campaign-label{
                                width: 50% !important;
                            }
                            .campaign-info{
                                width: 50% !important;
                            }  
                           }

                           .canp-details-lable-social{
                               .campaign-label{
                                   width: 70% !important;
                               }
                               .campaign-social-icons{
                                   width: 30% !important;
                               }
                           }
                        }
                    }
                    
                }
                
            }
           
        } 
        .pagination-text{
            text-align: left;
        }
        
    }
    .carousel-wrapper{
        .carousel-item{
            .carousel-caption{
                .carousel-caption-inner{
                    h2{
                        font-size: 21px;
                    }
                    p{
                        width: 75%;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    #profile .followers-wrapper {
        border: medium none;
    }
    .suggested-total-posts{
        float: left;
        width: 100%;
        text-align: center;
        margin-top: 5px;
    }
    .hired-wrapper{
        .applicant-shift{
            padding-top: 3em;
        }
        .hiredbtn-wrapper{
            float: left;
            width: 100%;
            padding: 1em 0em 0 0em;
            flex-direction: column;
            
            .hired-message-btn,
            .hired-canclejob-btn{
                float: left !important;
                width: 100% !important;
                margin: 5px 0px !important;
            }
        }
    }
    .feature-item{
        .received{
            top: 201px !important;
        }
    }
    .feature-slider{
        .feature-campaign{
            .feature-list{
                .slick-prev.slick-arrow{
                    top: 40% !important;
                    left: 12px;
                }
            }
        }  
    }
    .my-campaign-search{
        .btn-primary{
            height: 40px;
            min-height: inherit;
            line-height: inherit;
            top: 4px;
            position: relative;
        }
    }

    ul.menu{
        li{
            .dropdown-toggle {
                padding: 0 15px;
                font-size: 12px;
            } 
            a{
                padding: 0 15px;
                font-size: 12px;
            }
            .create-btn{
                font-size: 12px;
            }
        } 
    }
    .container.w1280{
        max-width: 720px;
    }

    .campaign-page{
        .feature-list{
            .feature-item{
                min-height: 475px;
                max-height: 475px;
            }
        }
    } 
    
    #profile-post-number{
        width: 100%;
    }

    .paymentaccept-infowrapper1 h2{
        width: 70%;
        float: right;
    }
    .paymentaccept-infowrapper1{
        .payment-action1 {
            float: left;
            width: 30%;
            .paymentaccept1{
                float: left;
            }
        }
    } 
    .paymentaccept-infowrapper{
        h2{
            width: 68%;
        }
        .payment-action{
            width: 30%;
            margin-top: 12px;
        }
    }
    .campaign-page{
        .feature-list{
            .feature-item{
                // width: 45%;
                margin: 0px 10px 15px !important;
            }
        } 
    } 

    .campaign-page{
        .campaign-search-wpr{
            .gender-list{
                display: inherit;
                float: left;
                width: 100%;
            }
        }
    }  
    .campaign-page{
        .container.w1280 {
            max-width: 720px;
        }
    }
    
    .feature-list{
        margin-top: 40px;
        width: 95%;
        margin: 0 auto;
        .feature-slider{
            .feature-item{
                width: 95%;
            }
        }
    }

    .product-list{
        margin-top: 40px;
        width: 95%;
        margin: 0 auto;
        .product-slider{
            .feature-item{
                width: 95%;
                min-height: 480px;
                .campaign-details-mask{
                    .productpriceoffer{
                        .productactprice{
                            margin-right: 5px;
                            font-size: 12px;
                        }
                        .productprevprice{
                            font-size:10px;
                            margin-right: 5px;
                        }
                        .productdiscount{
                            min-width: 50px;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
    }

    .inf-profile-header{
        .right-top{
            padding-left: 20px;
        }
        .right-bottom{
            padding-left: 20px;
        }
        .profile-img-wpr{
            .heart-wpr{
                right: 0px;
                top: 5px;
            }
        }
    }
    #campaigns_list {
        .campaign-search-wpr{
            float: left;
        }
        .feature-list{
            .feature-item{
                min-width: 45%;
                max-width: 45%;
                .received-campaignslist{
                    top: 190px;
                }
            }
        }
    }

    #createCampaignsPopup{
        .addwidth {
            width: 65%;
            padding-left: 30px !important;
        }
    }

    #createDraftPopup{
        .addwidth {
            width: 65%;
            padding-left: 30px !important;
        }
    }
    #my-campaigns{
        .feature-list{
            width: 100%;
            .feature-item{
                margin: 0px !important;
                margin-bottom: 2em !important;
                padding-bottom: 0em;
                min-height: auto;
                max-height:auto;
               .canp-details-lable{
                   .campaign-info{
                       height: 40px;
                       overflow: hidden;
                   }
               }
               .feature-details{
                   p{
                       width: 100%;
                   }
               }
               .received.mycampaigns,
               .received.awaiting{
                top: 115px !important;
               }
            }
        }
    }
    #my_application{
        .feature-list{
            width: 100%;
            .feature-item{
                margin: 0px !important;
                margin-bottom: 2em !important;
                padding-bottom: 2em;
                min-height: 485px;
                max-height: 485px;
               .canp-details-lable{
                   .campaign-info{
                       height: 40px;
                       overflow: hidden;
                   }
               }
            }
        }  
    }
    .interest-wpr{
        .applyforcamp-btn{
            font-size: 13px;
        }
    }
    
    #profileimg{
        .modal-dialog{
            right: 0 !important;
            max-width: 100%;
            height: 100vh;
            display: flex;
            align-items: center;
            justify-content: center;
            .modal-content{
                width: 90% !important;
                margin: 0 auto;
                align-items: center;
                display: flex;
                justify-content: center;
                .modal-body{
                    img.inf-image{
                        height: 400px;
                        object-fit: contain;
                        padding: 1em;
                        width: 100%;
                    }
                }
            }
        }
    }

    .create-right{
        .slide{
            left: -35px;
            top: 30px;
        }
    } 

    .cartaccept-type{
        .cartaccept-type-text{
            width: 100% !important;
        }
        .cartaccept-type-card{
            width: 100% !important;
            margin-top: 10px;
            .payment-cards {
                float: left !important;
            }
        }
    }

    .payment-wrapper{
        .straipayment-wrapper{
            .payviastrip-btn {
                min-width: 90px;  
                font-size: 13px;
            }
        }
    }  
    .product-list{
        .product-header{
            .campaign-header-left{
                .search-inf-wpr{
                    .search-inf{
                        .form-control{
                            width: 60%;
                        }
                        button.btn-primar{
                            width: 100px;
                        }
                    }
                    .search-inf button{
                        width: 100px;
                    }
                }
            }
        }
    }
    .product-item{
        width: 47% !important;
    }
    .my-campaigns{
        .feature-list{
            .feature-item{
                .feature-image{
                    img{
                        height: 155px;
                    }
                }
            }
        }
    }

    .editp{
        width: 140px !important;
        height: 140px !important;
        overflow: inherit !important;
        .editpro-wrapper{
            position: relative;
            .search-influencer-offline{
                position: absolute;
                top: 2em;
                right: 5px;
            }
            img {
                width: 140px;
                height: 140px;
                border-radius: 50%;
            }
        }
    }

    .transactionhistory_page{
        .accordion-wrapper{
            .accordionlist-info{
                .listwrapper{
                    float: right;
                    width: 85%;
                }
                .listwrapper_date{
                    float: right;
                    width: 85%;
                }
            }
        }
    }  
    
    .feature-campaign{
        .feature-slider{
            .feature-item {
                .feature-details{
                    span{
                        font-size: 10px;
                    }
                }
            }
        }
    }  
  
}

@media screen and  (max-width: 767px) {

	//Search Influencer
	.search-influencer-offline{
		left: 6.5rem;
	}
	
	.search-influencer-online{
		left: 9.6rem;
	}

    //  Campaigns Page
    .campaign-page {
        .search-inf-wpr{
            padding: 15px;
        }
        .campaign-header{
            padding-left: 15px;
            padding-right:15px
        }
        .campaign-search-wpr{
            margin: 0 auto;
            max-width: 100%;
        }
        .camp-left {
            padding-left: 15px;
            margin-bottom: 30px;
        }
        .feature-list{
            padding-right: 15px;
            .feature-item:nth-child(3n+1){
                margin-left: 36px;
            }
        }
    }
    .pg-wpr{
        text-align: center;
        .pg-left{
            margin-bottom: 20px;
        }
        .custom-pagination{
           @include justify-content(center);
            float: none;
        }
    }
    

    // My Campaigns Page
    .my-campaigns{
        .feature-list{
            .col-12{
                @include flexbox();
                @include justify-content(space-between);
                .feature-item{
                    margin: 0 auto 30px auto;
                }
            }
        }
    }

    // About page
    .ab-left{
        order: 2;
    }
    .ab-right{
        order: 1;
    }

    // Profile page

    .inf-profile-wpr{
        .right-bottom {
            .right-bottom-left{
                @include flex-direction(column);
                text-align: center;
                .post{
                    &::after{
                        display: none;
                    }
                }
                .right-social{
                    padding-left: 0;
                    margin-top: 20px;
                }
            }
        }
    } 

    .account-not-linked{
        h3{
            font-size: 22px;
        }
    }

    // Edit Profile Page
    .edit-profile-main{
        .tab-top-wpr{
            @include flex-direction(column);
            .custom-nav {
                .nav-link{
                    padding: 8px 14px;
                    font-size: 14px;
                }
            }
            .change-password{
                    min-width: 100px;
                    background: #1658D3;
                    padding: 10px 15px;
                    color: #fff;
                    margin: 3em 0 1em 0;
                    font-size: 13px;
            }
        }
        .edit-left{
            order:2;
        }
       .edit-right{
           order:1;
       }
    } 


    // Search Influencer
    .influen-search-wpr{
        max-width: 100%;
        margin: 0 auto 30px auto;
    }

    // Create Campaigns
    .create-campaigns {
        .popup-content {
            width: 90% !important;  
        }
        .create-right{
            .inner-create-right{
                padding-left: 15px;
                // .create-camp-content{
                //     min-height: 450px;
                //     max-height: 450px;
                // }
            }
            .footer-button-group{
                padding:10px;
                .col-12{
                    margin-bottom: 10px;
                }
                .btn-right-wpr{
                    text-align: left;
                }
            }
        }
        .camp-preview{
            max-height: 480px;
        }
    }


    .news-wpr{
        .news-section{
            .news-items{
                min-width: 340px;
                max-width: 340px;
            }
            -ms-grid-columns: 340px 340px;
            grid-template-columns: 340px 340px;
            .item1{
                -ms-grid-columns: 340px 340px;
                grid-template-columns: 340px 340px;
                grid-gap: 30px;
                .news-img-wpr{
                    max-width: 340px;
                    img{
                        max-width: 340px;
                    }
                }
                .news-details{
                    padding-left:0
                }
            }
            .news-img-wpr{
                max-width: 340px;
                img{
                    max-width: 340px;
                }
            }
            .image-details{
                width: 340px;
            }
        }
    }


    // Influencer Profile
    .inf-profile-wpr {
        .bg_al {
            .inf_content{
                margin: 0 auto;
            }
        }
    }

    // Help
    .help-right{
        min-width: 90%;
        margin-top: 40px;
        margin-bottom: 40px;
    }


    .inf-profile-wpr{
        .bg_al{
            .inf_content{
                .modal#profileimg{
                    
                    .modal-dialog{
                        right:0;
                        .modal-content{
                            width: 300px;
                            height: auto;
                            margin: 0 auto;
                            .modal-body{
                                position: relative;
                                padding: 0px;
                                img.inf-image{
                                    // height: 310px
                                    height: 285px;
                                    margin-top: 1em;
                                    object-fit: contain;
                                    width: 100%;
                                }
                                .modal-right-info{
                                    padding: 1em;
                                    padding-bottom: 5em;
                                    .modal-right-text{
                                        height: 100px;
                                        overflow: auto;
                                        overflow-x: hidden;
                                    }
                                    .footer-insta-bottom{
                                        width: 85%;
                                        position: absolute;
                                        padding: 0 1em;
                                        bottom:1em;
                                        left: 0;
                                        right: 0;
                                        margin: 0 auto;
                                        .insta-text{
                                            color: #333;
                                            font-size: 12px;
                                            padding-top: 8px;
                                            .fa{
                                                color: #ccc;
                                                font-size: 12px;
                                                margin-right: 5px;
                                            }
                                        }
                                        .modal-insta-img{
                                            img{
                                                padding-top: 6px;
                                                width: 60px;
                                                float: right;
                                            }
                                        }
                                    }
                                }
                            }
                        }    
                    }   
                }
            }
        }
    }   

	#successpopup .popup-content {
		width: 94% !important;
    }
    
    button.responsive-filter{
        display: block;
        margin-left: 5px;
    }
    .application-filter-form{
        display: none;
    }
	.responsive-sorting{
        display: block;
		width: 0%;
		margin-right: -62px;
		opacity: 0;
		margin-top: -5px;
    }
	.suggested-sorting{
		margin-right: -4px;
		margin-top: -5px;
    }
    .toogletab-section{
        #application-user-list-tab{
            .showlistgrid{
                .btn{
                    margin: 0px 0px; 
                }
            }
        }
    }
    
.applicantuserpayment{
    .paymentaccept-infowrapper{
        h2{
            float: left;
            width: auto;
            margin: 1em !important;
        }
        .payment-action{
            float: left;
            width: 100%;
            text-align: center;
            margin-top: 0.5em;
        }
    }
}

.applicantspaymentModal{
    .modal-content{
        .modal-body{
            .makepayment-infowrapper {
                float: left;
                width: 100%;
                margin-bottom: 1em;
            }
            .makeoffer-modal-form{
                .whatisescrow-wrapper{
                    .report-menu{
                        width: 325px;
                        top: -180px;
                        left: -165px;
                    }
                }
            }
        }
    }
}   


.mycollaboration-wrapper{
    .page-heading{
        text-align: center;
    }
    .custom-sort{
        float: left;
        width: 100%;
        margin-bottom: 10px;
        .form-group{
            min-width: 100%;
        }
    }  
    
}

.carousel-wrapper{
    .carousel-item{
        .carousel-caption{
            .carousel-caption-inner{
                width: 75%;
                h2{
                    font-size: 21px;
                }
                p{
                    width: 90%;
                    font-size: 13px;
                }
            }
        }
        .carousel-image-one{
            background-position: center right !important;
        }
        // .carousel-image-one{
        //     height: 80vh;
        //     background-position: center right !important;
        //  }
        //  .carousel-image-tow{
        //     height: 80vh;
        //     background-position: center right !important;
        //  }
        //  .carousel-image-three{
        //     height: 80vh;
        //     background-position: center right !important;
        //  }
    }
    .carousel-search-wrapper{
        bottom: 8%;
        .search-wrapper{
            padding: 1em !important;
            .searchinput-wrapper{
                #carouselSearchInput{
                    margin-bottom: 0.5em;
                }
            }
            #carouselSelectInput{
                margin-bottom: 0.5em;
                padding: 0px 42px 0px 15px;
            }
            select{
                margin-bottom: 0.5em;
            }
            .carousel-search-btn{
                margin-left: 0px;
            }
        }
    }
    
}    

.feature-campaign{
    h2{
        font-size: 20px;
        font-weight: 600;
        line-height: 40px
    }
    .feature-list{
        margin-top: 25px !important;
        .feature-slider{
            .slick-prev.slick-arrow{
                left: 4px;
                top: 135px;
                width: 35px;
                height: 35px;
            }
            .slick-next.slick-arrow{
                right: 4px;
                top: 135px;
                width: 35px;
                height: 35px;
            }
            .feature-item{
                margin: 0px !important; 
                width: 100%;
                max-width: 100%;
            }
            .slick-list{
                max-height: 530px !important;
            }
        }
    }
}


.productlist-campaign{
    h2{
        font-size: 20px;
        font-weight: 600;
        line-height: 40px
    }
    .product-list{
        margin-top: 25px !important;
        .product-slider{
            .slick-prev.slick-arrow{
                left: 4px;
                top: 135px;
                width: 35px;
                height: 35px;
            }
            .slick-next.slick-arrow{
                right: 4px;
                top: 135px;
                width: 35px;
                height: 35px;
            }
            .feature-item{
                margin: 0px !important; 
                width: 100%;
                max-width: 100%;
                min-height: 480px;
            }
            .slick-list{
                max-height: 530px !important;
            }
        }
    }
}
.my-campaign-search {
    .btn-primary{
        margin-top: 0px;
    }
}
.page-heading{
    text-align: center;
}
.influencer-wpr{
    .heading{
    
        h2{
            font-size: 20px;
            font-weight: 600;
            line-height: 40px
        }
    }
    .influencer-slider{
        margin:30px 0px;
        .slick-slider{
            float: left;
            width: 100%;
            padding:1em 0;
            background: #ffffff;
            // border-top:3px solid rgb(22, 88, 211, 0.5);
            // border-bottom: 3px solid rgb(22, 88, 211, 0.5);
            margin-bottom: 2em;
            text-align: center;
            .influencer-item{
                margin: 0 0px;
                text-align: center;
                max-width: 100%;
                width: 100%;
                .inf-img{
                    margin: 0 auto;
                }
                .influencer-details{
                    padding:0px 12px;
                    h3{
                        margin-top: 0px;
                    }
                    h4{
                        margin-top: 0px;
                    }
                }
            }
        }
    }
}

.download-section{
    padding-top: 0px;
    min-height: auto;
    .tips-wpr{
        float: left;
        width: 100%;
        padding: 30px 0px 45px 0;
        h3{
            font-size: 24px;
        }
        p{
            font-size: 13px;
        }
        .show_mbl{
            float: left;
            width: 100%;
        }
        .download-wpr {
            margin-top: 2em;
            text-align: center;
            a{
            float: none;
            display: inline-block;
            width: 40%;
            margin: 0px 12px;
                img{
                    width: 100%;
                }
            }
        }
    }
}

.help-container{
	margin-top:0px!important;
	margin-bottom:0px!important;
}

.homepage-header-only{
    .right-header.text-right{
        .mobile-menu {
            .mobile-icon{
                width: 30px;
                background: #fff;
                height: 2px;
                &:nth-child(2){
                    margin: 8px 0;
                    height: 1.5px;
                }
                &:last-child{
                    margin: 8px 0;
                    height: 1.5px;
                }
            }
        }
    }
}

.header-sticky{
    .right-header.text-right{
        .mobile-menu {
            position: relative;
            top: 7px;
            .mobile-icon{
                width: 30px;
                background: #000;
                height: 1.5px;
                &:nth-child(2){
                    margin: 8px 0;
                    height: 2px;
                }
                &:last-child{
                    margin: 8px 0;
                    height: 1.5px;
                }
            }
        }
    }
}
	.homesearch-required{
		margin: 0.2em;
	}

.homepage-header-only{
    .menu{
        margin-top: 0px;
        li{
            .navigationcreate-camp-btn{
                text-align: left;
            }
        } 
    }
}  
.homepage-header-only.header-sticky{
    .menu li{
        a{
            color: #ffffff;
        }
       .dropdown-toggle {
            color: #fff;
            svg{
                fill: #fff;
            }
        }
    }
}
.applicant-wrapper{
    .acceptoffer-label{
        display: inherit;
        width: 165px;
        span{
            text-align: left;
        }
    }
} 

.paymentaccept-infowrapper1 {
    float: none;
    width: 100%;
    background: #e4e2ff80;
    padding: 1.5em 1.5em 1em;
    margin: unset;
    margin-bottom: 20px;
}

.paymentaccept-infowrapper1 .payment-action1 .paymentaccept1 {
    min-width: 90px;
    height: 28px;
    padding: 6px 10px;
    margin: unset;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #ffffff;
    background: #58DC72;
}

.paymentaccept-infowrapper1 h2 {
    float: left;
    width: 100%;
    position: unset;
    top: 0px;
    line-height: 25px;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #172940 !important;
    padding: 0px !important;
    margin: 12px 0px 0px 0px !important
}

.signUp-modal{
    .mobile-wpr{
        .mobile-left{
            width: 23%;
        }
        .mobile-right{
            width: 72%;
        }
    }
}  

.titlebackbtn{
    margin-bottom: 15px;
}
.paymentaccept-infowrapper{
    margin: 0px 0px 20px 0px;
    h2{
        float: left;
        width: 100%;
    }
    .payment-action{
        float: left;
        width: 100%;
        margin-top: 15px;
        .paymentaccept{
            float: left;
            min-width: auto;
        }
        .paymentreject{
            float: left;
            min-width: auto;
        }
    }
}
.popup-content {
    width: 98% !important;
}

.campaign-amount{
    padding-right: 0px;
    padding-left: 0px;
}

.campaign-page {
    .feature-list{
        // float: left;
        width: 100%;
        margin: 0px;
        padding: 0px;
        .feature-item{
            margin: 0 auto !important;
            margin-bottom: 2em !important;
        }
    }
}
#createCampaignsPopup{
    .addwidth {
        width: 85%;
        padding-left: 15px !important;
    }
    #createCampaigns{
        .cancel-btn{
            font-size: 13px;
        }
        #saveNextBtn{
            font-size: 13px;
            float: right;
        }
    }
    #CampaignsPreview{
        .cancel-btn{
            font-size: 13px;    
            svg{
                width: 12px;
            }
        }
        .postcampaign-btn{
            font-size: 13px;
            float: right;
        }
    }
}
.header-sticky{
    background: #ffffff;
    max-height: 72px;
    padding: 16px 40px;
    transition: 0.2s;
    // position: inherit;
}
.createcampaign-moreless{
    span{
        font-size: 14px;
    }
}

.showlistgrid{
    .responsive-filter{
        height: 25px;
        width: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        i{
            padding: inherit;
            font-size: 10px;
        }
        
    } 
    
        .btn{
            padding: 0px;
            height: 25px;
            width: 25px;
            margin-left: 4px !important;
            i{
                font-size: 12px;
            }
        }
        
    
}
.toogletab-section{
    #application-user-list-tab{
        h3{
            font-size: 12px;
        }
    }
    .tab-top-wpr{
        #myTab{
            li{
                a{
                    font-size: 12px;
                    padding: 8px 15px;
                }
            }
        }
    }
    .applicant-list-wrapper{
        .applicantlist-inforight{
            padding: 0px !important;
            border:medium none !important;
        }
    }
}

.edit-profile-main {
    .popup-content{
        width: 90% !important;
    }
}

.login-modal{
    .social-btn-wpr {
        .facebook-btn{
            width: 48%;
            font-size: 16px;
            font-weight: 400;
            padding: 10px 10px;
        }
        .insta-btn{
            width: 48%;
            font-size: 16px;
            font-weight: 400;
            padding: 10px 10px;
        }
    }
} 

.payment-wrapper{
    .paymentfor-wrapper{
        .payforuser-avatar {
            float: left;
            width: 20%;
            text-align: center;
        }
    }
}  

.payment-container{
    .successful-wrapper{
        .payment-result-mask{
            width: 100%;
        }
    }
}  
.inf-profile-wpr{
    .right-bottom{
        .right-bottom-left{
            .right-social{
                a{
                    font-size: 14px;
                    padding: 10px 15px;
                    i{
                        font-size: 13px;
                        margin-right: 5px;
                    }
                }
            }
        }
    }
}   

.influencerprofile-tab-wrapper{
    .react-tabs__tab {
        padding: 6px 15px !important;
        h2{
            font-size: 16px;
        }
    }
}

.max-upload .modal-dialog{
    max-width: 100%;
}
.create-camp-wrapper {
    .savedraft-circle{
        position: relative;
        top: -3px;
        right: -15px;
    }
}
.createrightCreate{
    width: 90%;
}
#emailSentModal{
    .modal-body{
        padding-top: 7em;
    }
	.signup-success-body{
		padding-top:2.6em !important;
	}
}
.para {
    font-size: 14px;
    line-height: 25px;
    font-weight: 600;
    padding: 1em 0 0;
}
#CampaignsPreview{
    .campaignimage img {
        width: 100% !important;
        height: 220px;
        object-fit: cover;
        margin: 1em 1em 0em 0em;
    }
} 

.create-campaigns{
    .create-right{
        .inner-create-right{
            #createCampaigns{
                .campaignimage{
                    .campaignimage-wrapper img {
                        width: 100% !important;
                        height: 220px;
                        object-fit: cover;
                        margin: 1em 1em 0em 0em;
                    }
                }
            } 
        }
    }
}  
.products-page{
    h2.page-heading{
        text-align: left;
        padding:0px 20px;
    }
    .breadcrumb{
        padding:10px 20px;
    }
    .camp-left{
        padding-left: 15px;
        margin-bottom: 30px;
    }
    .campaign-search-wpr{
        margin: 0 auto;
        max-width: 100%;
    }
    .product-list{
        width: 100%;
        margin: 0px;
        padding: 0px;
        .product-header{
            float: left;
            width: 100%;
            display: initial;
            padding: 15px;
            .campaign-header-right{
                .shortingselect-custom{
                    position: relative;
                    top: 10px;
                }
                .shortingselect-custom{
                    .form-group{
                        width: 100%;
                        margin: 1.5em 0 1em 0;
                    }
                }
                
            }
        }
        .product-item{
            float: none;
            position: relative;
            width: 92%;
            margin: 0 auto !important;
            margin-bottom: 15px !important;
        }
    }
} 
.instatconnect-alert{
    display: inherit;
    text-align: center;
    padding-bottom: 20px;
    p{
        margin-bottom: 1.5em;
        text-align: left;
    }
    a{
        padding: 8px 10px 8px 33px;
        margin-bottom: 10px;
    }
    span {
        position: absolute;
        right: 7px;
        cursor: pointer;
        top: 2px;
    }
} 
.navsearchtop_wrapper{
    display: none;
}
// .search-influencer-offline{
//     position: absolute;
//     top: 2rem;
//     right: 7rem;
// }

.editp{
    width: 140px !important;
    height: 140px !important;
    overflow: inherit !important;
    .editpro-wrapper{
        position: relative;
        .search-influencer-offline{
            position: absolute;
            top: 2em;
            right: 5px;
        }
        img {
            width: 140px;
            height: 140px;
            border-radius: 50%;
        }
    }
}

.transactionhistory_page{
    .transactionhistory-tabs-wrapper{
        .product-header{
            padding: 15px !important;
            max-height: 115px;
        }
        .accordion-wrapper{
            .accordionlist-info {
                .listwrapper{
                    float: right;
                    width: 76%;
                    .listwrapper_left{
                        width: 100%;
                    }
                    .listwrapper_right{
                        width: 100%; 
                        span.lessamount{
                            float: left;
                            &::after{
                                position: absolute;
                                top: 10px;
                                right: 0px;
                            }
                        }
                        span.addamount{
                            float: left;
                            &::after{
                                position: absolute;
                                top: 10px;
                                right: 0px;
                            }
                        }
                    }
                }
            }
            .listwrapper_date{
                width: 76%;
            }
            #accordion{
                .card {
                    .card-body{
                        width: 100%;
                        padding: 10px;
                        .accordion-inner-wrapper{
                            .accordion-info-right{
                                .productlist-img-wrapper{
                                    .productlist-img-info{
                                        width: 100%;
                                        p{
                                            font-size: 12px;
                                        }
                                        h2{
                                            width: 90%;
                                            text-overflow: ellipsis;
                                            overflow: hidden;
                                            white-space: nowrap;
                                        }
                                    }
                                   
                                }
                            }
                        }
                    }  
                }
                .card-header{
                    .accordionlist-info{
                        .accordionimg{
                            float: left;
                            width: 16%;
                            img{
                                width: 100%;
                            }
                        }
                        .listwrapper{
                            float: left;
                            width: 80%;
                        }
                        .listwrapper_date{
                            float: left;
                            width: 80%;
                        }
                    }
                }
            }
        }
    }
}

.createuser-name{
    .createuser-form{
        .createuser-form-inner{
            width: 100% !important;
            .form-createuser{
                
            .createuerinput{
                width: 100%;
            }
               
            }
        }
    }
}  

 
}

@media only screen and (max-width : 320px) {
    .login-modal{
        .social-btn-wpr {
            .facebook-btn{
                width: 48%;
                font-size: 14px;
                font-weight: 400;
                padding: 10px 10px;
            }
            .insta-btn{
                width: 48%;
                font-size: 14px;
                font-weight: 400;
                padding: 10px 10px;
            }
        }
    } 
    .payment-wrapper {
        .straipayment-wrapper{
            .cartaccept-type{
                .cartaccept-type-text{
                    font-size: 14px;
                }
            }
        }
    }  
    .inf-profile-wpr{
        .right-bottom{
            .right-bottom-left{
                .right-social{
                    a{
                        font-size: 14px;
                        padding: 10px 15px;
                        i{
                            font-size: 13px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    } 
    
}