// 7. Campaign Section

.productlist-campaign{
    margin: 50px 0 90px 0;
    padding: 0;
    position: relative;
    .product-list{
        margin-top: 40px;
        .product-slider {
            position: relative;
            overflow: hidden;
            .slick-list{
                max-height: 500px;
            }
        }
        .slick-prev.slick-arrow{
            // position: absolute;
            // z-index: 10;
            // left: 50px;
            background: rgba(22, 88, 211, 0.8) url('/images/slider-arrow-left.svg') no-repeat;
            width: 49px;
            height: 49px;
            // position: absolute;
            z-index: 1;
            background-position: center;
            top: 60%;
            left: -15px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            transition: all 0.4s ease;
            &:before{
                //content: url('/images/fetureslider-left-arrow.svg');
                content: " ";
                font-size: 0px;
               // background: url('/images/slider-arrow-left.svg') no-repeat;
                // background-position: center;
                // border-radius: 4px;
                // -webkit-border-radius: 4px;
                // -moz-border-radius: 4px;
                // height: 49px;
                // width: 49px;
                // opacity: 1; 
            }
            &:hover{
                transition: all 0.4s ease;
                background: #1658D3 url('/images/slider-arrow-left.svg') no-repeat;
                position: absolute;
                background-position: center;
                z-index: 1;
            }
           
        }

        .slick-next.slick-arrow{
            // position: absolute;
            // z-index: 10;
            // left: 50px;
            background: rgba(22, 88, 211, 0.8) url('/images/slider-arrow-right.svg') no-repeat;
            width: 49px;
            height: 49px;
            background-position: center;
            position: absolute;
            //z-index: 1;
            top: 60%;
            right: -25px;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            transition: all 0.4s ease;
            &:before{
                content: " ";  
                font-size: 0px;
            }
            &:after{
                //content: url('/images/fetureslider-left-arrow.svg');
                content: " ";
                //background: 
                font-size: 0px;
                // background-position: center;
                // border-radius: 4px;
                // -webkit-border-radius: 4px;
                // -moz-border-radius: 4px;
                // height: 49px;
                // width: 49px;
                opacity: 1; 
            }
            &:hover{
                transition: all 0.4s ease;
                background: #1658D3 url('/images/slider-arrow-right.svg') no-repeat;
                position: absolute;
                background-position: center;
                z-index: 1;
            }
           
        }
		.image-top-price {
			float: left;
			width: auto;
			padding: 5px 10px;
			position: absolute;
			top: 1em;
			left: 1em;
			background: #FFFFFF;
			box-shadow: 0px 3px 6px #00000029;
			border-radius: 6px;
			-webkit-border-radius: 6px;
			-moz-border-radius: 6px;
			opacity: 1;
			color: #1658D3;
			font-size: 14px;
			font-weight: 600;
		}
	}
    .slick-slider {
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }
    .slick-list.draggable {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    .product-slider{
        overflow: visible !important;
        .feature-item{
            background: $grey1 ;
            display: inline-block;
            list-style: none;
            height: auto;
            margin: 0 10px;
            // max-width: 380px;
            min-height: 420px;
            width: 100%;
            list-style: none;
        
            .feature-image{
                position: relative;
                overflow: hidden;
                .react-loading-skeleton{
                    border-radius: 0;
                    line-height: 1.4;
                }
                img{
                    height: 300px;
                    width: 100%;
                    overflow: hidden;
                    //object-fit: cover;
                }
            }
            .productlist-details{
				h2{
                    font-size: 16px;  
                    font-weight: 600;   
                    color: #172940;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
				a{
					color: #172940;
					transition: 0.3s;
					-moz-transition: 0.3s;
					-webkit-transition: 0.3s;
				}
				a:hover {
					color: #ff007a;
					text-decoration: none;
				}
            }
            .campaign-details-mask{
                p{
                    font-size: 12px;
                    font-weight: normal;
                    color:#464550;
                    opacity: 1;
                    font-style: normal;
                    height: 40px;
                    overflow: hidden;
                    margin-bottom: 0px;
                }
                .productpriceoffer{
                    float: left;
                    width: 100%;
                    margin: 1em 0 0.5em 0;
                    .productactprice{
                        display: inline-block;
                        float: none;
                        width: auto;
                        font-size: 14px;
                        font-weight: 500;
                        text-transform: uppercase;
                        color: #1658D3;
                        margin-right: 10px;
                    }
                    .productprevprice{
                        display: inline-block;
                        float: none;
                        width: auto;
                        font-size: 12px;
                        font-weight: normal;
                        color: #707070;
                        margin-right: 10px;
                        text-decoration: line-through;
                    }
                    .productdiscount{
                        display: inline-block;
                        float: none;
                        width: auto;
                        min-width: 55px;
                        height: 20px;
                        background:#40E8A4;
                        font-size: 11px;
                        font-weight: 500;
                        color: #ffffff;
                        padding: 2px 5px;
                        text-align: center;
                        border-radius: 2px;
                        -webkit-border-radius: 2px;
                        -moz-border-radius: 2px;
                    }
                }
                .campaign-detail-btn{
                    float: left;
                    width: 100%;
                    padding: 0.5em;
                    background: #F9F9F9;
                    border: 1px solid #FF007A;
                    border-radius: 4px;
                    -webkit-border-radius: 4px;
                    -moz-border-radius: 4px;
                    opacity: 1;
                    text-align: center;
                    color: #FF007A;
                    font-size: 12px;
                    font-weight: 500;
                    margin-top: 1em;
                    transition: all 0.4s ease;
                    &:hover{
                        cursor: pointer;
                        transition: all 0.5s ease;
                        background: #ff5baa;
                        color: #F9F9F9;
                        order: 1px solid #ff5baa;
                        text-decoration: unset !important;
                    }
                }
            }
        }
        // .slick-prev.slick-arrow{
        //     left: 5px;
        //     top: 185px;
        // }
        // .slick-next.slick-arrow{
        //     right: 5px;
        //     top: 185px;
        // }
    }
    .see-all-featured{
        display: flex;
        justify-content: center;
        position: relative;
        top: 30px;
        a.viewall-btn {
            background: #fff;
            border-radius: 0;
            padding: 7px;
            font-size: 16px;
            font-weight: 500;
            border: 1px solid #E0E0E5;
            color: #333;
            min-height: 40px;
            min-width: 90px;
            text-align: center;
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 4px;
            transition: all 0.3s ease;
            &:hover{
                transition: all 0.3s ease;
                background: #1658D3;
                color: #fff;
                border: 1px solid #1658D3;
                text-decoration: none;
            }
        }
    }
}
.show-area{
    float: left;
    width: 100%;
    min-height: 135px;
}

// .homecampaigns {
//     position: absolute;
//     top: 14px;
//     right: -5px;
//     color: #ffffff;
//     width: auto;
//     padding: 0 0px;
//     height: 26px;
//     width: 185px;
//     border-bottom: 13px solid #689DFF;
//     border-top: 13px solid #689DFF;
//     border-left: 6px solid transparent;
//     font-size: 12px;
//     font-weight: 400;
//     line-height: 17px;
//     .bookmark-text{
//         overflow: hidden;
//         text-overflow: ellipsis;
//         white-space: nowrap;
//     }
// }

// .homecampaigns a {
//     color: #fff;
//     text-decoration: none !important;
// }

// .homecampaigns .bookmark-text {
//     padding: 0px 3.5px;
//     top: -9px;
//     position: relative;
//     left: 0px;
//     width: 100%;
//     float: left;
// }

// .homecampaigns .algny {
//     font-size: 14px;
//     position: relative;
//     top: 1px;
//     margin-right: 2px;
//     left: -1px;
// }

// .homecampaigns:after {
//     position: absolute;
//     content: "";
//     width: 0;
//     height: 0;
//     border-top: 10px solid transparent;
//     border-bottom: 10px solid transparent;
//     border-left: 10px solid #1658D3;
//     z-index: -1;
//     top: 3px;
//     right: 0;
// }

.productdetail_mask_wrapper{
    .breadcrumb_wrapper{
        float: left;
        width: 100%;
        .breadcrumb {
            background: transparent;
            padding: 8px 0px 15px 0px;
        }
        .breadcrumb-item:first-child{
            &::before{
                padding: 0px;
                content: "";
            }
        }
        .breadcrumb-item{
            color: #172940;
            font-size: 12px;
            &::before{
                padding-right: .3rem;
                padding-left: .3rem;
                color:#172940;
                content: ">";
            }
            a{
                color: #172940;
                font-size: 12px; 
            }
        }
    }
.productdetail_wrapper{
    float: left;
    width: 100%;
    img{
        width: 100%;
        object-fit: cover;
    }
    .productdetail_mask{
        float: left;
        width: 100%;
        border:1px solid #E5E5E5;
        padding: 1.5em;
        h2{
            font-size:15px;
            font-weight: 600;
            color: #172940;
            line-height: 23px;
        }
        .productpriceoffer{
            float: left;
            width: 100%;
            margin: 1em 0 0.5em 0;
            .productactprice {
                display: inline-block;
                float: none;
                width: auto;
                font-size: 14px;
                font-weight: 500;
                text-transform: uppercase;
                color: #1658D3;
                margin-right: 10px;
            }
            .productprevprice {
                display: inline-block;
                float: none;
                width: auto;
                font-size: 12px;
                font-weight: normal;
                color: #707070;
                margin-right: 10px;
                text-decoration: line-through;
            }
            .productdiscount {
                display: inline-block;
                float: none;
                width: auto;
                min-width: 55px;
                height: 20px;
                background: #40E8A4;
                font-size: 11px;
                font-weight: 500;
                color: #ffffff;
                padding: 2px 5px;
                text-align: center;
                border-radius: 2px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
            }
        }
        .product-detail-btn {
            float: left;
            min-width: 92px;
            padding: 0.5em;
            background: #F9F9F9;
            border: 1px solid #FF007A;
            opacity: 1;
            text-align: center;
            color: #FF007A;
            font-size: 12px;
            font-weight: 500;
            margin-top: 1em;
            transition: all 0.4s ease;
            &:hover {
                cursor: pointer;
                transition: all 0.5s ease;
                background: #ff5baa;
                color: #F9F9F9;
                order: 1px solid #ff5baa;
                text-decoration: unset !important;
            }
        }
		.outofstock{
			transition: all 0.5s ease;
			background: #ff5b5b;
			color: #F9F9F9;
			border: 1px solid #ff5b5b;   
			text-decoration: unset !important;
		}
    }
}
.productreview_section{
    float: left;
    width: 100%;
    margin: 4em 0 2em 0;
    float: left;
    width: 100%;
    padding-bottom: 2.5em;
    margin-bottom: 2.5em;
    border-bottom: 1px solid #E5E5E5;
    h2{
        float: left;
        width: 100%;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        color: #172940;
    }
    .rating-left{
        float: left;
        width: 40%;
        border-right: 1px solid #E5E5E5;
        h2{
            font-size: 40px;
            font-weight: 600;
            line-height: 60px;
            color: #172940;
            img{
                margin: 0px 15px;
                position: relative;
                top: -4px;
            }
        }
        p{
            float: left;
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            color:#A1A1A1;
        }
       
    }
    .rating-right{
        float: left;
        width: 60%;
        padding-left: 3em;
        .ratingiven-img{
            float: left;
            width: 100%;
            img{
                margin: 0px 15px 10px 0;
                float: left;
            }
            .progress{
                width: 55%;
                float: left;
                margin-right: 10px;
                border-radius: 0px;
                -webkit-border-radius: 0px;
                -moz-border-radius: 0px;
                .rating80{
                    width: 80%;
                    border-radius: 0px;
                    background-color: #94B9FF;
                }
                .rating50{
                    width: 50%;
                    border-radius: 0px;
                    background-color: #94B9FF;
                }
                .rating25{
                    width: 25%;
                    border-radius: 0px;
                    background-color: #94B9FF;
                }
                .rating10{
                    width: 10%;
                    border-radius: 0px;
                    background-color: #94B9FF;
                }
            }
            .rating-count{
                float: left;
                font-size: 10px;
                font-weight: 400;
                line-height: 16px;
                color:#A1A1A1; 
            }
        }
    }
    .writeareview-btn-mask{
        float: left;
        width: 100%;
        margin-top: 3em;
        .writeareview-btn{
            float: left;
            min-width: 191px;
            height: 44px;
            font-size: 16px;
            font-weight: 500;
            line-height: 25px;
            opacity: 1;
            color:#ffffff;
            background: #4579DC url('/images/write-review.svg') no-repeat;
            border:medium none;
            padding: 0px 12px 0px 35px;
            background-position: 18px 50%;
            transition: all 0.4s ease;
            &:hover{
                opacity: 0.9;
                transition: all 0.4s ease;
            }
        }
    }
    
}

.productreview_list_section{
    float: left;
    width: 100%;
    .productreview_mask{
        float: left;
        width: 100%;
        padding-bottom: 0.5em;
        margin-bottom: 2em;
        border-bottom: 1px solid #E5E5E5;
        .review-intro-section{
            img{
                float: left;
                width: 56px;
                height: 56px;
                margin-right: 20px;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
            }
            .review-intro-info{
                float: left;
                h2{
                    float: left;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 21px;
                    margin-bottom: 3px;
                    color: #1658D3;
                }
                p{
                    float: left;
                    width: 100%;
                    font-size: 9px;
                    font-weight: 600;
                    line-height: 13px;
                    color: #172940;
                    margin-bottom: 3px;
                }
                span{
                    float: left;
                    width: 100%;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #A1A1A1;
                }
            }
        }
        .review-desc-section{
            float: left;
            width: 100%;
            margin-top: 1.5em;
            h2{
                float: left;
                width: 100%;
                font-size: 14px;
                font-weight: 600;
                color: #172940;
            }
            p{
                float: left;
                width: 100%;
                font-size: 14px;
                line-height: 24px;
                font-weight: 400;
                color: #464550;
            }
        }
    }
}
.productgallery-title{
    font-size: 16px;
    font-weight: 600;
    line-height: 25px;
    color: #172940;
    margin-bottom: 1.5em;
    a{
        float: right;
        font-size: 16px;
        font-weight: 600;
        line-height: 25px;
        color: #1658D3;
        &:hover{
            text-decoration: none;
        }
    }
}

.productdetail-artical-wrapper{
    float: left;
    width: 100%;
    .porduct-artical-gallery{
        float: left;
        width: 100%;
    }
    .porduct-artical-info{
        float: left;
        width: 100%;
        h2{
            float: left;
            width: 100%;
            font-size: 24px;
            font-weight: 600;
            line-height: 35px;
            color: #172940;
        }
        p{
            float: left;
            width: 82%;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
            color: #464550;
            margin-bottom: 0px;
        }
        .product-rating{
            float: left;
            width: 100%;
            margin: 20px 0px 5px;
            img{
                float: left;
            }
            span{
                float: left;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                color: #6B6A77;
            }
        }
        .productbuynow-wrapper{
            float: left;
            width: 100%;
            margin: 2em 0;
            button{
                min-width: 100px;
                height: 40px;
                background:#ffffff;
                font-size: 14px;
                font-weight: 600;
                color: #FF007A;
                border:1px solid #FF007A;
                border-radius: 4px;
                -webkit-border-radius: 4px;
                -moz-border-radius: 4px;
                transition: all 0.4s ease;
                &:hover{
                    background:#FF007A;
                    border:1px solid #FF007A;
                    color: #ffffff;
                }
            }
			.outofstock{
				background:#ff5b5b;
                border:1px solid #ff5b5b;
                color: #ffffff;
				padding: 8px;
			}
        }
        .diliverydetail-wrapper{
            float: left;
            width: 100%;
            list-style:none;
            margin: 0px;
            padding: 0px;
            h2{
                font-size: 14px;
                font-weight: 400;
                line-height: 15px;
                text-transform: uppercase;
                color: #AEAEB4;
                margin-top: 1em;
            }
            li{
                float: left;
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                color: #464550
            }
        }
        .productpriceoffer{
            float: left;
            width: 100%;
            margin: 1em 0 0.5em 0;
            .productactprice{
                display: inline-block;
                float: none;
                width: auto;
                font-size: 20px;
                font-weight: 500;
                line-height: 30px;
                text-transform: uppercase;
                color: #1658D3;
                margin-right: 10px;
            }
            .productprevprice{
                display: inline-block;
                float: none;
                width: auto;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                font-weight: normal;
                color: #707070;
                margin-right: 10px;
                text-decoration: line-through;
            }
            .productdiscount{
                display: inline-block;
                float: none;
                width: auto;
                min-width: 55px;
                height: 20px;
                background:#40E8A4;
                font-size: 11px;
                font-weight: 500;
                color: #ffffff;
                padding: 2px 5px;
                text-align: center;
                border-radius: 2px;
                -webkit-border-radius: 2px;
                -moz-border-radius: 2px;
            }
        }
        .porductby-wrapper{
            float: left;
            width: 100%;
            margin: 8px 0 20px 0;
            img{
                float: left;
                margin-right: 10px;
            }
            .productby-info{
                float: left;
                p{
                    float: left;
                    font-size: 11px;
                    line-height: 15px;
                    font-style: italic;
                    color: #AEAEB4;
                }
                span{
                    float: left;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 21px;
                    color: #172940;
                }
            }
        }
    }
    
}
.productlist-section{
    float: left;
    width: 100%;
    margin-top: 4em;
}
}

.productimgviewer{
    .item-slick.slick-slide.slick-current.slick-active {
        outline: none!important  
    }
    .slider-for {
        margin-bottom: 15px;
        img {
            width: 100%;
            min-height: 100%; 
          }
      }
      .slider-nav {
        margin: auto;
        .item-slick {
            max-width: 240px;
            margin-right: 15px;
            outline: none!important;
            cursor: pointer;
            img {
                max-width: 100%;
                background-size: cover;
                background-position: center; 
              }
          }
          .slick-arrow {
            position: absolute;
            top: 50%;
            z-index: 50;
            margin-top: -12px; 
          }   
          .slick-prev {
            left: 8px;
            margin-top: 0px;
          }
          
          .slick-next {
            right: 8px; 
            margin-top: 0px;
          }       
      }
}

