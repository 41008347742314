// 5. Smart Section
.smart-wpr{
    //background: url(../../images/sm2.png);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: contain;
    // min-height: 100vh;
    height: calc(100vh - 280px);
    padding: 40px 0;
    position: relative;
    h1{
        color: $voilet;
        font-size: 32px;
        font-weight: 700;
        line-height: 50px;
        text-transform: capitalize;
        span{
            color: $hoverColor;
        }
    }
    p{  
        color: $primaryColor;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        padding: 14px 0;
    }
    &::before{
       // bottom: 5px;
        //content: url(../../images/dot.png);
       // left: -44px;
       // position:  absolute;
    }
    .smart-img-wpr{
        span{
            display: block;
        }
    }
    .form-wpr{
        font-size: 14px;
        margin: 0 0 10px 15px;
        position: relative;
        width: 540px;
        &:before{
            border: 1px solid #cccccc;
            content: '';
            right: -100px;
            height: 28px;
            position: absolute;
            top: 0;
            transform: rotate(45deg);
            width: 28px;
        }
		
	form{
		background: $white;
		box-shadow: 0px 1px 40px 0px rgba(32, 37, 39, 0.08);
	}
        .input-wpr{
            float: left;
            padding : 9px 0 8px 0;
            width: calc(100% - 123px);
        }
        .f-search{
            border-left: 1px solid #e2e2e2;
            position: relative;
            @include flexbox();
            @include align-items(center);
            width: 100%;
            input{
                border: none;
                color: #6e7884;
                font-size: 14px;
                font-weight: 500;
                padding : 10px 15px;
                margin-left: 28px;
                width: 100%;
            }
            
            span{
                position: absolute;
                left: 20px;

            }
            .search-icon{
                top: 9px;
                img{
                    width: 16px;
                } 
            }
            .cat-icon{
                top: 9px;
                img{
                    width: 16px;
                }
            }
            .map-icon{
                top: 9px;
                img{
                    width: 12px;
                } 
            }
            &:first-child{
                border-left: 0;
            }
        }
        button{
            background-color: $buttonBack;
            border: 1px solid $buttonBack;
            color: $white;
            height: 58px;
            width:123px;
			cursor:pointer;
        }
    }
}
.extra-header-below + header{
	display:none;
}

.createuser-name{
    float: left;
    width: 100%;
    margin-top: 1.9rem;
    background-image: url('../../images/dots-left.png'), url('../../images/dots-right.png'), url('../../images/dot.png'), url('../../images/circle-line.png');
    background-position: -2% 18%, 102% 18%, -2% 88%, 95% 88%;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
    background-size: 95px;
    //background-color: #f00;
    .createuser-form{
        float: left;
        width: 100%;
        display: flex;
        height: calc(100vh - 270px);
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .createuser-form-inner{
            width: 600px;
            h1{
                text-align: center;
            }
            p{
                text-align: center;
                margin-top: 25px;
            }
            .form-createuser{
                float: left;
                width: 100%;
                display: flex;
                justify-content:center;
                .errorcolor{
                    position: absolute;
                    color: #ff0000;
                    font-weight: 700;
                    padding-left: 18px;
                    &::after{
                        float: left;
                        content: '';
                        background: url('../../images/error-username.svg') no-repeat;
                        height: 25px;
                        width: 25px;
                        position: absolute;
                        left: 0px;
                        top: 4px;
                        font-weight: bold;
                    }
                }
                button{
                    margin-left: 10px !important;
                    background: #DEDEDE !important;
                    color: #A3A3A5 !important;
                    &:hover{
                        background: #DEDEDE;
                    }
                }
            }
        }
    }
}