
.toggle {
  display: none;
}

.accordion{
    margin-bottom: 60px;
}

.hedfx_h1 {
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #172940 ;
	margin-bottom: 40px;
}

.option {
  position: relative;
    margin-bottom: 20px;
    border-radius: 6px;
    border: #f6f6f6 solid 1px;
    label{
        margin-bottom: 0;
    }
}

.title,
.content {
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.title {
  background: #f6f6f6;
    padding: 18px 15px;
    display: block;
    color: #172940;
    font-size: 18px;
    font-weight: normal;
    cursor:pointer;
    &::after, &::before{
        content: '';
        position: absolute;
        right: 1.25em;
        top: 1.25em;
        width: 2px;
        height: 0.75em;
        background-color: #172940;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
    }
    &::after{
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg);
    }
}


.content {
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
}
.content p {
  margin: 0;
  padding: 0.5em 1em 1em;
      font-size: 14px;
    line-height: 20px;
	color:#172940;
	
}

.toggle:checked + .title, .toggle:checked + .title + .content {
  background: #fff;
  box-shadow:0px 2px 7px -6px #ddd;
      border-radius: 6px 6px 0px 0px;
}
.toggle:checked + .title + .content {
  max-height: inherit;
  border-radius:0 0 6px 6px;
}
.toggle:checked + .title:before {
  -webkit-transform: rotate(90deg) ;
          transform: rotate(90deg) ;
}

.ulli {font-size: 14px; line-height: 23px; }
.ulli ul{margin:0 0 15px 35px;}
.ulli ul li{color: #172940; list-style-type: disc;}



 @media (min-width:411px) and (max-width:420px) {
 .content p {letter-spacing: 0.3px ; font-size: 12px ;}
 .title {font-size:15px ; letter-spacing: 0.6px ;}
 .title:after, .title:before {top: 1.25em ;}
 .ulli ul li{font-size:12px ;}
} 

@media (width:360px){
 .content p{font-size:10px ; letter-spacing: 0.5px ;}
 .title {font-size:13px ; }
 .title:after, .title:before {top: 1.50em ;}
 .ulli ul li{font-size:10px ;}

}

@media (max-width:767px){
	
 .option {margin-bottom: 15px;     border-radius: 7px ;}	
 .title {font-size:14px; letter-spacing:0.3px; border-radius:6px; font-weight:500;  line-height:20px; padding:15px 31px 15px 16px;}
 .content p {padding:0 1em 1em 1.5em; border-radius:6px 6px 0 0; line-height:21px; letter-spacing:0.2px;
    font-size:11px; position: relative; top: -5px;}
 .content{border-radius:0 0 6px 6px ;}
 .title:after, .title:before {top: 1.40em;}
 .ulli ul li{font-size:11px;}
 
 
}