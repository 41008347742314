/* Page Heading */ 

.page-heading{
    color: #172940;
    font-size: 24px;
    font-weight: 600;
    line-height: 35px;
    margin-bottom: 20px;
}

/* Sort Select Option */ 

.custom-sort{
    .form-group{
        display: table;
        margin-bottom: 0;
        position: relative;
        min-width: 240px;
        .down-arrow{
            position: absolute;
            right: 8px;
            top: 15px;
        }
        label{
           color: #6F7B8A;
           display: table-cell;
           font-size: 14px;
           padding-right: 5px;
        }
        select{
            border: 1px solid $borderColor ;
            border-radius: 0;
            font-size: 13px;
            font-weight: 400;
            color: $primaryColor;
            min-height: 40px;
            -webkit-appearance: none;
            -moz-appearance: none;
                appearance: none;
            &:focus{
                border: 1px solid $borderColor ; 
                box-shadow: none;
            }
        }
    }
}



.heading{
    text-align: center;
    h2{
        color: $voilet;
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 0;
        line-height: 76px;
        text-transform: capitalize;
        color: #1D2024;
    }
    p{
        color: $primaryColor;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
}

// For single Feature css
.feature-item{
    background: $grey1 ;
    display: inline-block;
    list-style: none;
    height: auto;
    margin: 0 10px;
    max-width: 290px;
    //min-height: 560px;
    width: 100%;
    list-style: none;
   
    .feature-image{
        position: relative;
        overflow: hidden;
        .react-loading-skeleton{
            border-radius: 0;
            line-height: 1.4;
        }
        img{
            height: 227px;
            width: 100%;
            object-fit: cover;
        }
        .feature-type{
            background: rgba(0, 0, 0, 0.6);
            border-radius: 10px;
            color: $white;
            display: inline-block;
            font-size: 10px;
            height: 19px;
            padding: 2px 10px;
            position: absolute;
            text-transform: uppercase;
            left: 10px;
            bottom: 10px;
            width: auto;
        }
    }
    .feature-details{
        padding: 20px;
        float: left;
        width: 100%;
        h3{
            color: $primaryColor;
            font-size: 16px;
            font-weight: 600;
            line-height: 25px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        p{
            //color: $grey2;
            color: #858A8F;
            font-size: 12px;
            margin-bottom: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            letter-spacing: inherit;
            opacity: 1;
            font-style: inherit;
            img{
                display: inline-block;
                margin-right: 3px;
            }
        }
        h4{
            color: $blue;
            font-size: 18px;
            font-weight: 600;
            line-height: 27px;
        }
        a{
            color: $hoverColor;
            font-size: 14px;
            line-height: 21px;
            display: inline-block;
            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0px;
                background: $hoverColor;
                transition: width .3s;
            }
            &:hover::after{
                width: 100%;
            }
            img{
                height: 11px;
                width: 13px;
                display: inline-block;
            }
            &:hover{
                text-decoration: none;
            }
        }
        
    }
}


// Input style

/* form starting stylings ------------------------------- */
.custom-form{ 
    position:relative; 
    margin:22px 0 30px 0; 
    input, select{
        color: #1C2A3A;
        font-size:14px;
        font-weight: 400;
        padding:10px 10px 10px 0;
        display:block;
        width:100%;
        border:none;
        border-bottom:1px solid #DEDEDE;
        &:focus{
            outline:none;
        }
      }
      label{
        color:#747C85;
        font-size:13px;
        font-weight:400;
        position:absolute;
        pointer-events:none;
        left:0;
        top:10px;
        transition:0.2s ease all; 
        -moz-transition:0.2s ease all; 
        -webkit-transition:0.2s ease all;
      }

     /* active state */
  input:focus ~ label, input:valid ~ label , select:focus ~ label, select:valid ~ label{
    top:-13px;
    font-size:13px;
    font-weight: 400;
    color:#747C85;
  }
  
  /* BOTTOM BARS =========*/
  .bar 	{ position:relative; display:block; width:100%; }
  .bar:before, .bar:after 	{
    content:'';
    height:2px; 
    width:0;
    bottom:0; 
    position:absolute;
    background: $hoverColor; 
    transition:0.2s ease all; 
    -moz-transition:0.2s ease all; 
    -webkit-transition:0.2s ease all;
  }
  .bar:before {
    left:50%;
  }
  .bar:after {
    right:50%; 
  }
  
  /* active state */
  input:focus ~ .bar:before, input:focus ~ .bar:after,  select:focus ~ .bar:before, select:focus ~ .bar:after{
    width:50%;
  }
  
  /* HIGHLIGHTER  */
  .highlight {
    position:absolute;
    height:60%; 
    width:100px; 
    top:25%; 
    left:0;
    pointer-events:none;
    opacity:0.5;
  }
}



// Btn Primary

.btn.btn-primary{
    background: $blue;
    opacity: 0.8;
    border-radius: 0;
    border: 1px solid transparent;
    @include font-style(16px, 500, 1.9);
    min-height: 44px;
    min-width: 102px;
    transition: all .3s ease;
    &:focus, &:hover{
        box-shadow: none;
        outline: none;
        opacity: 1;
        border: 1px solid transparent;
        transition: all .3s ease;
    }
}

.btn-primary:not(:disabled):not(.disabled).active:focus, 
.btn-primary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-primary.dropdown-toggle:focus{
    box-shadow: none;
}

.btn-primary.disabled{
    border: 1px solid #DEDEDE;
    background: #DEDEDE;
}

.custom-group.form-group{
    position: relative;
    .down-arrow{
        position: absolute;
        bottom: 15px;
        right: 10px;
    }
    .custom-select.form-control{
        background: $white;
        box-shadow: 2.828px 2.828px 8px 0px rgba(0, 0, 0, 0.02);
        border: none;
        border-radius: initial;
        cursor: pointer;
        font-size: 13px;
        font-weight: 400;
        min-height: 44px;
        padding: 10px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        &:focus{
            box-shadow: none;
        }
    }
        
    label{
        color: $primaryColor;
        font-size: 13px;
        font-weight: 600;
    }
}

// Campaigns Page CSS

/* Range Slider */ 

.range-slider-wpr{
    .range-content {
        width: 100%;
      }
      
    .slider {
        display: block;
        position: relative;
        height: 36px;
        width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select:none;
        -o-user-select:none;
        user-select:none;
    
        .slider-touch-left, .slider-touch-right {
            display: block;
            position: absolute;
            height: 18px;
            width: 18px;
            z-index: 2;
            
            span {
                box-shadow: 0px 3px 6px #00000029;
                cursor: pointer;
                display: block;
                width: 18px;
                height: 18px;
                background: #ffffff;
                border-radius: 50%;
            }
        }
    
        .slider-line {
            position: absolute;
            width: 100%;
            left: 0;
            top: 8px;
            height: 4px;
            border-radius: 4px;
            background: #F0F0F0;
            z-index: 0;
            overflow: hidden;
    
            span {
                display: block;
                height: 100%;
                width: 0%;
                background: #1658D3;
            }
        }
    }
    
    .range-result{
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        margin: 10px 0  6px 0;
        span{
            color: #172940;
            display: block;
            font-size: 13px;
            font-weight: 600;
            opacity: 0.68;
        }
    }
}

  /*End Range Slider */ 

  .custom-modal{
    min-height:100%;
    overflow-y:scroll;
    overflow-x:hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar{
      display: none;
    }
      .modal-dialog{
          max-width: 490px;
        // margin-top: 10%;
          .modal-content{
              border :none;
              border-radius: 0;
              .modal-body{
                  padding: 30px;
                  h2.modal-heading{
                      color: #222222;
                      font-size: 22px;
                      font-weight: 600; 
                      line-height: 33px;
                  }
              }
              .form-bottom-text{
                  color: #1C2A3A;
                  font-size: 12px;
                  margin-bottom: 0;
                  padding-top: 25px;
                  a{
                      color: #384D8F;
                      text-decoration: underline;
                  }
              }
              textarea{
                  font-size: 13px;
              }
          }
      }
      .signUp-modal,.signUp-modal{
        .modal-dialog{
            margin-top: 2%;
        }
    }
}


//   Custom nav tab

.custom-nav{
    border: 0;
    .nav-item{
        margin-bottom: 0;
    }
    .nav-link{
        color: $primaryColor;
        opacity: 0.4;
        @include font-style(16px, 600, 25px);
        border-color:transparent ;
        position: relative;
        padding: 8px 20px;
        &:hover, &.active, &:focus{
            opacity: 1;
            border-color: transparent;
        }
        &::before{
            position: absolute;
            content: " ";
            height: 3px;
            width: 0%;
            left: 0;
            bottom: -2px;
            background-color: #FF007A;
            opacity: 0;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
        }
        &.active:before{
            opacity: 1;
            -webkit-transition: all 0.5s ease-in-out;
            transition: all 0.5s ease-in-out;
            width: 100%;
        }
    }
}

// form-control

.custom-form-group{
    label{
        color: $primaryColor;
        @include font-style(14px, 600, 21px);
    }
    .form-control{
        border-radius: 0;
        color: #4B5460;
        @include font-style(14px, 500, 21px);
        padding: 10px 13px;
        &:focus{
            border-color:initial ;
            box-shadow: none;
        }
    }
    .m-select{
        cursor: pointer;
    }
}

// Custom Select box
.custom-select-box{
    position: relative;
    .form-control{
        min-height: 43px;
        cursor: pointer;
    }
    .down-arrow {
        position: absolute;
        right: 9px;
        top: 15px;
        font-size: 13px;
    }
    .dropdown-menu{
        border-radius: 0;
        padding: 0;
        margin-top: 0px;
        border-top: 0;
        max-height: 230px;
        width: 100%;
        overflow: hidden;
        overflow-y: scroll;
        @include font-style(14px, 600, 21px);
        li{
            cursor: pointer;
            text-align: left;
            padding: 6px 10px;
            &:focus, &:hover{
                color: $primaryColor;
                background-color: #f8f9fa;
            }
        }
    }
    .dropdown-toggle{
        cursor: pointer;
        &:after{
            display: none;
         }
        
         
    }
    label{
        color: $primaryColor;
        @include font-style(14px, 600, 21px);
    }
    .form-control{
        border-radius: 0;
        color: #4B5460;
        @include font-style(14px, 500, 21px);
        padding: 10px 13px;
        &:focus{
            border-color:initial ;
            box-shadow: none;
        }
    }
}

// Custom Date

.custom-date{
    position: relative;
    label{
        color: $primaryColor;
        @include font-style(14px, 600, 21px);
    }
    .react-datepicker-wrapper{
        display: block;
    }
    .cal-icon{
        position: absolute;
        right: 14px;
        top: 45px;
        z-index: 1;
        color: #6E7884;
    }
    @include font-style(14px, 500, 21px);
    .react-datepicker__day--selected, .react-datepicker__day--keyboard-selected, 
    .react-datepicker__month-text--keyboard-selected, 
    .react-datepicker__quarter-text--keyboard-selected,.react-datepicker__day--selected:hover{
        background-color: #FF007A 
    }
}


// Radio Button
.custom-checkbox{
    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }
    [type="radio"]:checked + label,
    [type="radio"]:not(:checked) + label
    {
        position: relative;
        padding-left: 28px;
        cursor: pointer;
        line-height: 20px;
        display: inline-block;
        color: #666;
    }
    [type="radio"]:checked + label:before,
    [type="radio"]:not(:checked) + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 20px;
        height: 20px;
        border: 1px solid #ddd;
        border-radius: 100%;
        background: #fff;
    }
    [type="radio"]:checked + label:after,
    [type="radio"]:not(:checked) + label:after {
        content: '';
        width: 10px;
        height: 10px;
        background: #1658D3;
        position: absolute;
        top: 5px;
        left: 5px;
        border-radius: 100%;
        -webkit-transition: all 0.2s ease;
        transition: all 0.2s ease;
    }
    [type="radio"]:not(:checked) + label:after {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    [type="radio"]:checked + label:after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

// Cancel and Discard button

.cancel-btn, .btn-secondary:not(:disabled):not(.disabled).active, 
.btn-secondary:not(:disabled):not(.disabled):active, 
.show>.btn-secondary.dropdown-toggle{
    //border: 1px solid #DBE0EB;
    border: 1px solid transparent;
    background: #DBE0EB;
    color: #6B6A77;
    transition: all .3s ease;
}

.cancel-btn{
    min-height: 44px;
    min-width: 102px;
    border-radius: 0;
    border: 1px solid transparent;
    &:hover{
        border: 1px solid transparent;
       // border: 1px solid #DBE0EB;
        background: #D0D6E1;
        color: #6B6A77;
        transition: all .3s ease;
    }
    &:focus{
        border: 1px solid transparent;
        box-shadow: none;
        transition: all .3s ease;
    }
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-secondary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-secondary.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-outline-secondary.dropdown-toggle:focus{
    box-shadow: none;
}


.discard-btn{
    border: 1px solid transparent;
    border-radius: 0;
    background: transparent;
    color: #6B6A77;
    @include font-style(16px , 500, 21px);
    min-height: 44px;
    min-width: 102px;
    &:hover{
        border: 1px solid transparent;
        background: transparent;
        color: #6B6A77;
        box-shadow: none;
    }
    &:focus{
        box-shadow: none;
    }
}

.discard-btn, .btn-outline-secondary:not(:disabled):not(.disabled).active, 
.btn-outline-secondary:not(:disabled):not(.disabled):active, 
.show>.btn-outline-secondary.dropdown-toggle{
    border: 1px solid transparent;
    background: transparent;
    color: #6B6A77;
}